<template>
  <div id="gaime_admin">
    <b-row  v-if="editGaine">
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>{{currentGaine.nom}}</span><br></p>
          </div>
          <div class="content-form roundButton">
            <b-row>
              <b-col cols="2">
                <label for="vmc_input">Gaine: </label>
              </b-col>
              <b-col>
              <b-form-input id="vmc_input" list="my-list-id" v-model="currentGaine.lidic" @blur="updateVMC" @input="updateLidicProduct()"></b-form-input>
              <datalist id="my-list-id">
                  <option :value="prod.value" v-for="(prod,index) in lidic_product" :key="index">{{ prod.text }}</option>
              </datalist>
            </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="libelle">Libellé: </label>
              </b-col>
              <b-col>
                <b-form-input id="libelle" v-model="currentGaine.libelle"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="diamcuisine">Diamètre Cuisine: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="diamcuisine" v-model="currentGaine.diametre.cuisine"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="diamsdb">Diamètre SDB: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="diamsdb" v-model="currentGaine.diametre.sdb"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="diamsdbwc">Diamètre SDBWC: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="diamsdbwc" v-model="currentGaine.diametre.sdbwc"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="diamwc">Diamètre WC: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="diamwc" v-model="currentGaine.diametre.wc"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="diameau">Diamètre Eau: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="diameau" v-model="currentGaine.diametre.eau"></b-form-input>
              </b-col>
            </b-row>
            <b-row><b-col>&nbsp;</b-col></b-row><b-row><b-col>&nbsp;</b-col></b-row>
            <b-row>
              <b-col cols="2">
                <label for="libelle">Type de Gaine: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentGaine.type" :options="options_type"></b-form-select>
              </b-col>
            </b-row>
            <div v-if="currentGaine.type=='flexible'">
            <b-row>
              <b-col cols="2">
                <label for="debit_input_min">Perte par mètre(Pa): </label>
              </b-col>
              <b-col>
                <b-form-input type="text" id="debit_input_min" v-model="currentGaine.m"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="perte_coude90">Perte par coude 90°: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="perte_coude90" v-model="currentGaine.coude90"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="perte_coude45">Perte par coude 45°: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="perte_coude45" v-model="currentGaine.coude45"></b-form-input>
              </b-col>
            </b-row>
            </div>
            <div v-if="currentGaine.type=='pluggit'">
              <b-tabs>
                <b-tab title="Cuisine" active class="main_content">
                  <b-tabs>
                    <b-tab title="Perte ABM" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsABM">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_abm">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_abm" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_abm">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_abm" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','cuisine','abm')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','cuisine','abm')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.cuisine.abm" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','cuisine','abm')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','cuisine','abm')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                    <b-tab title="Perte Conduit" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsConduit">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_conduit">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_conduit" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_conduit">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_conduit" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','cuisine','conduit')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','cuisine','conduit')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.cuisine.conduit" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','cuisine','conduit')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','cuisine','conduit')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                    <b-tab title="Perte Y de connexion 125" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsConnexion">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_connexion">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_connexion" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_connexion">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_connexion" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','cuisine','connexion')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','cuisine','connexion')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.cuisine.connexion" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','cuisine','connexion')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','cuisine','connexion')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                    <b-tab title="Perte Coude 90°" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsCoude90">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_coude90">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_coude90" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_coude90">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_coude90" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','cuisine','coude90')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','cuisine','coude90')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.cuisine.coude90" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','cuisine','coude90')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','cuisine','coude90')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                    <b-tab title="Perte Coude 45°" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsCoude45">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_coude45">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_coude45" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_coude45">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_coude45" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','cuisine','coude45')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','cuisine','coude45')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.cuisine.coude45" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','cuisine','coude45')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','cuisine','coude45')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                  </b-tabs>
                </b-tab>
                <b-tab title="Autres" class="main_content">
                  <b-tabs>
                    <b-tab title="Perte ABM" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsABMAutre">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_abmautre">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_abmautre" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_abmautre">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_abmautre" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','autre','abm')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','autre','abm')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.autre.abm" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','autre','abm')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','autre','abm')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                    <b-tab title="Perte Conduit" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsConduitAutre">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_conduitautre">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_conduitautre" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_conduitautre">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_conduitautre" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','autre','conduit')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','autre','conduit')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.autre.conduit" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','autre','conduit')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','autre','conduit')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                    <b-tab title="Perte Reduction 90/80" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsConnexionAutre">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_connexionautre">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_connexionautre" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_connexionautre">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_connexionautre" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','autre','connexion')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','autre','connexion')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.autre.connexion" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','autre','connexion')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','autre','connexion')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                    <b-tab title="Perte Coude 90°" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsCoude90Autre">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_coude90autre">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_coude90autre" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_coude90autre">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_coude90autre" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','autre','coude90')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','autre','coude90')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.autre.coude90" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','autre','coude90')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','autre','coude90')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                    <b-tab title="Perte Coude 45°" class="main_content">
                      <div class="content-block shadow-box" v-if="editOptionsCoude45Autre">
                        <div class='header-form'>
                          <p><span>Point de fonctionnement</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                          <b-col>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_debit_coude45autre">Débit: </label>
                              </b-col>
                              <b-col>
                                <b-form-input id="points_fonctionnement_debit_coude45autre" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="2">
                                <label for="points_fonctionnement_pression_coude45autre">Pression: </label>
                              </b-col>
                              <b-col>
                              <b-form-input id="points_fonctionnement_pression_coude45autre" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('point_fonctionnement','autre','coude45')" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        </b-row>
                      </div>
                    </div>
                    <b-row align-h="end">
                      <b-col cols="2">
                        <b-button size="sm" target="_blank" @click="editItem(-1,'point_fonctionnement','autre','coude45')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                      </b-col>
                    </b-row>
                      <b-table responsive striped hover :items="currentGaine.calcul.autre.coude45" :fields="fields_points_fonctionnement">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'point_fonctionnement','autre','coude45')" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'point_fonctionnement','autre','coude45')" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                  </b-tabs>
                </b-tab>
              </b-tabs>
            </div>
            <b-row><b-col>&nbsp;</b-col></b-row><b-row><b-col>&nbsp;</b-col></b-row>
            <b-row>
              <b-col cols="2">
                <label for="statut_input">Status: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentGaine.status" :options="status" id="statut_input"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="cancel('gaine')" class="mr-1">Annuler</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="saveItem('gaine')" class="mr-1">Enregistrer</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
  </b-row>
      <b-row  v-if="!editGaine">
        <b-col cols="12" md="12" lg="12">
          <div class="content-block shadow-box">
            <div class='header-form'>
              <p><span>Gaines</span><br></p>
            </div>
            <div class="content-form roundButton">
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                <p>Gaine enregistré avec succès.</p>
              </b-alert>
      <b-row align-h="end">
        <b-col cols="2">
          <b-button size="sm" target="_blank" @click="editItem(-1,'gaine')" class="mr-1">Ajouter une Gaine</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="2">
          <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher par LIDIC, Nom, ..."></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table responsive striped hover :items="gaines" :fields="fields" :filter="filter" id="my-table" :per-page="perPage" :current-page="currentPage" :tbody-tr-class="rowClass">
      <template #cell(vignette)="row">
        <b-img :src="`/img/${row.item.lidic}.jpg`"/>
      </template>
        <template #cell(actions)="row">
          <b-button size="sm" target="_blank" @click="editItem(row.item,'gaine')" class="mr-1">Editer</b-button>
          <b-button size="sm" target="_blank" @click="copyItem(row.item,'gaine')" class="mr-1">Copier</b-button>
          <b-button size="sm" target="_blank" @click="deleteItem(row.item,'gaine')" class="mr-1">Supprimer</b-button>
        </template>
      </b-table>
      </div></div>
    </b-col>
    </b-row>
    <datalist id="my-list-pt">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'GaineAdmin',
  props: {
    gaines: Array,
    product: Array,
  },
  data() {
      return {
        dismissCountDown: 0,
        tmpPieceName:'',
        editOptionsABM:false,
        editOptionsCoude90:false,
        editOptionsCoude45:false,
        editOptionsConnexion:false,
        editOptionsConduit:false,
        editOptionsABMAutre:false,
        editOptionsCoude90Autre:false,
        editOptionsCoude45Autre:false,
        editOptionsConnexionAutre:false,
        editOptionsConduitAutre:false,
        currentPoints_fonctionnement:{},
        currentGaine:{
          type:'',
          calcul:{
            cuisine:{
              abm:[],
              conduit:[],
              connexion:[],
              coude90:[],
              coude45:[]
            },
            autre:{
              abm:[],
              conduit:[],
              connexion:[],
              coude90:[],
              coude45:[]
            }
          }
        },
        fields_points_fonctionnement: [
          {
            key: 'debit',
            label: 'Débit',
            sortable: true
          },
          {
            key: 'pression',
            label: 'Pressions',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        currentGaineLidic: -1,
        currentLocalId: -1,
        currentOptions:{},
        currentParams:{},
        currentConfig:{},
        currentLocal: {},
        personne_debit: false,
        m_debit: false,
        supp_debit: false,
        newConfig: 'Nouveau Label',

        tmpGaineLidic: null,
        currentConfigLabel: null,
        tmpConfigLabel: null,
        lidic_options:[],
        lidic_params:[],
        lidic_product:[],
        newConfigParam: '',
        editGaine:false,
        editLocal:false,
        editLocalPiece:false,
        editOptions:false,
        editParams:false,
        editConfig:false,
        editGaines:false,
        filter: null,
        perPage: 20,
        currentPage: 1,
        fields_config: [
          {
            key: 'label',
            label: 'label',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_piece_params: [
          {
            key:'name',
            label:'Nom Pièce',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_params: [
          {
            key: 'type',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_gaines: [
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_options: [
          {
            key: 'libelle',
            label: 'Libelle',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields: [
          { key: 'vignette', label: '',tdClass: 'product-vignette' },
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          {
            key: 'libelle',
            label: 'Nom',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        typologie:[{
          value:1,
          text:'T1'
        },{
          value:2,
          text:'T2'
        },{
          value:3,
          text:'T3'
        },{
          value:4,
          text:'T4'
        },{
          value:5,
          text:'T5'
        },{
          value:6,
          text:'T6'
        },{
          value:7,
          text:'T7'
        }],
        reglementation:[{
          value:'E',
          text:'Existant'
        },
        {
          value:'N',
          text:'Construction Neuve'
        },{
          value:'E/N',
          text:'Existant ou Construction Neuve'
        }],
        options_type:[{value:'flexible',text:'Flexible'},{value:'pluggit',text:'Pluggit'}],
        param_type:[{value:'bouche',text:'Bouche Commande'},
                    {value:'piece_technique','text':'Pièces Techniques'},
                   {value:'entree','text':"Bouche d'entrée"}],
        param_vmc_type:[{value:'piquetage',text:'Piquetage'},{value:'barre_horizontal',text:'Barre Horizontal'},{value:'barre_vertical',text:'Barre Vertical'}],
        flux:[{value:'Simple Flux',text:'Simple Flux'},{value:'Double Flux',text:'Double Flux'}],
        status:[{value:'valid',text:'Validé'},{value:'error',text:'Erreur Paramétrage'},{value:'waiting',text:'En attente de validation'}],
        categorie:[{value:'Caisson Très Basse Conso.',text:'Caisson Très Basse Consommation'},{value:'Caisson Basse Conso.',text:'Caisson Basse Consommation'},{value:'Caisson Basse Conso. Régulé',text:'Caisson Basse Consommation Régulé'},{value:'Caisson Standard',text:'Caisson Standard'}],
        type_aggrement:[{value:'N',text:'Non Agrée'},{value:'400',text:'400°C 1/2H'}],
        type_flux:[{value:'S',text:'Simple Flux'},{value:'D',text:'Double Flux'}],
        type_version:[{value:'S',text:'Standard'},{value:'Elec',text:'Batterie Electrique'},{value:'Eau',text:'Batterie Eau'}],
        type:[{value:'JETLINE',text:'JETLINE'},{value:'CACB ECM ECO',text:'CACB ECM ECO'},{value:'CACB ECM REJET VERTICAL',text:'CACB ECM REJET VERTICAL'},{value:'CACB MV REJET HORIZ/VERTICAL',text:'CACB MV REJET HORIZ/VERTICAL'},
      {value:'CRCB ECOWATT REJET HORIZONTAL',text:'CRCB ECOWATT REJET HORIZONTAL'},{value:'CRCB ECOWATT ISOLE  REJET HORIZONTAL',text:'CRCB ECOWATT ISOLE  REJET HORIZONTAL'},{value:'CRCB ECOWATT ISOLE  REJET VERTICAL',text:'CRCB ECOWATT ISOLE  REJET VERTICAL'},
    {value:'CRCB ECOWATT REJET VERTICAL',text:'CRCB ECOWATT REJET VERTICAL'},{value:'CRCB ECOWATT PM  REJET HORIZONTAL',text:'CRCB ECOWATT PM  REJET HORIZONTAL'},{value:'CRCB ECOWATT PM  REJET VERTICAL',text:'CRCB ECOWATT PM  REJET VERTICAL'},
  {value:'CRCB ECOWATT PM ISOLE  REJET HORIZONTAL',text:'CRCB ECOWATT PM ISOLE  REJET HORIZONTAL'},{value:'CRCB ECOWATT PM ISOLE  REJET VERTICAL',text:'CRCB ECOWATT PM ISOLE  REJET VERTICAL'},{value:'CRCB ECOWATT TRI  ISOLE  REJET VERTICAL',text:'CRCB ECOWATT TRI  ISOLE  REJET VERTICAL'},
{value:'CRCB ECOWATT TRI ISOLE  REJET VERTICAL',text:'CRCB ECOWATT TRI ISOLE  REJET VERTICAL'},{value:'CRCB ECOWATT TRI REJET VERTICAL',text:'CRCB ECOWATT TRI REJET VERTICAL'},{value:'CACT N ECO TRI',text:'CACT N ECO TRI'}],
      tension:[{value:'M',text:'MonoPhase'},{value:'T',text:'Triphase'}],
      aspiration:[{value:'9',text:'90°'},{value:'L',text:'Ligne'},{value:'D',text:'Double'}],
      debit:["300", "400", "500", "600", "700", "800", "900", "1000", "1100", "1200", "1300", "1400", "1500", "1600", "1700", "1800", "1900",'2000','2700','2900','3700','5000','6900','8000','9000'],
      }
  },
  methods: {
    close(type,opt1,opt2){
      console.log(type);
      switch(opt2){
        case 'abm':
          if(opt1=="cuisine"){
            this.editOptionsABM=false;
          }else{
            this.editOptionsABMAutre=false;
          }
        break;
        case 'coude90':
          if(opt1=="cuisine"){
          this.editOptionsCoude90=false;
          }else{
            this.editOptionsCoude90Autre=false;
          }
        break;
        case 'coude45':
          if(opt1=="cuisine"){
          this.editOptionsCoude45=false;
          }else{
            this.editOptionsCoude45Autre=false;
          }
        break;
        case 'connexion':
          if(opt1=="cuisine"){
          this.editOptionsConnexion=false;
          }else{
            this.editOptionsConnexionAutre=false;
          }
        break;
        case 'conduit':
          if(opt1=="cuisine"){
          this.editOptionsConduit=false;
          }else{
            this.editOptionsConduitAutre=false;
          }
        break;
      }
    },
    onFiltered(filteredItems){
      console.log("filter");
      console.log(this.tertiaire.vmc.length);
      console.log(filteredItems.length);
      this.total_rows_vmc = filteredItems.length;
      this.currentPage = 1;
    },
    updateLidicPiquetage(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateConfig(){
      switch(this.currentOptions.content.type){
        case 'checkbox':
          this.$delete(this.currentOptions.content,'options');
        break;
        default:
          this.$delete(this.currentOptions.content,'items');
          this.$delete(this.currentOptions,'type');
        break;
      }
    },
    changeSupp(){
      if(!this.supp_debit){
        if(!Object.prototype.hasOwnProperty.call(this.currentPiece.debit,'supp')){
          this.$set(this.currentPiece.debit,'supp',0);
        }
      }else{
        if(Object.prototype.hasOwnProperty.call(this.currentPiece.debit,'supp')){
          this.$delete(this.currentPiece.debit,'supp');
        }
      }
    },
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    copyItem(item,type){
      switch(type){
        case 'individuel':
          this.currentGaine = JSON.parse(JSON.stringify(item));
          this.currentGaine.lidic=null;
          this.currentGaine.status= "waiting",
          this.currentGaineLidic = -1;
          this.editVMC = true;
        break;
      }
    },
    editItem(item,type,opt1,opt2){
      switch(type){
        case 'gaine':
          if(item==-1){
            this.currentGaine = {
              lidic: null,
              m: 0,
              coude90:0,
              coude45:0,
              diametre:{cuisine:125,eau:80,sdb:80,sdbwc:80,wc:80},
              status: "waiting",
              type: '',
              calcul:{
                cuisine:{
                  abm:[],
                  conduit:[],
                  connexion:[],
                  coude90:[],
                  coude45:[]
                },
                autre:{
                  abm:[],
                  conduit:[],
                  connexion:[],
                  coude90:[],
                  coude45:[]
                }
              }
            };
            this.currentGaineLidic = item;
          }else{
            console.log("edit caisson");
            console.log(item);
            this.currentGaine = item;
            this.currentGaineLidic = item.lidic;
          }
          console.log(this.currentGaine);
          this.editGaine = true;
        break;
        case 'point_fonctionnement':
          if(item==-1){
            this.currentGaine.calcul[opt1][opt2].push({pression:0,debit:0});
            this.currentPoints_fonctionnement = this.currentGaine.calcul.cuisine.abm[this.currentGaine.calcul.cuisine.abm.length - 1];
          }else{
            this.currentPoints_fonctionnement = item;
          }
          switch(opt2){
            case 'abm':
              if(opt1=="cuisine"){
                this.editOptionsABM = true;
              }else{
                this.editOptionsABMAutre = true;
              }
            break;
            case 'coude90':
              if(opt1=="cuisine"){
                this.editOptionsCoude90 = true;
              }else{
                this.editOptionsCoude90Autre = true;
              }
            break;
            case 'coude45':
              if(opt1=="cuisine"){
                this.editOptionsCoude45 = true;
              }else{
                this.editOptionsCoude45Autre = true;
              }
            break;
            case 'conduit':
              if(opt1=="cuisine"){
                this.editOptionsConduit = true;
              }else{
                this.editOptionsConduitAutre = true;
              }
            break;
            case 'connexion':
              if(opt1=="cuisine"){
                this.editOptionsConnexion = true;
              }else{
                this.editOptionsConnexionAutre = true;
              }
            break;
          }

        break;
      }
    },
    deleteItem(item,type,opt1,opt2){
      switch(type){
        case 'gaine':
          this.$http.delete(`${this.$api_url}gaines/${item.lidic}`).then((result) => {
            console.log(result);
            if(result){
              var index = this.gaines.findIndex((gaine) => { return item.lidic == gaine.lidic; });
              if(index>-1) this.gaines.splice(index,1);
            }else{
              console.log(result);
            }
          });
        break;
        case 'point_fonctionnement':
          this.currentGaine.calcul[opt1][opt2].splice(item,1);
        break;
      }
    },
    cancel(type){
      switch(type){
        case 'gaine':
          this.editGaine = false;
          this.currentGaine = {};
        break;
      }
    },
    saveItem(type){
      switch(type){
        case 'gaine':
          console.log(this.currentGaine);
          var baseURI = this.$api_url+'gaines';
          if(this.currentGaineLidic==-1){
            this.$http.post(baseURI,{gaine: this.currentGaine})
            .then((result) => {
              if(result){
                this.$set(this.gaines,this.gaines.length,this.currentGaine);
                console.log(this.gaines);
                this.currentGaine = {};
                this.editGaine = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }else{
            console.log(this.currentGaine);
            this.$http.put(baseURI,{gaine: this.currentGaine,lidic: this.currentGaineLidic})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.gaines.findIndex((item) => { return item.lidic == this.currentGaineLidic; });
                this.$set(this.gaines,index,this.currentGaine);
                this.currentGaine = {};
                this.editGaine = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
      }
    },
    rowClass(item,type){
      if (item && type === 'row') {
        if(item.status=='valid'){
          return 'validation_success';
        }else if(item.status=='error'){
          return 'validation_error';
        }else if(item.status=="waiting"){
          return 'waiting_validation';
        }
      }else{
        return null;
      }
    },
    updateVMC(){
      if(this.currentGaine.lidic!=""){
        let index = this.product.findIndex((item)=> {
          return item.lidic == this.currentGaine.lidic});
        if(index!=-1){
          this.currentGaine.libelle = this.product[index].nom;
          /*this.currentGaine.designation = this.product[index].designation;
          this.currentGaine.prix_unitaire = this.product[index].prix_unitaire;
          this.currentGaine.debit_max_reel = this.product[index].debit_max;*/
        }
        console.log(this.currentGaine);
      }
    },
    updateLidicGaineProd(nb_etage,index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentGaine.items[nb_etage][index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentGaine.items[nb_etage][index].lidic) || item.nom.toString().includes(this.currentGaine.items[nb_etage][index].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicGaine(){
      let res = [];
      this.product.forEach((item) => {
        if(this.tmpGaineLidic.toString().length>3 && (item.lidic.toString().includes(this.tmpGaineLidic) || item.nom.toString().includes(this.tmpGaineLidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicOptions(index,isCheckbox){
      let res = [];
      if(isCheckbox){
        this.product.forEach((item) => {
          if(this.currentOptions.content.items[index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.items[index].lidic) || item.nom.toString().includes(this.currentOptions.content.items[index].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }else{
        this.product.forEach((item) => {
          if(this.currentOptions.content.options[index].value.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.options[index].value) || item.nom.toString().includes(this.currentOptions.content.options[index].value))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }
      this.lidic_params = res;
      return res;
    },
    clearLidicBouche(){
      this.lidic_params = [];
    },
    updateLidicEntree(rallonge,index,index2){
      let res = [];
      if(rallonge){
        this.product.forEach((item) => {
          if(this.currentParams['rallonge'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['rallonge'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['rallonge'][index][index2].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }else{
        this.product.forEach((item) => {
          if(this.currentParams['entree'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['entree'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['entree'][index][index2].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }
      this.lidic_params = res;
      return res;
    },
    updateLidicBouche(index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[index][index2].lidic) || item.nom.toString().includes(this.currentParams[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicParams(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicPT(pt,index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[pt].items[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[pt].items[index][index2].lidic) || item.nom.toString().includes(this.currentParams[pt].items[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicProduct(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentGaine.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentGaine.lidic) || item.nom.toString().includes(this.currentGaine.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_product = res;
      return res;
    }
  },
  computed: {
    piece_entree(){
      let res = [];
      if(this.currentLocal && this.currentLocal.params && this.currentLocal.params.items){
        let indexEntree = this.currentLocal.params.items.findIndex((item) => { return item.type=="entree"});
        if(indexEntree!=-1){
          res = this.currentLocal.params.items[indexEntree].piece
        }
      }
      return res;
    },
    piece_sortie(){
      let res = [];
      if(this.currentLocal && this.currentLocal.params && this.currentLocal.params.items){
        let indexEntree = this.currentLocal.params.items.findIndex((item) => { return item.type=="sortie"});
        if(indexEntree!=-1){
          res = this.currentLocal.params.items[indexEntree].piece
        }
      }
      return res;
    },
    piece_independant(){
      let res = [];
      if(this.currentLocal && this.currentLocal.params && this.currentLocal.params.items){
        let indexEntree = this.currentLocal.params.items.findIndex((item) => { return item.type=="independant"});
        if(indexEntree!=-1){
          res = this.currentLocal.params.items[indexEntree].piece
        }
      }
      return res;
    },
    get_gaines_params(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="gaines"){
        console.log(this.currentParams);
        Object.keys(this.currentParams).filter(e => e !== 'type').forEach((item)=>{
          res.push({lidic: item});
        });
      }
      return res;
    },
    typologie_range(){
      let res = [];
      if(this.currentVMC!=null && this.currentVMC.typologie!=undefined && this.currentVMC.typologie_min!=undefined){
        let tmp = this.currentVMC.typologie_min;
        while(tmp<=this.currentVMC.typologie){
          res.push(tmp);
          tmp++;
        }
      }
      return res;
    },
    piece_technique_range(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="piece_technique" && this.currentParams['eau']!=null){
        res.push({value:'eau',text:"Salle d'eau"});
        res.push({value:'wc',text:"WC"});
        res.push({value:'sdb_wc',text:"Salle de bain avec WC"});
        res.push({value:'sdb',text:"Salle de bain"});
      }
      return res;
    },
    total_devis(){
      return this.items.length;
    },
    getCurrentConfig(){
      let res = [];
      Object.keys(this.currentVMC.params.config).forEach((item)=>{
        console.log(item);
        res.push({label:item});
      });
      return res;
    },
    total_prix(){
      let res = 0;
      this.items.forEach((item) => {
        res += parseFloat(item.prix);
      });
      res = res.toFixed(2);
      return res;
    },
    items(){
      let res;
      if((this.start!=null && this.start!="") && (this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime() && new Date(elem.created_at).getTime() <= new Date(this.end).getTime());
      }else if((this.start!=null && this.start!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime());
      }else if((this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime()<= new Date(this.end).getTime());
      }else{
        res = this.devis;
      }
      return res;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
