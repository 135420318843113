<template>
  <b-container id="collectif" fluid="xl" :style="cssProps" ref="collectif">
    <b-overlay :show="networkAction" rounded="sm" >
    <!--<div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Performair" id="performair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne" ref="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <router-link to="/chantier">Chantier</router-link> <span class='decoration'>></span> <span class='selected'>Tracé</span> <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
    </div>
    <h3 style="margin-bottom:1em;">Dimensionner mon projet</h3>!-->
    <b-row>
      <b-col md="6" lg="5" xl="4">
      <div class="header inner-shadow-box">
        <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Performair" id="performair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
      </div>
      </b-col>
      <b-col md="6" lg="7" xl="8">
        <b-row style="margin-bottom:0em"><b-col>
        <div class="arianne" ref="arianne">
          <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <router-link to="/chantier">Chantier</router-link> <span class='decoration'>></span> <span class='selected'>Tracé</span> <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
        </div>
        </b-col></b-row>
        <b-row style="margin-bottom:0em"><b-col>
<h3 style="margin-bottom:1em;">Dimensionner mon projet</h3>
        </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="!actionchoosed" class="chooseAction" align-h="center">
      <b-col cols="12" md="6" lg="3" href="#" @click="setTraceByInput(true)" class="trace-item">
        <h5>Saisie métrique</h5>
        <p>A partir de la lecture de vos plans ou d’un tracé, saisissez les longueurs et coudes de votre réseau,
<br><strong>obtenez le dimensionnement RE2020</strong> de votre projet</p>
        <div class='button-next'>
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="3" href="#" @click="setTraceByInput(false)" class="trace-item">
        <h5>Saisie graphique</h5>
        <p>Implanter votre groupe et les bouches directement sur votre plan, calcul automatique de <strong>dimensionnement</strong>, <br>obtenez votre <strong>plan d’installation conforme à la RE2020</strong></p>
        <div class='button-next'>
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </div>
      </b-col>
    </b-row>
    <b-form @submit="onSubmit" @reset="onReset" class="main_content" v-show="traceByInput || !$news">
      <b-row>
      <b-col cols="12" md="12" lg="12" style="margin-bottom: 2em;">
        <div class="content-block shadow-box">
          <div class='row header-form align-items-center' style="margin-bottom:0em;">
            <b-col cols="8">
              <p><span>Pièces</span><br>techniques</p>
            </b-col>
            <b-col cols="4">
              <b-img style="width:20%" src="../assets/form-icon.png" />
            </b-col>
          </div>
          <div class="content-form spinner">
            <b-col class="my-auto" style="padding:0;margin:0;">
              <b-row class="no-gutters">
                <b-col>
                  <b-row class="no-gutters" style="margin-bottom:1em;">
                    <b-col cols="4" style="margin-right: 1em;">
                      <label for="cuisine_checkbox" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">Cuisine</label>
                    </b-col>
                    <b-col cols="6" lg="6" md="8" sm="6">
                      <b-form-checkbox inline id="cuisine_checkbox" v-model="configTrace['cuisine']" switch @input="updateConfig('cuisine')" class="small-version"></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row class="no-gutters" style="margin-bottom:1em;">
                    <b-col cols="4" style="margin-right: 1em;">
                      <label for="sdb" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">Salle de bain</label>
                    </b-col>
                    <b-col cols="6" lg="6" md="8" sm="6">
                      <b-form-checkbox inline id="sdb" v-model="configTrace['sdb']" switch @input="updateConfig('sdb')" class="small-version"></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col><b-col>
                  <b-row class="no-gutters" style="margin-bottom:1em;">
                    <b-col cols="4" style="margin-right: 1em;">
                      <label for="wc" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">WC</label>
                    </b-col>
                    <b-col cols="6" lg="6" md="8" sm="6">
                      <b-form-checkbox inline id="wc" v-model="configTrace['wc']" switch @input="updateConfig('wc')" class="small-version"></b-form-checkbox>
                    </b-col>
                  </b-row>
                  </b-col><b-col>
                  <b-row class="no-gutters" style="margin-bottom:1em;">
                    <b-col cols="4" style="margin-right: 1em;">
                      <label for="sdb_wc" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">Salle de bain + WC</label>
                    </b-col>
                    <b-col cols="6" lg="6" md="8" sm="6">
                      <b-form-checkbox inline id="sdb_wc" v-model="configTrace['sdb_wc']" switch @input="updateConfig('sdb_wc')" class="small-version"></b-form-checkbox>
                    </b-col>
                  </b-row>
                  </b-col><b-col>
                  <b-row class="no-gutters" style="margin-bottom:1em;">
                    <b-col cols="4" style="margin-right: 1em;">
                      <label for="eau" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">Salle d'eau (cellier, buanderie...)</label>
                    </b-col>
                    <b-col cols="6" lg="6" md="8" sm="6">
                      <b-form-checkbox inline id="eau" v-model="configTrace['eau']" switch @input="updateConfig('eau')" class="small-version"></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </div>
      </b-col>
    </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6" xl="4" style="margin-bottom: 2em;" v-if="configTrace['cuisine']">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Cuisine</span></p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/piece-icon.png" style="max-width: 110px;" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row>
                <b-col cols="7">
                  <label :for="'cuisine_longueur_'">Longueur en m</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'cuisine_longueur_'" v-model="tempCuisine[0].longueur" min="1" size="sm" step=".1" @change="setConfig" class="small-version" :number="true"></b-form-input>
                  </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'cuisine_coude90_'">Nombre de coude 90°</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'cuisine_coude90_'+(index)" v-model="tempCuisine[0].coude_90" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'cuisine_coude45_'">Nombre de coude 45°</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'cuisine_coude45_'" v-model="tempCuisine[0].coude_45" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <!--<b-row>
                <b-col cols="7">
                  <label :for="'cuisine_gaine_'">Type de Gaine</label>
                </b-col>
                <b-col cols="3">
                  <b-form-select :id="'cuisine_gaine_'" v-model="tempCuisine[0].gaine.lidic" :options="typeGaine" size="sm"  @change="setConfig" class="small-version"></b-form-select>
                </b-col>
              </b-row>-->
              <!--<b-row>
                <b-col cols="7">
                  <label :for="'cuisine_diametre_'">Diamètre de Gaine</label>
                </b-col>
                <b-col cols="3">
                  <b-form-select :id="'cuisine_diametre_'" v-model="tempCuisine[0].diametre" :options="typeDiametre['cuisine'][0]" size="sm"  @change="setConfig" class="small-version"></b-form-select>
                </b-col>
              </b-row>-->
          <!--<b-row>
            <b-col>
              <b-button block variant="light" class="btn" @click.prevent="addRemoveConfig('eau',index,true)">Autres Salle d'Eau' <b-icon icon="plus"></b-icon></b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" style="text-align:left;">
              <b-button variant="danger" class="btn" @click.prevent="addRemoveConfig('eau',index,false)"><b-icon icon="trash-fill"></b-icon></b-button>
            </b-col>
          </b-row>-->
            </div>
          </div>
        </b-col>
        </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6" xl="4" v-for="(config, indexSDB) in tempSdb" :key="'sdb_'+indexSDB" style="margin-bottom: 2em;">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Salle</span><br>de bain {{indexSDB+1}}</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/piece-icon.png" style="max-width: 110px;" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row>
                <b-col cols="7">
                  <label :for="'sdb_longueur_'+(indexSDB)">Longueur en m</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'sdb_longueur_'+(index)" v-model="config.longueur" min="1" size="sm" step=".1" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'sdb_coude90_'+(indexSDB)">Nombre de coude 90°</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'sdb_coude90_'+(indexSDB)" v-model="config.coude_90" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                <label :for="'sdb_coude45_'+(indexSDB)">Nombre de coude 45°</label>
              </b-col>
              <b-col cols="3">
                <b-form-input inline type="number" :id="'sdb_coude45_'+(indexSDB)" v-model="config.coude_45" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
              </b-col>
            </b-row>
            <!--<b-row>
              <b-col cols="7">
                <label :for="'sdb_gaine_'+(indexSDB)">Type de Gaine</label>
              </b-col>
              <b-col cols="3">
                <b-form-select :id="'sdb_gaine_'+(indexSDB)" v-model="config.gaine.lidic" :options="typeGaine" size="sm"  @change="setConfig();updateDiametre('sdb',indexSDB);" class="small-version"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="7">
                <label :for="'sdb_diametre_'+(indexSDB)">Diamètre de Gaine</label>
              </b-col>
              <b-col cols="3">
                <b-form-select :id="'sdb_diametre_'+(indexSDB)" v-model="config.gaine.diametre" :options="typeDiametre['sdb'][indexSDB]" size="sm"  @change="setConfig" class="small-version"></b-form-select>
              </b-col>
            </b-row>-->
          <b-row>
            <b-col>
              <b-button block variant="light" class="btn" @click.prevent="addRemoveConfig('sdb',indexSDB,true)">Autres Salle de Bain <b-icon icon="plus"></b-icon></b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" style="text-align:left;">
              <b-button variant="danger" class="btn" @click.prevent="addRemoveConfig('sdb',indexSDB,false)"><b-icon icon="trash-fill"></b-icon></b-button>
            </b-col>
          </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="6" xl="4" v-for="(config, indexWC) in tempWC" :key="'wc_'+indexWC" style="margin-bottom: 2em;">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>WC</span><br> {{indexWC+1}}</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/piece-icon.png" style="max-width: 110px;" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row>
                <b-col cols="7">
                  <label :for="'wc_longueur_'+(indexWC)">Longueur en m</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'wc_longueur_'+(indexWC)" v-model="config.longueur" min="1" size="sm" @change="setConfig" class="small-version" step=".1" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'wc_coude90_'+(indexWC)">Nombre de coude 90°</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'wc_coude90_'+(indexWC)" v-model="config.coude_90" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
              <label :for="'wc_coude45_'+(indexWC)">Nombre de coude 45°</label>
            </b-col>
            <b-col cols="3">
              <b-form-input inline type="number" :id="'wc_coude45_'+(indexWC)" v-model="config.coude_45" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
            </b-col>
          </b-row>
          <!--<b-row>
            <b-col cols="7">
              <label :for="'wc_gaine_'+(indexWC)">Type de Gaine</label>
            </b-col>
            <b-col cols="3">
              <b-form-select :id="'wc_gaine_'+(indexWC)" v-model="config.gaine.lidic" :options="typeGaine" size="sm"  @change="setConfig();updateDiametre('wc',indexWC);" class="small-version"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7">
              <label :for="'wc_diametre_'+(indexWC)">Diamètre de Gaine</label>
            </b-col>
            <b-col cols="3">
              <b-form-select :id="'wc_diametre_'+(indexWC)" v-model="config.gaine.diametre" :options="typeDiametre['wc'][indexWC]" size="sm"  @change="setConfig" class="small-version"></b-form-select>
            </b-col>
          </b-row>-->
          <b-row>
            <b-col>
              <b-button block variant="light" class="btn" @click.prevent="addRemoveConfig('wc',indexWC,true)">Autres WC <b-icon icon="plus"></b-icon></b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" style="text-align:left;">
              <b-button variant="danger" class="btn" @click.prevent="addRemoveConfig('wc',indexWC,false)"><b-icon icon="trash-fill"></b-icon></b-button>
            </b-col>
          </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="6" xl="4" v-for="(config, indexSDBWC) in tempSdb_wc" :key="'sdbwc_'+indexSDBWC" style="margin-bottom: 2em;">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Salle</span><br>de bain avec WC {{indexSDBWC+1}}</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/piece-icon.png" style="max-width: 110px;" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row>
                <b-col cols="7">
                  <label :for="'sdbwc_longueur_'+(indexSDBWC)">Longueur en m</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'sdbwc_longueur_sdbwc_longueur_'+(indexSDBWC)" v-model="config.longueur" min="1" size="sm" @change="setConfig" class="small-version" step=".1" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'sdbwc_coude90_'+(indexSDBWC)">Nombre de coude 90°</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'sdbwc_coude90_'+(indexSDBWC)" v-model="config.coude_90" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'sdbwc_coude45_'+(indexSDBWC)">Nombre de coude 45°</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'sdbwc_coude45_'+(indexSDBWC)" v-model="config.coude_45" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <!--<b-row>
                <b-col cols="7">
                  <label :for="'sdbwc_gaine_'+(indexSDBWC)">Type de Gaine</label>
                </b-col>
                <b-col cols="3">
                  <b-form-select :id="'sdbwc_gaine_'+(indexSDBWC)" v-model="config.gaine.lidic" :options="typeGaine" size="sm"  @change="setConfig();updateDiametre('sdbwc',indexSDBWC);" class="small-version"></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'sdbwc_diametre_'+(indexSDBWC)">Diamètre de Gaine</label>
                </b-col>
                <b-col cols="3">
                  <b-form-select :id="'sdbwc_diametre_'+(indexSDBWC)" v-model="config.gaine.diametre" :options="typeDiametre['sdbwc'][indexSDBWC]" size="sm"  @change="setConfig" class="small-version"></b-form-select>
                </b-col>
              </b-row>-->
          <b-row>
            <b-col>
              <b-button block variant="light" class="btn" @click.prevent="addRemoveConfig('sdb_wc',indexSDBWC,true)">Autres Salle de Bain avec WC <b-icon icon="plus"></b-icon></b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" style="text-align:left;">
              <b-button variant="danger" class="btn" @click.prevent="addRemoveConfig('sdb_wc',indexSDBWC,false)"><b-icon icon="trash-fill"></b-icon></b-button>
            </b-col>
          </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="6" xl="4" v-for="(config, indexEau) in tempEau" :key="'eau_'+indexEau" style="margin-bottom: 2em;">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Salle</span><br>d'eau {{indexEau+1}}</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/piece-icon.png" style="max-width: 110px;" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row>
                <b-col cols="7">
                  <label :for="'eau_longueur_'+(indexEau)">Longueur en m</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'eau_longueur_'+(indexEau)" v-model="config.longueur" min="1" size="sm" @change="setConfig" class="small-version" step=".1" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'eau_coude90_'+(indexEau)">Nombre de coude 90°</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'eau_coude90_'+(indexEau)" v-model="config.coude_90" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
              <label :for="'eau_coude45_'+(indexEau)">Nombre de coude 45°</label>
            </b-col>
            <b-col cols="3">
              <b-form-input inline type="number" :id="'eau_coude45_'+(indexEau)" v-model="config.coude_45" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
            </b-col>
          </b-row>
          <!--<b-row>
            <b-col cols="7">
              <label :for="'eau_gaine_'+(indexEau)">Type de Gaine</label>
            </b-col>
            <b-col cols="3">
              <b-form-select :id="'eau_gaine_'+(indexEau)" v-model="config.gaine.lidic" :options="typeGaine" size="sm"  @change="setConfig();updateDiametre('eau',indexEau);" class="small-version"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7">
              <label :for="'eau_diametre_'+(indexEau)">Diamètre de Gaine</label>
            </b-col>
            <b-col cols="3">
              <b-form-select :id="'eau_diametre_'+(indexEau)" v-model="config.gaine.diametre" :options="typeDiametre['eau'][indexEau]" size="sm"  @change="setConfig" class="small-version"></b-form-select>
            </b-col>
          </b-row>-->
          <b-row>
            <b-col>
              <b-button block variant="light" class="btn" @click.prevent="addRemoveConfig('eau',indexEau,true)">Autres Salle d'Eau' <b-icon icon="plus"></b-icon></b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" style="text-align:left;">
              <b-button variant="danger" class="btn" @click.prevent="addRemoveConfig('eau',indexEau,false)"><b-icon icon="trash-fill"></b-icon></b-button>
            </b-col>
          </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="8" lg="6" style="margin-bottom: 2em;">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Rejet</span></p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/piece-icon.png" style="max-width: 110px;" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row v-if="customRejet" style="margin-top:1.5em;">
                <b-col cols="3">
                  <b-form-input inline placeholder="Marque" type="text" :id="'rejet_marque'" v-model="tempRejet.marque" size="sm" @change="setConfig" class="small-version"></b-form-input>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline placeholder="Type" type="text" :id="'rejet_type'" v-model="tempRejet.type" size="sm" @change="setConfig" class="small-version"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="7">
                      <label :for="'rejet_longueur_'">Longueur en m</label>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input inline type="number" :id="'rejet_longueur_'" v-model="tempRejet.longueur" min="1" size="sm" step=".1" @change="setConfig" class="small-version" :number="true"></b-form-input>
                      </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="7">
                      <label :for="'rejet_coude90_'">Nombre de coude 90°</label>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input inline type="number" :id="'rejet_coude90_'" v-model="tempRejet.coude_90" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="7">
                      <label :for="'rejet_coude45_'">Nombre de coude 45°</label>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.coude_45" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="customRejet" style="margin-top:1.5em;">
                <b-col cols="3">
                  <label :for="'rejet_coude45_'">Débit Min. en m3/h</label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.points_fonctionnement[0].debit" min="0" size="sm" @change="setConfig" class="small-version" :number="true" disabled></b-form-input>
                </b-col>
                <b-col cols="3">
                  <label :for="'rejet_coude45_'">Perte de charge au débit min en Pa<strong style="color:#666666">*</strong></label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.points_fonctionnement[0].pression" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row v-if="customRejet">
                <b-col cols="3">
                  <label :for="'rejet_coude45_'">Débit Max. en m3/h</label>
                </b-col>
                <b-col cols="3">

                  <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.points_fonctionnement[1].debit" min="0" size="sm" @change="setConfig" class="small-version" :number="true" disabled></b-form-input>
                </b-col>
                <b-col cols="3">
                  <label :for="'rejet_coude45_'">Perte de charge au débit max en Pa<strong style="color:#666666">*</strong></label>
                </b-col>
                <b-col cols="3">
                  <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.points_fonctionnement[1].pression" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
                </b-col>
              </b-row>
              <b-row v-if="customRejet">
                <b-col>
                  <p><strong style="color:#666666">*se reporter aux données du produit et saisir les valeurs correspondantes </strong></p>
                </b-col>
              </b-row>
          <!--<b-row>
            <b-col>
              <b-button block variant="light" class="btn" @click.prevent="addRemoveConfig('eau',index,true)">Autres Salle d'Eau' <b-icon icon="plus"></b-icon></b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" style="text-align:left;">
              <b-button variant="danger" class="btn" @click.prevent="addRemoveConfig('eau',index,false)"><b-icon icon="trash-fill"></b-icon></b-button>
            </b-col>
          </b-row>-->
            </div>
          </div>
        </b-col>
      </b-row>
    <b-row class="footer align-items-center no-gutters">
      <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
        <b-button @click="back($event)">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
      <b-col md="8" cols="12" order="1" order-md="2"></b-col>
      <b-col md="2" cols="6" order="3" order-md="3" style="text-align:right;">
        <b-button type="submit" class="next" :disabled="!validate">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
    </b-row>
    </b-form>
    <b-form @submit="onSubmitDraw" @reset="onResetDraw" class="main_content" v-show="traceByDraw" ref="traceByInput" v-if="$news">
      <b-row class="headerToDraw align-items-between no-gutters" align-v="center">
        <b-col md="1" cols="6" order="2" order-md="1" style="text-align:left;">
          <b-button class="prev" @click="prevStep($event)">
            <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
          </b-button>
        </b-col>
        <b-col md="10" cols="12" order="1" order-md="2" class="content inner-shadow-box">
          <div class="content-block" style="background:none;">
            <b-form-file v-if="firstStep" v-model="planChantier" browse-text="Parcourir" multiple placeholder="Importez mes plans (fichier pdf, image)" ref="planUploader" class="flex-grow-1"></b-form-file>
            <b-button v-if="!disableScale" variant="light" class="btn" @click="defineScaleBtn($event)">Définir Echelle</b-button>
            <b-button v-if="(planChantierSrc.length>1) && !disableAnchor" variant="light" class="btn" @click="defineAnchorBtn($event)">Définir Ancrage</b-button>
            <b-button v-if="!disableCaisson || !disableRejet" variant="dark" class="btn" @click="placeVMC($event)" :disabled="placedVMC">Placer le groupe de ventilation et tracez votre reseau</b-button>
            <b-button v-if="!disableCaisson || !disableRejet" variant="primary" class="btn" @click="placeEA($event)">Placer Entrée d'air</b-button>
            <b-button v-if="!disableCaisson || !disableRejet" variant="primary" class="btn" @click="placeBalyage($event)">Placer Balayage</b-button>
            <p style="text-align:center;margin-top:1em;margin-bottom:0em;">{{message[steps][message_type]}}</p>
          </div>
        </b-col>
        <b-col md="1" cols="6" order="3" order-md="3" style="text-align:right;">
          <b-button class="next" @click="nextStep($event)" :disabled="disableValidateTrace && (!disableCaisson || !disableRejet)">
            <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
          </b-button>
        </b-col>
      </b-row>
      <b-row id="menuScale" class="menuTrace">
              <b-col style="text-align:center;">
                <label :for="'scale_longueur'">Définir la longueur en <strong>m</strong></label>
                <b-form-input inline type="number" :id="'scale_longueur'" v-model="scale[selectedLayer].cm" min="0" step=".1" size="sm" @change="setConfig" class="small-version" :number="true" style="display: inline-block;width: auto;margin-left: 1em;"></b-form-input>
                <b-button block variant="light"  @click="closeMenuScaleBranche()" style="width: auto;display: inline;padding: 4px 10px;margin-left: 1em;">Valider</b-button>
              </b-col>
      </b-row>
      <b-row id="menuRejet" class="menuTrace">
          <b-col cols="3">Autre Rejet Conforme CPT</b-col>
          <b-col cols="3">
            <b-form-input inline placeholder="Marque" type="text" :id="'rejet_marque'" v-model="tempRejet.marque" size="sm" @change="setConfig" class="small-version"></b-form-input>
          </b-col>
          <b-col cols="3">&nbsp;</b-col>
          <b-col cols="3">
            <b-form-input inline placeholder="Type" type="text" :id="'rejet_type'" v-model="tempRejet.type" size="sm" @change="setConfig" class="small-version"></b-form-input>
          </b-col>
          <div class="w-100" style="margin-bottom:0.5em"></div>
          <b-col cols="3">
            <label :for="'rejet_coude45_'">Débit Min. en m3/h</label>
          </b-col>
          <b-col cols="3">
            <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.points_fonctionnement[0].debit" min="0" size="sm" @change="setConfig" class="small-version" :number="true" disabled></b-form-input>
          </b-col>
          <b-col cols="3">
            <label :for="'rejet_coude45_'">Perte de charge au débit min en Pa<strong style="color:#666666">*</strong></label>
          </b-col>
          <b-col cols="3">
            <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.points_fonctionnement[0].pression" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
          </b-col>
          <div class="w-100" style="margin-bottom:0.5em"></div>
          <b-col cols="3">
            <label :for="'rejet_coude45_'">Débit Max. en m3/h</label>
          </b-col>
          <b-col cols="3">
            <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.points_fonctionnement[1].debit" min="0" size="sm" @change="setConfig" class="small-version" :number="true" disabled></b-form-input>
          </b-col>
          <b-col cols="3">
            <label :for="'rejet_coude45_'">Perte de charge au débit max en Pa<strong style="color:#666666">*</strong></label>
          </b-col>
          <b-col cols="3">
            <b-form-input inline type="number" :id="'rejet_coude45_'" v-model="tempRejet.points_fonctionnement[1].pression" min="0" size="sm" @change="setConfig" class="small-version" :number="true"></b-form-input>
          </b-col>
          <div class="w-100" style="margin-bottom:0.5em"></div>
          <b-col>
            <p><strong style="color:#666666">*se reporter aux données du produit et saisir les valeurs correspondantes </strong></p>
          </b-col>
          <b-col>
            <b-button block variant="light"  @click="closeMenuCreateRejet()">Fermer</b-button>
          </b-col>
      </b-row>
      <b-row id="menuCreateBranche" class="menuTrace" align-h="between">
          <b-col cols="12" md>
            <h5>Bouche</h5>
          </b-col>
          <div class="w-100" style="margin-bottom:0.5em"></div>
          <b-col cols="12" md>
            <b-button variant="light" @click="createBranche('cuisine')"><span v-if="!pluggit">Cuisine</span><span v-if="pluggit">Cuisine<br><small>+ raccord ABM coudé 90°</small><img :src='"/img/abm_coude.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="createBranche('sdb')"><span v-if="!pluggit">Salle de bain</span><span v-if="pluggit">Salle de bain<br><small>+ raccord ABM coudé 90°</small><img :src='"/img/abm_coude.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="createBranche('sdb_wc')"><span v-if="!pluggit">Salle de bain avec WC</span><span v-if="pluggit">Salle de bain avec WC<br><small>+ raccord ABM coudé 90°</small><img :src='"/img/abm_coude.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="createBranche('wc')"><span v-if="!pluggit">WC</span><span v-if="pluggit">WC<br><small>+ raccord ABM coudé 90°</small><img :src='"/img/abm_coude.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="createBranche('eau')"><span v-if="!pluggit">Salle d'eau <small>(cellier, buanderie...)</small></span><span v-if="pluggit">Salle d'eau <small>(cellier, buanderie...)</small><br><small>+ raccord ABM coudé 90°</small><img :src='"/img/abm_coude.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md v-if="!placedRejet">
            <b-button variant="light" @click="createBranche('rejet')">Rejet</b-button>
          </b-col>
          <div class="w-100" style="margin-bottom:1em"></div>
          <b-col cols="12" md>
            <b-button variant="light" @click="defaultCoude('90');createBranche('cuisine')"><span v-if="!pluggit">Cuisine avec coude 90°</span><span v-if="pluggit">Cuisine<br><small>+ raccord ABM en ligne</small><img :src='"/img/abm_ligne.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="defaultCoude('90');createBranche('sdb')"><span v-if="!pluggit">Salle de bain avec coude 90°</span><span v-if="pluggit">Salle de bain<br><small>+ raccord ABM en ligne</small><img :src='"/img/abm_ligne.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="defaultCoude('90');createBranche('sdb_wc')"><span v-if="!pluggit">Salle de bain avec WC avec coude 90°</span><span v-if="pluggit">Salle de bain avec WC<br><small>+ raccord ABM en ligne</small><img :src='"/img/abm_ligne.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="defaultCoude('90');createBranche('wc')"><span v-if="!pluggit">WC avec coude 90°</span><span v-if="pluggit">WC<br><small>+ raccord ABM en ligne</small><img :src='"/img/abm_ligne.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="defaultCoude('90');createBranche('eau')"><span v-if="!pluggit">Salle d'eau <small>(cellier, buanderie...)</small> avec coude 90°</span><span v-if="pluggit">Salle d'eau <small>(cellier, buanderie...)</small><br><small>+ raccord ABM en ligne</small><img :src='"/img/abm_ligne.png"' class="abm_picto"/></span></b-button>
          </b-col>
          <b-col cols="12" md v-if="!placedRejet">
            <p>&nbsp;</p>
          </b-col>
          <div class="w-100" style="margin-bottom:1.5em"></div>
          <b-col cols="12" md>
            <h5>Continuer</h5>
          </b-col>
          <div class="w-100" style="margin-bottom:0.5em"></div>
          <b-col cols="12" md>
            <b-button variant="light" @click="editCoude(1,90)">Ajouter Coude 90°</b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button variant="light" @click="editCoude(1,45)">Ajouter Coude 45°</b-button>
          </b-col>
          <b-col cols="12" md v-if="planChantierSrc.length>1">
            <b-button variant="light" @click="createBranche('colonne')" >Traversée d'étage</b-button>
          </b-col>
          <b-col cols="12" md>
            <b-button block variant="light"  @click="closeMenuCreateBranche(true)">Fermer</b-button>
          </b-col>
          <b-col cols="12" md v-if="planChantierSrc.length==1">
            <p>&nbsp;</p>
          </b-col>
          <b-col cols="12" md v-if="planChantierSrc.length>=1">
            <p>&nbsp;</p>
          </b-col>
          <b-col cols="12" md v-if="planChantierSrc.length>=1 && !placedRejet">
            <p>&nbsp;</p>
          </b-col>
      </b-row>
      <b-row id="menuEditBranche" class="menuTrace">
          <b-col cols="12" md>
            <h5>Continuer</h5>
          </b-col>
          <div v-show="rejetBranche" class="w-100" style="display:flex">
            <div class="w-100" style="margin-bottom:0"><h3 style="margin-bottom: 0;margin-left: 1em;">Type de Rejet</h3></div>
            <b-col class="options-content rejet" :class="'rejets_'+rejet.lidic" cols="12" md="6" lg="3" v-for="(rejet,index) in rejets" :key="index" @click="selectRejet(rejet,index,$event)">
              <h6><strong>{{firstWord(rejet.libelle)}}</strong><br> {{restWord(rejet.libelle)}}</h6>
              <b-img :src="`/img/${rejet.lidic}.jpg`" fluid class='vignette'/>
            </b-col>
            <b-col class="options-content rejet" :class="'rejets_custom'" cols="12" md="6" lg="3" @click="selectRejet({lidic:'custom',longueur_min:0,diametre:160,points_fonctionnement:[]},-1,$event)">
              <h6><strong>Autre</strong><br> Rejet Conforme CPT</h6>
              <b-img :src="`/img/874478.jpg`" fluid class='vignette'/>
            </b-col>
          </div>
          <div class="w-100" style="margin-bottom:0.5em"></div>
            <b-col cols="12" md>
              <b-button variant="light" @click="editCoude(1,90)">Ajouter Coude 90°</b-button>
            </b-col>
            <b-col cols="12" md>
              <b-button variant="light" @click="editCoude(1,45)">Ajouter Coude 45°</b-button>
            </b-col>
            <b-col cols="12" md>
              <b-button variant="light" @click="editCoude(0,90)">Retirer Coude 90°</b-button>
            </b-col>
            <b-col cols="12" md>
              <b-button variant="light" @click="editCoude(0,45)">Retirer Coude 45°</b-button>
            </b-col>
            <b-col cols="12" md>
              <b-button variant="light" @click="deleteBranche()">Supprimer Branche</b-button>
            </b-col>
            <b-col cols="12" md><b-button block variant="light" @click="closeMenuEditBranche()">Fermer</b-button></b-col>

      </b-row>
      <b-row align-h="center" align-v="center" style="margin-bottom:0.5em;">
          <b-col style="text-align:center">
            <b-button variant="light" class="btn" v-if="selectedLayer>0 && planChantierSrc.length>1" @click="changeLayer(-1)" style="display:inline;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg></b-button>
            <b-form-input type="text" style="display:inline-block;width:auto;" v-model="planChantierLabel[selectedLayer]" :disabled="!firstStep" v-if="planChantierSrc.length>0" />
            <b-button variant="light" class="btn" v-if="(selectedLayer<(planChantierSrc.length-1)) && planChantierSrc.length>1" @click="changeLayer(1)" style="display:inline;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></b-button>
            <b-button variant="light" class="btn" @click="deleteLayer()" style="display:inline" v-if="firstStep && planChantierSrc.length>1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
</svg></b-button>
        </b-col>
      </b-row>
      <b-row style="border:1px solid black;margin-left:0px;margin-right:0px;">
        <b-col style="padding:0px">
            <v-stage :config="configKonva" ref="stage" id="plan" style="width:100%;height;auto;flex: 1 1 auto;min-height:750px">
              <v-layer ref="layer">
              </v-layer>
            </v-stage>
        </b-col>
      </b-row>
    </b-form>
    <b-card class="mt-3" v-if="debugging" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
    <template #overlay>
      <div class="text-center">
          <b-icon icon="hourglass-split" font-scale="3" animation="spin"></b-icon>
          <p>Calcul du dimensionnement en cours selon le débit de votre connexion internet pour charger les plans.</p>
      </div>
    </template>
  </b-overlay>
  </b-container>
</template>

<script>
(function(Konva){

function qrDecompose(a) {
  let angle = Math.atan2(a[1], a[0]),
    denom = Math.pow(a[0], 2) + Math.pow(a[1], 2),
    scaleX = Math.sqrt(denom),
    scaleY = (a[0] * a[3] - a[2] * a [1]) / scaleX;

  return {
    rotation: angle / (Math.PI / 180),
    scaleX: scaleX,
    scaleY: scaleY,
    x: a[4],
    y: a[5]
  };
}

function getGroupCoords(object, group) {
  let mB = object.getAbsoluteTransform().getMatrix();
  let mX = group.getAbsoluteTransform().getMatrix();

  //possible to replace with mB * mX.invert()
  let M = mB[0], N = mB[1], O = mB[2], P = mB[3], R = mB[4], S = mB[5],
    A = mX[0], B = mX[1], C = mX[2], D = mX[3], E = mX[4], F = mX[5],
    AD = A * D,
    BC = B * C,
    G = (C * N - M * D) / (BC - AD),
    H = (A * N - M * B) / (AD - BC),
    I = (C * P - O * D) / (BC - AD),
    J = (A * P - O * B) / (AD - BC),
    K = (C * (S - F) + D * (E - R)) / (BC - AD),
    L = (A * (S - F) + B * (E - R)) / (AD - BC);


  let matrix = [G, H, I, J, K, L],
    options = qrDecompose(matrix);

  return options;
}

/**
 * enable Cropping On DblClick
 */
Konva.Image.prototype.enableCropOnDblClick = function () {
  this.on('dblclick', function () {
    this.transformActive = false;
    this.cropStart();
  });
};

Konva.Image.prototype.setCropTransform = function (value) {
  if(value === false){
    delete this._cropElement;
    return;
  }
  if (!this._cropElement) {
    this._cropElement = new Konva.Shape();
  }
  this._cropElement.setAttrs(value);
  this._cropElement.setAttrs({
    offsetX: 0,
    offsetY: 0
  });
};

Konva.Image.prototype.getCropTransform = function () {
  return this._cropElement && this._cropElement.getAttrs();
};

Konva.Image.prototype.cropTransform = function (value) {
  if (value) {
    this.setCropTransform(value);
  } else {
    return this.getCropTransform();
  }
};

Konva.Image.prototype.cropEnd = function () {
  if (this.cropImage) {
    this.transformer.destroy();
    this.cropImageTransformer.destroy();
    this.cropImage.off('dragmove', this.cropUpdateBinded);
    this.cropImage.off('transform', this.cropUpdateBinded);
    this.off('dragmove', this.cropUpdateBinded);
    this.off('transform', this.resizAndCropUpdateBinded);
    this.cropImage.remove();
    delete this.cropImage;
    delete this.transformer;
    delete this.cropImageTransformer;

    console.log("ok crop ended0");
    this.setAttrs({
      croppedEnded:false,
      cropElementEnd:JSON.stringify(this._cropElement)
    });
    console.log(this);
    this.getLayer().draw();

  }
};

Konva.Image.prototype.cropUpdate = function () {
  let options = getGroupCoords(this.cropImage, this);
  this.cropTransform(options);
  this.getLayer().draw();
};

Konva.Image.prototype.resize = function () {
  this.setAttrs({
    scaleX: 1,
    scaleY: 1,
    width: this.width() * this.scaleX(),
    height: this.height() * this.scaleY()
  });
};

Konva.Image.prototype.cropReset = function () {
  if (this.cropImage) {
    this.cropEnd();
  }
  this.setCropTransform(false);
  this.getLayer().draw();
};

Konva.Image.prototype.cropStart = function () {
  //this.getStage().find('Transformer').destroy();
  this.setAttrs({croppedEnded:false});
  if (this.cropImage) {
    return;
  }
  if (!this._cropElement) {
    this.cropTransform({
      x: 0,
      y: 0,
      width: this.width(),
      height: this.height(),
      rotation: 0
    })
  }
  let layer = this.getLayer(),
    transform = this.getAbsoluteTransform(),
    transform2 = this._cropElement.getAbsoluteTransform(),
    transform0 = layer.getAbsoluteTransform(),
    options = qrDecompose(transform0.copy().invert().multiply(transform).multiply(transform2).getMatrix());

  this.cropImage = new Konva.Image({
    stroke: this.stroke(),
    strokeWidth: this.strokeWidth(),
    image: this.image(),
    opacity: 0.2,
    draggable: false //change to move image or not
  });
  this.cropImage.isCroppingElement = true;
  this.cropImage.setAttrs(options);
  this.cropImage.setAttrs({
    width: this._cropElement.width(),
    height: this._cropElement.height(),
  });

  layer.add(this.cropImage);
  this.cropImageTransformer = new Konva.Transformer({
    borderDash: [5, 5],
    anchorSize: 21,
    anchorCornerRadius: 11
  })
    .attachTo(this.cropImage);

  this.transformer = new Konva.Transformer({
    rotateEnabled: false
  })
    .attachTo(this);

  //layer.add(this.cropImageTransformer, this.transformer);
  layer.add(this.transformer);
  this.cropUpdateBinded = this.cropUpdate.bind(this);

  this.resizAndCropUpdateBinded = function () {
    this.resize();
    this.cropUpdate()
  }.bind(this);

  this.resize();
  this.cropUpdate();
  this.cropImage.on('dragmove', this.cropUpdateBinded);
  this.cropImage.on('transform', this.cropUpdateBinded);
  this.on('dragmove', this.cropUpdateBinded);
  this.on('transform', this.resizAndCropUpdateBinded);

  this.getStage().on('click tap', (e) => {
    if (e.target !== this.cropImage && e.target !== this) {
      var croppedStatus = this.getAttr('croppedEnded');
      console.log("Cropped Status: "+croppedStatus);

      console.log("rotation");

      console.log(this.rotation());
      if(!croppedStatus){
        this.cropEnd();
        this.adjustSize();
      }
    }
  });
  layer.draw();
};

Konva.Image.prototype.adjustSize = function (){
    console.log("cropAction need to adjust width height");
    console.log(this.getAttr('croppedEnded'));
    this.setAttrs({
      croppedEnded: true
    });
    var img_width = this.width();
    var img_height = this.height();

    // calculate dimensions to get max 300px
    var max = this.getAttr('maxHeight');
    var maxWidth = this.getAttr('maxWidth');
    var ratio;
    var maxHeightImg=0;
    var maxWidthImg=0;
    var offsetX=0;
    var offsetY=0;
    if(img_width > img_height){
      ratio = (img_width / img_height);
      /*if((img_width*ratio)>maxWidth){
        if((maxWidth/ratio)>max){
          maxHeightImg = max;
          maxWidthImg = maxHeightImg*ratio;
        }else{
          maxWidthImg = maxWidth;
          maxHeightImg = maxWidthImg/ratio;
          offsetY = (max-maxHeightImg)/2;
        }
      }else{
        maxHeightImg = max;
        maxWidthImg = maxHeightImg*ratio;
      }*/
    }else{
      ratio = (img_height / img_width);
    /*  maxHeightImg = max;
      maxWidthImg = maxHeightImg/ratio;*/
    }
    /*console.log(img_width);
    console.log(img_height);
    console.log(maxWidthImg);
    console.log(maxHeightImg);*/

    /*var NewScaleX = maxWidthImg/img_width;
    var NewScaleY = maxHeightImg/img_height;

    console.log(NewScaleX);
    console.log(NewScaleY);
    this.scaleX(NewScaleX);
    this.scaleY(NewScaleY);*/

    var currentRotation = this.rotation();
    console.log("rogange after rotation");
    console.log(currentRotation);
    if(currentRotation==90){
      if(img_width > img_height){
        if((img_width*ratio)>max){
          if((max/ratio)>maxWidth){
            maxHeightImg = maxWidth;
            maxWidthImg = maxHeightImg*ratio;
          }else{
            maxWidthImg = max;
            maxHeightImg = maxWidthImg/ratio;
          }
        }else{
          maxWidthImg = max;
          maxHeightImg = maxWidthImg*ratio;
        }
      }else{
        maxWidthImg = max;
        maxHeightImg = maxWidthImg*ratio;
      }
      this.scaleX((maxWidthImg/img_width));
      this.scaleY((maxHeightImg/img_height));
      this.x(0+(this.height()*this.scaleY()));
      this.y(0);
    }else if(currentRotation==180){
      if(img_width > img_height){
        if((img_width*ratio)>maxWidth){
          if((maxWidth/ratio)>max){
            maxHeightImg = max;
            maxWidthImg = maxHeightImg*ratio;
          }else{
            maxWidthImg = maxWidth;
            maxHeightImg = maxWidthImg/ratio;
          }
        }else{
          maxHeightImg = max;
          maxWidthImg = maxHeightImg*ratio;
        }
      }else{
        maxHeightImg = max;
        maxWidthImg = maxHeightImg/ratio;
      }
      this.scaleX((maxWidthImg/img_width));
      this.scaleY((maxHeightImg/img_height));

      this.x(0+(this.width()*this.scaleX()));
      this.y(0+(this.height()*this.scaleY()));
    }else if(currentRotation==-90){
      if(img_width > img_height){
        if((img_width*ratio)>max){
          if((max/ratio)>maxWidth){
            maxHeightImg = maxWidth;
            maxWidthImg = maxHeightImg*ratio;
          }else{
            maxWidthImg = max;
            maxHeightImg = maxWidthImg/ratio;
          }
        }else{
          maxWidthImg = max;
          maxHeightImg = maxWidthImg*ratio;
        }
      }else{
        maxWidthImg = max;
        maxHeightImg = maxWidthImg*ratio;
      }
      this.scaleX((maxWidthImg/img_width));
      this.scaleY((maxHeightImg/img_height));

      this.x(0);
      this.y(0+(this.width()*this.scaleX()));
    }else if(currentRotation==-180){

      if(img_width > img_height){
        if((img_width*ratio)>maxWidth){
          if((maxWidth/ratio)>max){
            maxHeightImg = max;
            maxWidthImg = maxHeightImg*ratio;
          }else{
            maxWidthImg = maxWidth;
            maxHeightImg = maxWidthImg/ratio;
          }
        }else{
          maxHeightImg = max;
          maxWidthImg = maxHeightImg*ratio;
        }
      }else{
        maxHeightImg = max;
        maxWidthImg = maxHeightImg/ratio;
      }
      this.scaleX((maxWidthImg/img_width));
      this.scaleY((maxHeightImg/img_height));

      this.x(0+(this.width()*this.scaleX()));
      this.y(0+(this.height()*this.scaleY()));
    }else{
      if(img_width > img_height){
        if((img_width*ratio)>maxWidth){
          if((maxWidth/ratio)>max){
            maxHeightImg = max;
            maxWidthImg = maxHeightImg*ratio;
          }else{
            maxWidthImg = maxWidth;
            maxHeightImg = maxWidthImg/ratio;
          }
        }else{
          maxHeightImg = max;
          maxWidthImg = maxHeightImg*ratio;
        }
      }else{
        maxHeightImg = max;
        maxWidthImg = maxHeightImg/ratio;
      }
      this.scaleX((maxWidthImg/img_width));
      this.scaleY((maxHeightImg/img_height));
      this.x(offsetX);
      this.y(offsetY);
    }
}

Konva.Image.prototype._sceneFunc = function (context) {
  let width = this.width(),
    height = this.height(),
    image = this.image(),
    cropWidth,
    cropHeight,
    params;

  context.save();
  context.beginPath();
  context.rect(0, 0, width, height);
  context.closePath();
  context.clip();
  if (this.hasFill() || this.hasStroke()) {
    context.fillStrokeShape(this);
  }

  if (image) {
    if (this._cropElement) {
      context.save();
      width = this._cropElement.width();
      height = this._cropElement.height();
      let transform = this._cropElement.getAbsoluteTransform();
      let m = transform.getMatrix();
      context.transform(m[0], m[1], m[2], m[3], m[4], m[5]);
    }

    cropWidth = this.cropWidth();
    cropHeight = this.cropHeight();
    if (cropWidth && cropHeight) {
      params = [
        image,
        this.cropX(),
        this.cropY(),
        cropWidth,
        cropHeight,
        0,
        0,
        width,
        height
      ];
    } else {
        params = [image, 0, 0, width, height];
    }

    context.drawImage.apply(context, params);

    if (this._cropElement) {
      context.restore();
    }
  }
  context.strokeShape(this);
  context.restore();
  /*if(this.getAttr('croppedEnded')==true){
    this.adjustSize();
  }*/
};
})(window.Konva)

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => { var tmpRes = reader.result; if(tmpRes.includes('data:application/pdf;base64')){
      console.log("pdf detected");
      var BASE64_MARKER = ';base64,';
      var dataURI = tmpRes;
      var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      var base64 = dataURI.substring(base64Index);
      var raw = window.atob(base64);
      var rawLength = raw.length;
      var pdfJSarray = new Uint8Array(new ArrayBuffer(rawLength));

      for(var i = 0; i < rawLength; i++) {
        pdfJSarray[i] = raw.charCodeAt(i);
      }
      var loadingTask = window.pdfjsLib.getDocument(pdfJSarray);
      loadingTask.promise.then(function(pdf) {
          // please be aware this uses .range() function from lodash
          var mapArrays = Array(pdf.numPages).fill().map((x,i)=>(i+1));
          var pagePromises = mapArrays.map(function(number) {
              return pdf.getPage(number);
          });
          return Promise.all(pagePromises);
      }).then(function(pages) {
              var scale = 1.5;
              var pdfRes = [];
              var pdfPromises = [];
              var canvasRes = [];
              pages.forEach(function(page,indexPage) {
                  var viewport = page.getViewport({ scale: scale, }); // Prepare canvas using PDF page dimensions

                  var canvas = document.createElement('canvas');
                  canvas.id = 'pdf_'+indexPage;
                  console.log(canvas.id);
                  canvas.height = viewport.height;
                  canvas.width = viewport.width; // Render PDF page into canvas context

                  var canvasContext = canvas.getContext('2d');
                  var renderContext = {
                      canvasContext: canvasContext,
                      viewport: viewport
                  };
                  canvasRes.push(canvas);
                  pdfPromises.push(page.render(renderContext).promise);

              });
              Promise.all(pdfPromises).then(function(pagesData){
                pagesData.forEach(function(page,indexPage) {
                  console.log(indexPage);
                  console.log(canvasRes[indexPage]);
                  pdfRes.push(canvasRes[indexPage].toDataURL());
                });
                resolve(pdfRes);
              });
          },
          function(error) {
              return console.log('Error', error);
          });
    }else{ resolve(tmpRes);} };
    reader.onerror = error => reject(error);
  });

export default {
  name: 'Trace',
  data() {
      return {
        networkAction: false,
        rejets:[],
        oldPosX:-1,
        oldPosY:-1,
        firstStep: true,
        steps:1,
        message_type:'info',
        message:[
          "",
          {info: "Après avoir chargé votre plan vous pouvez le faire pivoter et l'agrandir si besoin.", error:''},
          {info: "Définissez l'échelle de votre/vos plans. Cliquez sur « définir échelle », pour dessiner un trait de cotation en allant sur le plan : cliquez (point de départ),  déplacez la souris,  cliquez , une flèche rouge apparait. Définissez la longueur en m que ce trait représente, validez.", error:"Les échelles n'ont pas été défini sur tout vos plans."},
          {info: "Définissez le point d'ancrage de vos plans, afin de pouvoir les lier.", error:"Les points d'ancrage n'ont pas été défini sur tous vos plans."},
          {info: "Cliquez sur «Placez le groupe… », le groupe apparait en noir, déplacez le groupe à l’endroit désiré en maintenant le clic gauche de la souris. Le groupe est positionné. Pour faire les branches de réseaux, venez juste cliquer sur le groupe (relâcher le clic) et déplacer votre souris pour aller à la bouche désirée, clic gauche pour dessiner la branche et clic droit pour activer la boite de dialogue et faire chacune des branches. Une fois la bouche positionnée, revenez cliquer sur le groupe pour faire une nouvelle branche, ainsi de suite jusqu’au positionnement du rejet (obligatoire). Les branches sont modifiables en cliquant (clic droit) sur la bouche et le groupe est déplaçable avec clic gauche maintenu. Passer à l’étape suivante pour obtenir le dimensionnment.", error:""},
        ],
        disableValidateTrace:true,
        loadingSaveChantier:false,
        defineEA: false,
        defineBalyage: false,
        countEA: 0,
        countBalyage:0,
        transformActive: false,
        disableAnchor: true,
        disableColumn: true,
        disableScale: true,
        placedVMC:false,
        placedRejet:false,
        placedCuisine: false,
        disableCaisson: true,
        disableRejet: true,
        defineScale:[false],
        defineBranche:false,
        defineRejet:true,
        oldRotation: 0,
        firstPointDefine: false,
        selectedBranche:-1,
        rejetBranche: false,
        moveIndexArrow: -1,
        poseIndexArrow: {
          x: -1,
          y: -1
        },
        typeDiametreTmp : {
          'cuisine':[120],
          'sdb':[],
          'wc':[],
          'sdbwc':[],
          'eau':[],
        },
        typeGaine : [
        ],
        addDefaultCoude: false,
        imageBouche: null,
        imageRejet: null,
        balayageBouche: null,
        eaBouche: null,
        scale:[{px:0,cm:0}],
        defineAnchor:[false],
        defineColumn: false,
        tmpPos:null,
        newBranche:null,
        mainLayer:null,
        selectedLayer:0,
        mainStage:null,
        mainVMC:null,
        mainRejet:null,
        maxHeight : 0,
        maxWidth : 0,
        configKonva: {
        width: 200,
        height: 200
        },
        configCircle: {
          x: 100,
          y: 100,
          radius: 70,
          fill: "red",
          stroke: "black",
          strokeWidth: 4
        },
        planChantier: [],
        planChantierSrc: [],
        planChantierLabel:[],
        debugging:false,
        traceByInput: false,
        traceByDraw: false,
        pluggit:false,
        validate: false,
        customRejet:false,
        tempSdb: [],
        tempEau: [],
        tempSdb_wc: [],
        tempWC: [],
        visualBranche: 0,
        ptBranche:0,
        debit_min: {total:0,eau:0,wc:0,sdb:0,sdbwc:0,cuisine:0},
        debit_max: {total:0,eau:0,wc:0,sdb:0,sdbwc:0,cuisine:0, total_fuite:0},
        debit_fuite: {total:0,eau:0,wc:0,sdb1:0,sdb2:0,sdbsupp:0,sdbwc1:0,sdbwc2:0,sdbwcsupp:0,cuisine:0},
        debit_branche:{total:0,eau:0,wc:0,sdb:0,sdbwc:0,cuisine:0},
        tempCuisine: [{longueur:0,coude_90:0,coude_45:0,diametre:125,pression:0}],
        tempRejet: {longueur:0,coude_90:0,coude_45:0, points_fonctionnement:[]},
        defaultCoude90: 0,
        defaultCoude45: 0,
        defaultGaine: -1,
        defaultDiametre: 80,
        configTrace:{eau:0,sdb:0,sdb_wc:0,wc:0,cuisine:true},
        finalConfigAppartement:{1:0,2:0,3:0,4:0,5:0,6:0,7:0},
        actionchoosed: false,
        items: [
          {
            text: 'Accueil',
            href: '/'
          },
          {
            text: 'Trace',
            active: true
          }
        ],
      }
    },
    methods: {
      /*updateDiametre(typo,index){
        console.log(typo);
        console.log(index)
        switch(typo){
          case 'sdb':
            if(this.tempSdb[index].gaine.lidic == 813930){
              //this.tempSdb[index].gaine.diametre = 80;
              this.typeDiametreTmp[typo][index] = [{value:80,text:'80mm'},{value:150,text:'150mm'}];
            }else{
              console.log("super gaine sdb");
              console.log(index);
              console.log(typo);
              console.log(this.tempSdb);
              //this.tempSdb[index].gaine.diametre = 90;
              this.typeDiametreTmp[typo][index] = [{value:90,text:'90mm'},{value:180,text:'2x90mm'}];
            }
          break;
          case 'sdbwc':
            if(this.tempSdb_wc[index].gaine.lidic == 813930){
              this.tempSdb_wc[index].gaine.diametre = 80;
              this.typeDiametreTmp[typo][index] = [{value:80,text:'80mm'},{value:150,text:'150mm'}];
            }else{
              this.typeDiametreTmp[typo][index] = [{value:90,text:'90mm'},{value:180,text:'2x90mm'}];
            }
          break;
          case 'eau':
            if(this.tempEau[index].gaine.lidic == 813930){
              this.tempEau[index].gaine.diametre = 80;
              this.typeDiametreTmp[typo][index] = [{value:80,text:'80mm'},{value:150,text:'150mm'}];
            }else{
              this.tempEau[index].gaine.diametre = 90;
              this.typeDiametreTmp[typo][index] = [{value:90,text:'90mm'},{value:180,text:'2x90mm'}];
            }
          break;
          case 'wc':
          console.log('WC INDEX');
          console.log(this.tempWC);
          console.log(index);
            if(this.tempWC[index].gaine.lidic == 813930){
              this.tempWC[index].gaine.diametre = 80;
              this.typeDiametreTmp[typo][index] = [{value:80,text:'80mm'},{value:150,text:'150mm'}];
            }else{
              this.tempWC[index].gaine.diametre = 90;
              this.typeDiametreTmp[typo][index] = [{value:90,text:'90mm'},{value:180,text:'2x90mm'}];
            }
          break;
        }
      },*/
      firstWord(libelle){
        return libelle.split(" ")[0];
      },
      restWord(libelle){
        var tmp = libelle.split(" ");
        tmp.shift();
        return tmp.join(" ");
      },
      selectRejet(rejet,index2,ev){
        console.log(rejet);
        //let res = this.form;
        if(!ev.currentTarget.classList.contains('active')){
          let x = document.getElementsByClassName("rejet");
          for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
          ev.currentTarget.classList.toggle("active");
          //res.rejet = rejet;
          this.$store.state.devis.devis['chantier'].rejet = rejet;
          this.tempRejet.points_fonctionnement = this.$store.state.devis.devis['chantier'].rejet.points_fonctionnement;
          if(this.$store.state.devis.devis['chantier'].rejet.lidic=="custom"){
            this.customRejet=true;
            //debit doit pas être à 0;
            console.log(this.debit);
            this.tempRejet.points_fonctionnement[0] = {debit:0,pression:0};
            this.tempRejet.points_fonctionnement[1] = {debit:0,pression:0};

            this.tempRejet.points_fonctionnement[0].debit = this.debit_min.total.toFixed(1);
            this.tempRejet.points_fonctionnement[1].debit = this.debit_max.total.toFixed(1);

            var menuNodeScale = document.getElementById('menuRejet');
            menuNodeScale.style.display = 'flex';
          }else{
            this.customRejet=false;
            var menuNodeScaleCR = document.getElementById('menuRejet');
            menuNodeScaleCR.style.display = 'none';
          }
          this.$store.dispatch('devis/updateRejet',rejet);
        }
        //this.form = res;
      },
      nextStep(evt){
        console.log(this.steps);
        switch(this.steps){
          case 1:
            this.validatePlanBtn(evt);
          break;
          case 2:
            this.validateScaleBtn(evt);
          break;
          case 3:
            this.validateAnchorBtn(evt);
          break;
          case 4:
            this.validateTrace(evt);
          break;
        }
      },
      prevStep(evt){
        evt.preventDefault();
        console.log(this.steps);
        switch(this.steps){
          case 1:
            this.actionchoosed = false;
          break;
          case 2:
            this.firstStep = true;
            this.disableScale = true;
            for(var i = 0 ; i < this.planChantierSrc.length ; i++){
              var tmpPlan = this.mainStage.find('#plan_'+i);
              tmpPlan[0].listening(true);

              var tmpScale = this.mainStage.find('#scale_'+i);
              if(tmpScale[0]){
                tmpScale[0].destroy();
              }
              var tmpScaleTag = this.mainStage.find('#scale_tag_'+i);
              if(tmpScaleTag[0]){
                tmpScaleTag[0].destroy();
              }
            }
            this.scale.fill({
              px:0,
              cm:0
            });
            this.defineScale.fill(false);
            this.steps--;
          break;
          case 3:
            this.disableScale = false;
            this.disableAnchor = true;
            this.mainStage.find('Circle').forEach((circleNode) => {
                var anchor = circleNode.getAttr('anchor');
                if(anchor==true){
                  circleNode.destroy();
                }
            });
            this.steps--;
          break;
          case 4:
          //remove all vmc branche pt visual
          this.mainStage.find('Line').forEach((lineNode) => {
            lineNode.destroy();
          });
          this.mainStage.find('Image').forEach((imageNode) => {
              var bouche = imageNode.getAttr('bouche');
              var rejet = imageNode.getAttr('rejet');
              if(bouche || rejet){
                imageNode.destroy();
              }
          });
          this.mainStage.find('Circle').forEach((circleNode) => {
              var anchor = circleNode.getAttr('anchor');
              if(anchor!=true){
                circleNode.destroy();
              }
          });

          //remove all branche logic
          this.tempSdb=[];
          this.tempEau=[];
          this.tempSdb_wc=[];
          this.tempWC=[];
          this.tempCuisine= [{longueur:0,coude_90:0,coude_45:0,diametre:120,pression:0}];
          this.tempRejet.longueur = 0;
          this.tempRejet.coude_90 = 0;
          this.tempRejet.coude_45 = 0;
          this.mainVMC.destroy();
          this.mainVMC = null;

          this.placedVMC = false;
          this.placedRejet = false;
          this.disableValidateTrace = true;
          this.disableCaisson = true;
          this.disableRejet = true;
          if(this.planChantierSrc.length>1){
            this.steps--;
            this.disableAnchor = false;
          }else{
            this.steps-=2;
            for(var j = 0; j < this.scale.length; j++){
              var tmpScaleListen = this.mainStage.find("#scale_"+j);
              if(tmpScaleListen[0]) {
                tmpScaleListen[0].draggable(true);
                tmpScaleListen[0].listening(true);
              }
            }
            this.disableScale = false;
          }

          //close all toolbox
          this.closeMenuCreateRejet();
          this.closeMenuEditBranche();
          var menuNode = document.getElementById('menuCreateBranche');
          menuNode.style.display = 'none';
          break;
        }

      },
      validateTrace(evt){
        evt.preventDefault();
        this.onSubmit(evt);
        /*var stageSaved = this.mainStage.toJSON();
        var layersImgSaved= [];
        var allLayers = this.mainStage.getLayers();
        for(var i = 1 ; i < allLayers.length ; i++){
          layersImgSaved.push(allLayers[i].toDataURL());
        }*/
      },
      validateAnchorBtn(evt){
        evt.preventDefault();
        var tmpAnchordone = 0;
        for (var tI = 0; tI < this.defineAnchor.length ; tI++){
          if(this.defineAnchor[tI]) tmpAnchordone++;
        }

        if(tmpAnchordone==this.defineAnchor.length){
          this.disableAnchor = true;
          this.steps++;
          this.disableCaisson = false;
          this.message_type = 'info';
        }else{
          this.message_type = 'error';
        }
      },
      validateScaleBtn(evt){
        evt.preventDefault();
        var scaleDone=0;
        for(var i = 0; i < this.scale.length; i++){
          if(this.scale[i].cm!=0) scaleDone++;
        }
        console.log(scaleDone);
        console.log(this.scale.length);
        if(scaleDone==this.scale.length){
          this.disableScale = true;
          console.log("ok");
          console.log(this.planChantierSrc.length);
          if(this.planChantierSrc.length>1){
            this.disableAnchor = false;
            this.steps++;
          }else{

            this.disableCaisson = false;
            console.log(this.disableCaisson);
            console.log(this.disableRejet);
            this.steps+=2;
            console.log(this.steps);
          }
          this.message_type = 'info';

          //stop listen on scale
          for(var j = 0 ; j < this.scale.length; j++){
            var tmpScale = this.mainStage.find("#scale_"+j);
            if(tmpScale[0]) {
              tmpScale[0].draggable(false);
              tmpScale[0].listening(false);
            }
          }
        }else{
          this.message_type = 'error';
        }
      },
      validatePlanBtn(evt){
        evt.preventDefault();
        this.firstStep = false;
        console.log(this.planChantier);
        console.log(this.planChantierSrc);
        console.log(this.mainStage.find('Image'));

        console.log(this.mainStage.find('Layer'));
        for(var i = 0 ; i < this.planChantierSrc.length ; i++){
          console.log(i);
          var tmpPlan = this.mainStage.find('#plan_'+i);
          tmpPlan[0].listening(false);
          tmpPlan[0].cropEnd();
          var tmpTransformer = this.mainStage.find("#transformer_"+i);
          tmpTransformer[0].nodes([]);
        }
        this.disableScale = false;
        this.steps++;
      },
      defineColumnBtn(evt){
        evt.preventDefault();
        //definir colonne clique droit menu creation branche
        this.defineColumn = true;
      },
      defineAnchorBtn(evt){
        evt.preventDefault();
        this.defineAnchor[this.selectedLayer] = true;
      },
      changeLayer(add){
        var tmpLayerId = '#layer_'+this.selectedLayer;
        var tmpLayer = this.mainStage.find(tmpLayerId);
        tmpLayer[0].hide();
        if(add==1){
          this.selectedLayer++;
        }else{
          this.selectedLayer--;
        }
        tmpLayerId = '#layer_'+this.selectedLayer;
        tmpLayer = this.mainStage.find(tmpLayerId);
        tmpLayer[0].show();
        this.mainLayer = tmpLayer[0];
        this.transformActive = false;
        /*this.mainLayer.batchDraw();*/
        if(this.firstStep){
          var tmpPlan = this.mainStage.find("#plan_"+this.selectedLayer);
          tmpPlan[0].cropStart();
        }
        if((this.planChantierSrc.length>1) && !this.disableAnchor){
          this.defineAnchor[this.selectedLayer] = true;
        }
      },
      deleteLayer(){
        var tmpLayerId = '#layer_'+this.selectedLayer;
        var tmpLayer = this.mainStage.find(tmpLayerId);
        var planLayer = tmpLayer[0].find("#plan_"+this.selectedLayer);
        planLayer[0].cropEnd();
        tmpLayer[0].hide();
        tmpLayer[0].destroy();
        this.scale.splice(0,1);
        this.planChantierSrc.splice(this.selectedLayer,1);
        this.mainStage.find('Layer').forEach((layerNode) => {
          var newIdLayer = "layer";
          var tmpLayerId = layerNode.getAttr('id').split('_');
          console.log(tmpLayerId[1]);
          console.log(this.selectedLayer);
          if(tmpLayerId[1]>this.selectedLayer){
            var planLayerID = "#plan_"+tmpLayerId[1];
            var planLayer = this.mainStage.find(planLayerID);
            var transformerLayerID = "#transformer_"+tmpLayerId[1];
            var transformerLayer = this.mainStage.find(transformerLayerID);
            tmpLayerId[1]--;
            newIdLayer+="_"+tmpLayerId[1];
            var newPlanLayerID = "plan_"+tmpLayerId[1];
            var newtransformerLayerID = "transformer_"+tmpLayerId[1];
            planLayer[0].setAttr('id',newPlanLayerID);
            transformerLayer[0].setAttr('id',newtransformerLayerID);
            console.log(newIdLayer);
            layerNode.setAttr('id',newIdLayer);
          }
        });
        console.log(this.selectedLayer);
        console.log(this.planChantierSrc.length);
        if(this.selectedLayer>=this.planChantierSrc.length) this.selectedLayer--;
        tmpLayerId = '#layer_'+this.selectedLayer;
        var tmpLayer2 = this.mainStage.find(tmpLayerId);
        tmpLayer2[0].show();
        this.mainLayer = tmpLayer2[0];
      },
      editCoude(toAdd,type){
        var id_branche = '#branche_'+this.selectedBranche+'_'+this.selectedLayer;
        var branche = this.mainLayer.find(id_branche);
        console.log(id_branche);
        console.log(toAdd);
        console.log(type);
        if(branche.length>0){
          console.log(branche[0].getAttr('typeBranche'));
          var toShowLabelEdit = false;
          var textLabelBrancheEdit = "";
          switch(branche[0].getAttr('typeBranche')){
            case 'cuisine':
              if(toAdd){
                if(type==90){
                  console.log(branche[0].getAttr('idBranche'));
                  this.tempCuisine[branche[0].getAttr('idBranche')].coude_90++;
                  if(this.tempCuisine[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempCuisine[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempCuisine[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempCuisine[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempCuisine[branche[0].getAttr('idBranche')].coude_45++;
                  if(this.tempCuisine[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempCuisine[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempCuisine[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempCuisine[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }else{
                if(type==90){
                  this.tempCuisine[branche[0].getAttr('idBranche')].coude_90--;
                  if(this.tempCuisine[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempCuisine[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempCuisine[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempCuisine[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempCuisine[branche[0].getAttr('idBranche')].coude_45--;
                  if(this.tempCuisine[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempCuisine[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempCuisine[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempCuisine[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }
            break;
            case 'sdb':
              if(toAdd){
                if(type==90){
                  this.tempSdb[branche[0].getAttr('idBranche')].coude_90++;
                  if(this.tempSdb[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempSdb[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempSdb[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempSdb[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempSdb[branche[0].getAttr('idBranche')].coude_45++;
                  if(this.tempSdb[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempSdb[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempSdb[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempSdb[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }else{
                if(type==90){
                  this.tempSdb[branche[0].getAttr('idBranche')].coude_90--;
                  if(this.tempSdb[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempSdb[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempSdb[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempSdb[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempSdb[branche[0].getAttr('idBranche')].coude_45--;
                  if(this.tempSdb[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempSdb[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempSdb[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempSdb[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }
            break;
            case 'sdb_wc':
              if(toAdd){
                if(type==90){
                  this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90++;
                  if(this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45++;
                  if(this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }else{
                if(type==90){
                  this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90--;
                  if(this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45--;
                  if(this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempSdb_wc[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }
            break;
            case 'wc':
              if(toAdd){
                if(type==90){
                  this.tempWC[branche[0].getAttr('idBranche')].coude_90++;
                  if(this.tempWC[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempWC[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempWC[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempWC[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempWC[branche[0].getAttr('idBranche')].coude_45++;
                  if(this.tempWC[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempWC[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempWC[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempWC[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }else{
                if(type==90){
                  this.tempWC[branche[0].getAttr('idBranche')].coude_90--;
                  if(this.tempWC[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempWC[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempWC[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempWC[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempWC[branche[0].getAttr('idBranche')].coude_45--;
                  if(this.tempWC[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempWC[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempWC[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempWC[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }
            break;
            case 'eau':
              if(toAdd){
                if(type==90){
                  this.tempEau[branche[0].getAttr('idBranche')].coude_90++;
                  if(this.tempEau[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempEau[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempEau[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempEau[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempEau[branche[0].getAttr('idBranche')].coude_45++;
                  if(this.tempEau[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempEau[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempEau[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempEau[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }else{
                if(type==90){
                  this.tempEau[branche[0].getAttr('idBranche')].coude_90--;
                  if(this.tempEau[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempEau[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempEau[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempEau[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempEau[branche[0].getAttr('idBranche')].coude_45--;
                  if(this.tempEau[branche[0].getAttr('idBranche')].coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempEau[branche[0].getAttr('idBranche')].coude_90 +' coude(s) 90';
                  }
                  if(this.tempEau[branche[0].getAttr('idBranche')].coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempEau[branche[0].getAttr('idBranche')].coude_45+' coude(s) 45';
                  }
                }
              }
            break;
            case 'rejet':
              if(toAdd){
                if(type==90){
                  this.tempRejet.coude_90++;
                  if(this.tempRejet.coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempRejet.coude_90 +' coude(s) 90';
                  }
                  if(this.tempRejet.coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempRejet.coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempRejet.coude_45++;
                  if(this.tempRejet.coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempRejet.coude_90 +' coude(s) 90';
                  }
                  if(this.tempRejet.coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempRejet.coude_45+' coude(s) 45';
                  }
                }
              }else{
                if(type==90){
                  this.tempRejet.coude_90--;
                  if(this.tempRejet.coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempRejet.coude_90 +' coude(s) 90';
                  }
                  if(this.tempRejet.coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempRejet.coude_45+' coude(s) 45';
                  }
                }else{
                  this.tempRejet.coude_45--;
                  if(this.tempRejet.coude_90>0){
                    toShowLabelEdit = true;
                    textLabelBrancheEdit = this.tempRejet.coude_90 +' coude(s) 90';
                  }
                  if(this.tempRejet.coude_45>0){
                    toShowLabelEdit = true;
                    if(textLabelBrancheEdit!=""){
                      textLabelBrancheEdit += ' | ';
                    }
                    textLabelBrancheEdit += this.tempRejet.coude_45+' coude(s) 45';
                  }
                }
              }
            break;
          }
          console.log(toShowLabelEdit);
        /*  var labelCoude90Edit = this.mainLayer.find('#branche_tag_'+this.selectedBranche+'_'+this.selectedLayer);
          labelCoude90Edit[0].text(textLabelBrancheEdit);
          if(!toShowLabelEdit){
            labelCoude90Edit[0].hide();
          }else{
            labelCoude90Edit[0].show();
          }*/
          console.log(this.tempCuisine);
          this.closeMenuEditBranche();
        }else{

          if(type==90){
            this.defaultCoude90++;
          }else{
            this.defaultCoude45++;
          }
          //add point
          let pts = this.newBranche.points();
          pts.push(this.tmpPos.x, this.tmpPos.y);
          this.newBranche.points(pts);
          var pt = new window.Konva.Circle({
            x: this.tmpPos.x,
            y: this.tmpPos.y,
            radius: 6,
            id:'pt_'+this.visualBranche+'_'+this.ptBranche,
            fill: 'red',
            stroke: 'white',
            strokeWidth: 2,
            listening: true,
            draggable:true
          });

          this.ptBranche++;
          var selfAddPoint = this;
          pt.on('dragmove',function(e){
            var id = e.target.id().split("_");
            var mousePos = selfAddPoint.mainStage.getPointerPosition();
            var id_branche = '#branche_'+id[1]+'_'+selfAddPoint.selectedLayer;
            var branche = selfAddPoint.mainLayer.find(id_branche);
            var points = branche[0].points();
            var x_index = id[2]*2;
            var y_index = (id[2]*2)+1;
            points[x_index] = mousePos.x;
            points[y_index] = mousePos.y;
            branche[0].points(points);
            var tmpLength = selfAddPoint.computedLength(id[1]);
            branche[0].setAttr('length',tmpLength);
            switch(branche[0].getAttr('typeBranche')){
              case 'cuisine':
                selfAddPoint.tempCuisine[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'sdb':
                selfAddPoint.tempSdb[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'sdb_wc':
                selfAddPoint.tempSdb_wc[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'wc':
                selfAddPoint.tempWC[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'eau':
                selfAddPoint.tempEau[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
            }
            selfAddPoint.mainLayer.batchDraw();
          });
          this.mainLayer.add(pt);


          console.log(this.tempCuisine);
          this.closeMenuCreateBranche(true);
        }

      },
      computedLength(id_branche){
        var computedLength=0;
        var tmpBranche = this.mainLayer.find("#branche_"+id_branche+'_'+this.selectedLayer);
        var pts = tmpBranche[0].points();
        var nbPoints = pts.length/2;
        for(var i = 0 ; i < nbPoints-1; i++){
          var x_ind_1 = (i*2);
          var y_ind_1 = (i*2)+1;
          var x_ind_2 = ((i+1)*2);
          var y_ind_2 = ((i+1)*2)+1;

          computedLength += Math.sqrt(Math.pow((pts[x_ind_2]-pts[x_ind_1]),2)+Math.pow((pts[y_ind_2]-pts[y_ind_1]),2));
        }
        computedLength = ((((computedLength*this.scale[this.selectedLayer].cm*100))/this.scale[this.selectedLayer].px)/100);
        if(tmpBranche[0].getAttr('throughColumn')==true){

          computedLength += 2.7;
          for(var j = 0 ; j < this.planChantierSrc.length; j++){
            if(j!=this.selectedLayer){
              var tmpLength = 0;
              var tmpLayer = this.mainStage.find("#layer_"+j);
              var tmpBrancheCol = tmpLayer[0].find("#branche_"+id_branche+'_'+j);
              var ptsCol = tmpBrancheCol[0].points();
              var nbPointsCol = ptsCol.length/2;
              for(var iCol = 0 ; iCol < nbPointsCol-1; iCol++){
                var x_ind_1_col = (iCol*2);
                var y_ind_1_col = (iCol*2)+1;
                var x_ind_2_col = ((iCol+1)*2);
                var y_ind_2_col = ((iCol+1)*2)+1;

                tmpLength += Math.sqrt(Math.pow((ptsCol[x_ind_2_col]-ptsCol[x_ind_1_col]),2)+Math.pow((ptsCol[y_ind_2_col]-ptsCol[y_ind_1_col]),2));
              }
              computedLength += ((((tmpLength*this.scale[j].cm*100))/this.scale[j].px)/100);
            }
          }
          if(computedLength<1){
            computedLength=1;
          }
        }
        return computedLength.toFixed(1);
      },
      defaultCoude(type){
        console.log(type);
        if(!this.pluggit){
          this.defaultCoude90++;
        }
      },
      createBranche(type){
        /*var tmpColor = {
          cuisine:'purple',
          sdb:'yellow',
          sdb_wc:'teal',
          wc:'green',
          eau:'blue',
          colonne: 'silver',
          rejet: 'black'
        };*/

        let pts = this.newBranche.points();
        pts.push(this.tmpPos.x, this.tmpPos.y);
        this.newBranche.points(pts);
        console.log(pts);
        var pt;
        if(type=="rejet"){
          this.placedRejet = true;
          console.log(this.placedRejet);
          /*var computedWidth = (20*this.scale[this.selectedLayer].px)/(this.scale[this.selectedLayer].cm*100);
          var computedHeight = (20*this.scale[this.selectedLayer].px)/(this.scale[this.selectedLayer].cm*100);*/
          var computedRejetWidth = (80*this.scale[this.selectedLayer].px)/(this.scale[this.selectedLayer].cm*100);
          var computedRejetHeight = (81*this.scale[this.selectedLayer].px)/(this.scale[this.selectedLayer].cm*100);
          var computedRejetX = this.tmpPos.x - (computedRejetWidth/2);
          var computedRejetY = this.tmpPos.y - (computedRejetHeight/2);
          this.mainRejet = new window.Konva.Image({
            x: computedRejetX,
            y: computedRejetY,
            width: computedRejetWidth,
            height: computedRejetHeight,
            /*fill: 'black',
            stroke: 'black',
            strokeWidth: 6,*/
            image: this.imageRejet,
            draggable:true,
            listening:true,
            id: 'pt_'+this.visualBranche+'_'+this.ptBranche,
            rejet: true
          });
          var selfRejet = this;
          this.mainRejet.on('dblclick dbltap',function(e){
            console.log("double click rejet");
            if(selfRejet.customRejet){
              console.log(e);
              var menuNodeScale = document.getElementById('menuRejet');
              menuNodeScale.style.display = 'flex';
            }
          });
          this.mainRejet.setAttr('source',this.imageRejet.src);
          pt = this.mainRejet;

          var id_branche = '#branche_'+this.visualBranche+'_'+this.selectedLayer;
          var branche = this.mainLayer.find(id_branche);
          branche[0].stroke("#da8c0c");
          branche[0].strokeWidth(12);
          branche[0].dashEnabled(false);
          if(branche[0].getAttr('throughColumn')==true){
            for(var cT=0; cT < this.planChantierSrc.length; cT++){
              if(cT!=this.selectedLayer){
                var tmpLayerCT = this.mainStage.find("#layer_"+cT);
                var tmpBrancheCT = tmpLayerCT[0].find("#branche_"+this.visualBranche+"_"+cT);
                tmpBrancheCT[0].strokeWidth(12);
                tmpBrancheCT[0].stroke("#da8c0c");
                tmpBrancheCT[0].dashEnabled(false);
              }
            }
          }
        }else{
            if(type=="colonne"){
              pt = new window.Konva.Shape({
                x: this.tmpPos.x,
                y: this.tmpPos.y,
                radius: 2,
                id:'pt_'+this.visualBranche+'_'+this.ptBranche,
                //fill: 'white',
                stroke: 'black',
                strokeWidth: 1,
                listening: true,
                draggable:true,
                colonne:(type=='colonne'),
                sceneFunc: function (context, shape) {
                  context.beginPath();
                  // don't need to set position of rect, Konva will handle it
                  //context.rect(0, 0, shape.getAttr('width'), shape.getAttr('height'));
                  context.arc(0,0,shape.getAttr('radius')+4,0,2*Math.PI);
                  context.closePath();
                  context.fillStrokeShape(shape);
                  context.beginPath();
                  context.arc(0,0,shape.getAttr('radius'),0,2*Math.PI);
                  context.fill();
                  context.closePath();
                  // (!) Konva specific method, it is very important
                  // it will apply are required styles
                  context.fillStrokeShape(shape);
                }
              });
            }else{
              var computedBoucheWidth = (60*this.scale[this.selectedLayer].px)/(this.scale[this.selectedLayer].cm*100);
              var computedBoucheHeight = (61*this.scale[this.selectedLayer].px)/(this.scale[this.selectedLayer].cm*100);
              var computedBoucheX = this.tmpPos.x - (computedBoucheWidth/2);
              var computedBoucheY = this.tmpPos.y - (computedBoucheHeight/2);
              pt = new window.Konva.Image({
                x: computedBoucheX,
                y: computedBoucheY,
                id:'pt_'+this.visualBranche+'_'+this.ptBranche,
                width:computedBoucheWidth,
                height:computedBoucheHeight,
                listening: true,
                draggable:true,
                colonne:(type=='colonne'),
                image: this.imageBouche,
                bouche:true
              });
              console.log('pt_'+this.visualBranche+'_'+this.ptBranche);
              pt.setAttr('source',this.imageBouche.src);
            }

            if(type=="cuisine"){
              this.placedCuisine = true;
              var id_brancheCuisine = '#branche_'+this.visualBranche+'_'+this.selectedLayer;
              var brancheCuisine = this.mainLayer.find(id_brancheCuisine);
              brancheCuisine[0].strokeWidth(12);
              if(brancheCuisine[0].getAttr('throughColumn')==true){
                for(var cR=0; cR < this.planChantierSrc.length; cR++){
                  if(c!=this.selectedLayer){
                    var tmpLayerCR = this.mainStage.find("#layer_"+cR);
                    var tmpBrancheCR = tmpLayerCR[0].find("#branche_"+this.visualBranche+"_"+cR);
                    tmpBrancheCR[0].strokeWidth(12);
                  }
                }
              }
            }
        }

          var selfCreateBranche = this;
          pt.on('dragmove',function(e){
            var id = e.target.id().split("_");
            console.log(id);
            var isColonne = e.target.getAttr('colonne');
            var mousePos = selfCreateBranche.mainStage.getPointerPosition();
            var id_branche = '#branche_'+id[1]+'_'+selfCreateBranche.selectedLayer;
            var branche = selfCreateBranche.mainLayer.find(id_branche);
            var points = branche[0].points();
            console.log(points);
            var x_index = id[2]*2;
            var y_index = (id[2]*2)+1;
            points[x_index] = mousePos.x;
            points[y_index] = mousePos.y;
            branche[0].points(points);
            if(isColonne){
              //update colonne point
              var id_anchorC = "#anchor_"+selfCreateBranche.selectedLayer;
              var tmpAnchorC = selfCreateBranche.mainLayer.find(id_anchorC);
              var posAnchor = tmpAnchorC[0].absolutePosition();
              var moveColumn = {
                x: (posAnchor.x - mousePos.x),
                y: (posAnchor.y - mousePos.y)
              };
              for(var tmpLayerID = 0; tmpLayerID < selfCreateBranche.planChantierSrc.length ; tmpLayerID++){
                if(tmpLayerID!=selfCreateBranche.selectedLayer){
                  var tmpLayer = selfCreateBranche.mainStage.find("#layer_"+tmpLayerID);
                  var Tid_anchor = "#anchor_"+tmpLayerID;
                  var TtmpAnchor = tmpLayer[0].find(Tid_anchor);
                  var TposAnchor = TtmpAnchor[0].absolutePosition();


                  //SCALE INTO CONSIDERATION COLSCALE
                  var ThigherScaleCurrent = false;
                  if((selfCreateBranche.scale[selfCreateBranche.selectedLayer].cm/selfCreateBranche.scale[selfCreateBranche.selectedLayer].px) > (selfCreateBranche.scale[tmpLayerID].cm/selfCreateBranche.scale[tmpLayerID].px)){
                    ThigherScaleCurrent = true;
                  }

                  var TratioScale;
                  var TscaleMoveColumn;
                  if(ThigherScaleCurrent){
                    TratioScale = (selfCreateBranche.scale[selfCreateBranche.selectedLayer].cm/selfCreateBranche.scale[selfCreateBranche.selectedLayer].px)/(selfCreateBranche.scale[tmpLayerID].cm/selfCreateBranche.scale[tmpLayerID].px);
                    TscaleMoveColumn = {
                      x: moveColumn.x * TratioScale,
                      y: moveColumn.y * TratioScale
                    }
                  }else{
                    TratioScale = (selfCreateBranche.scale[tmpLayerID].cm/selfCreateBranche.scale[tmpLayerID].px)/(selfCreateBranche.scale[selfCreateBranche.selectedLayer].cm/selfCreateBranche.scale[selfCreateBranche.selectedLayer].px);
                    TscaleMoveColumn = {
                      x: moveColumn.x / TratioScale,
                      y: moveColumn.y / TratioScale
                    }
                  }

                  var circles = tmpLayer[0].getChildren(function(node){
                     return ((node.getClassName() === 'Shape') && (node.getAttr('colonne')==true));
                  });
                  for(var k = 0 ; k < circles.length; k++){
                    var tmpIDCol = circles[k].id().split("_");
                    if(tmpIDCol[1]==id[1]){
                      var newPos = {
                        x: (TposAnchor.x-TscaleMoveColumn.x),
                        y: (TposAnchor.y-TscaleMoveColumn.y),
                      };
                      circles[k].position(newPos);

                      var tmpBrancheID = "#branche_"+id[1]+"_"+tmpLayerID;
                      var tmpBrancheC = tmpLayer[0].find(tmpBrancheID);
                      var tmpBranchePoints = tmpBrancheC[0].points();
                      var x_index_tmpBranche = tmpIDCol[2]*2;
                      var y_index_tmpBranche = (tmpIDCol[2]*2)+1;
                      tmpBranchePoints[x_index_tmpBranche] = newPos.x;
                      tmpBranchePoints[y_index_tmpBranche] = newPos.y;
                      tmpBrancheC[0].points(tmpBranchePoints);
                    }
                  }
                }
              }
            }

            var tmpLength = selfCreateBranche.computedLength(id[1]);
            branche[0].setAttr('length',tmpLength);
            switch(branche[0].getAttr('typeBranche')){
              case 'cuisine':
                selfCreateBranche.tempCuisine[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'sdb':
                selfCreateBranche.tempSdb[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'sdb_wc':
                selfCreateBranche.tempSdb_wc[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'wc':
                selfCreateBranche.tempWC[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'eau':
                selfCreateBranche.tempEau[branche[0].getAttr('idBranche')].longueur = tmpLength;
              break;
              case 'rejet':
                //if(selfCreateBranche.tempRejet.longueur==1.5){tmpLength=1.5;}
                selfCreateBranche.tempRejet.longueur = tmpLength;
              break;
            }
            selfCreateBranche.mainLayer.batchDraw();
          });
          this.mainLayer.add(pt);

        if(type=="colonne"){
          this.ptBranche = 0;
          var ptsBranche2 = this.newBranche.points();
          ptsBranche2 = ptsBranche2.slice(0, ptsBranche2.length - 2);
          this.newBranche.points(ptsBranche2);
          this.newBranche.setAttr('throughColumn',true);
          if(this.planChantierSrc.length==2){
            //prendre en compte les échelles pour la traversée
            var id_anchorC = "#anchor_"+this.selectedLayer;
            var tmpAnchorC = this.mainLayer.find(id_anchorC);
            var posAnchor = tmpAnchorC[0].absolutePosition();
            console.log(this.tmpPos);
            console.log(posAnchor);
            var moveColumn = {
              x: (posAnchor.x - this.tmpPos.x),
              y: (posAnchor.y - this.tmpPos.y)
            };
            console.log(moveColumn);
            for(var tmpLayerID = 0; tmpLayerID < this.planChantierSrc.length ; tmpLayerID++){
              if(tmpLayerID!=this.selectedLayer){
                console.log("ColumnScale");
                var tmpLayer = this.mainStage.find("#layer_"+tmpLayerID);
                var Tid_anchor = "#anchor_"+tmpLayerID;
                var TtmpAnchor = tmpLayer[0].find(Tid_anchor);
                var TposAnchor = TtmpAnchor[0].absolutePosition();
                console.log(TtmpAnchor[0]);
                console.log(TposAnchor);

                //SCALE INTO CONSIDERATION COLSCALE
                var higherScaleCurrent = false;
                if((this.scale[this.selectedLayer].cm/this.scale[this.selectedLayer].px) > (this.scale[tmpLayerID].cm/this.scale[tmpLayerID].px)){
                  higherScaleCurrent = true;
                }
                console.log(higherScaleCurrent);
                var ratioScale;
                var scaleMoveColumn;
                if(higherScaleCurrent){
                  ratioScale = (this.scale[this.selectedLayer].cm/this.scale[this.selectedLayer].px)/(this.scale[tmpLayerID].cm/this.scale[tmpLayerID].px);
                  scaleMoveColumn = {
                    x: moveColumn.x * ratioScale,
                    y: moveColumn.y * ratioScale
                  }
                }else{
                  ratioScale = (this.scale[tmpLayerID].cm/this.scale[tmpLayerID].px)/(this.scale[this.selectedLayer].cm/this.scale[this.selectedLayer].px);
                  scaleMoveColumn = {
                    x: moveColumn.x / ratioScale,
                    y: moveColumn.y / ratioScale
                  }
                }

                console.log(ratioScale);
                console.log(moveColumn);
                console.log(scaleMoveColumn);

                var ptColumn = new window.Konva.Shape({
                  x: (TposAnchor.x-scaleMoveColumn.x),
                  y: (TposAnchor.y-scaleMoveColumn.y),
                  radius: 2,
                  id:'pt_'+this.visualBranche+'_'+this.ptBranche,
                  //fill: 'white',
                  stroke: 'black',
                  strokeWidth: 1,
                  listening: true,
                  draggable:true,
                  colonne:(type=='colonne'),
                  sceneFunc: function (context, shape) {
                    context.beginPath();
                    // don't need to set position of rect, Konva will handle it
                    //context.rect(0, 0, shape.getAttr('width'), shape.getAttr('height'));
                    context.arc(0,0,shape.getAttr('radius')+4,0,2*Math.PI);
                    context.closePath();
                    context.fillStrokeShape(shape);
                    context.beginPath();
                    context.arc(0,0,shape.getAttr('radius'),0,2*Math.PI);
                    context.fill();
                    context.closePath();
                    // (!) Konva specific method, it is very important
                    // it will apply are required styles
                    context.fillStrokeShape(shape);
                  }
                });

                var selfColumn = this;
                ptColumn.on('dragmove',function(e){
                  var id = e.target.id().split("_");
                  var isColonne = e.target.getAttr('colonne');
                  var mousePos = selfColumn.mainStage.getPointerPosition();
                  var id_branche = '#branche_'+id[1]+'_'+selfColumn.selectedLayer;
                  var branche = selfColumn.mainLayer.find(id_branche);
                  var points = branche[0].points();
                  var x_index = id[2]*2;
                  var y_index = (id[2]*2)+1;
                  points[x_index] = mousePos.x;
                  points[y_index] = mousePos.y;
                  branche[0].points(points);
                  if(isColonne){
                    //update colonne point
                    var id_anchorC = "#anchor_"+selfColumn.selectedLayer;
                    var tmpAnchorC = selfColumn.mainLayer.find(id_anchorC);
                    var posAnchor = tmpAnchorC[0].absolutePosition();
                    var moveColumn = {
                      x: (posAnchor.x - mousePos.x),
                      y: (posAnchor.y - mousePos.y)
                    };
                    for(var tmpLayerID = 0; tmpLayerID < selfColumn.planChantierSrc.length ; tmpLayerID++){
                      if(tmpLayerID!=selfColumn.selectedLayer){
                        var tmpLayer = selfColumn.mainStage.find("#layer_"+tmpLayerID);
                        var Tid_anchor = "#anchor_"+tmpLayerID;
                        var TtmpAnchor = tmpLayer[0].find(Tid_anchor);
                        var TposAnchor = TtmpAnchor[0].absolutePosition();

                        //SCALE INTO CONSIDERATION COLSCALE
                        var ThigherScaleCurrent = false;
                        if((selfColumn.scale[selfColumn.selectedLayer].cm/selfColumn.scale[selfColumn.selectedLayer].px) > (selfColumn.scale[tmpLayerID].cm/selfColumn.scale[tmpLayerID].px)){
                          ThigherScaleCurrent = true;
                        }

                        var TratioScale;
                        var TscaleMoveColumn;
                        if(ThigherScaleCurrent){
                          TratioScale = (selfColumn.scale[selfColumn.selectedLayer].cm/selfColumn.scale[selfColumn.selectedLayer].px)/(selfColumn.scale[tmpLayerID].cm/selfColumn.scale[tmpLayerID].px);
                          TscaleMoveColumn = {
                            x: moveColumn.x * TratioScale,
                            y: moveColumn.y * TratioScale
                          }
                        }else{
                          TratioScale = (selfColumn.scale[tmpLayerID].cm/selfColumn.scale[tmpLayerID].px)/(selfColumn.scale[selfColumn.selectedLayer].cm/selfColumn.scale[selfColumn.selectedLayer].px);
                          TscaleMoveColumn = {
                            x: moveColumn.x / TratioScale,
                            y: moveColumn.y / TratioScale
                          }
                        }
                        var circles = tmpLayer[0].getChildren(function(node){
                           return ((node.getClassName() === 'Shape') && (node.getAttr('colonne')==true));
                        });
                        for(var k = 0 ; k < circles.length; k++){
                          var tmpIDCol = circles[k].id().split("_");
                          if(tmpIDCol[1]==id[1]){
                            var newPos = {
                              x: (TposAnchor.x-TscaleMoveColumn.x),
                              y: (TposAnchor.y-TscaleMoveColumn.y),
                            };
                            circles[k].position(newPos);

                            var tmpBrancheID = "#branche_"+id[1]+"_"+tmpLayerID;
                            var tmpBrancheC = tmpLayer[0].find(tmpBrancheID);
                            var tmpBranchePoints = tmpBrancheC[0].points();
                            var x_index_tmpBranche = tmpIDCol[2]*2;
                            var y_index_tmpBranche = (tmpIDCol[2]*2)+1;
                            tmpBranchePoints[x_index_tmpBranche] = newPos.x;
                            tmpBranchePoints[y_index_tmpBranche] = newPos.y;
                            tmpBrancheC[0].points(tmpBranchePoints);
                          }
                        }
                      }
                    }
                  }

                  var tmpLength = selfColumn.computedLength(id[1]);
                  branche[0].setAttr('length',tmpLength);
                  switch(branche[0].getAttr('typeBranche')){
                    case 'cuisine':
                      selfColumn.tempCuisine[branche[0].getAttr('idBranche')].longueur = tmpLength;
                    break;
                    case 'sdb':
                      selfColumn.tempSdb[branche[0].getAttr('idBranche')].longueur = tmpLength;
                    break;
                    case 'sdb_wc':
                      selfColumn.tempSdb_wc[branche[0].getAttr('idBranche')].longueur = tmpLength;
                    break;
                    case 'wc':
                      selfColumn.tempWC[branche[0].getAttr('idBranche')].longueur = tmpLength;
                    break;
                    case 'eau':
                      selfColumn.tempEau[branche[0].getAttr('idBranche')].longueur = tmpLength;
                    break;
                    case 'rejet':
                      /*if(selfColumn.tempRejet.longueur==1.5){
                        tmpLength = 1.5;
                      }*/
                      selfColumn.tempRejet.longueur=tmpLength;
                    break;
                  }
                  selfColumn.mainLayer.batchDraw();
                });

                tmpLayer[0].add(ptColumn);
              }
            }



            var tmpLayerIdT = '#layer_'+this.selectedLayer;
            var tmpLayerT = this.mainStage.find(tmpLayerIdT);
            tmpLayerT[0].hide();
            if(this.selectedLayer==1){
              this.selectedLayer--;
            }else{
              this.selectedLayer++;
            }
            tmpLayerIdT = '#layer_'+this.selectedLayer;
            tmpLayerT = this.mainStage.find(tmpLayerIdT);
            tmpLayerT[0].show();
            this.mainLayer = tmpLayerT[0];

            //create branche on selected Layer
            //get column on selected layer and use his pos
            this.newBranche = new window.Konva.Line({
              id:'branche_'+this.visualBranche+'_'+this.selectedLayer,
              stroke: 'red',
              strokeWidth: 8,
              dash: [33, 10],
              // remove line from hit graph, so we can check intersections
              listening: true,
              points: [(TposAnchor.x-scaleMoveColumn.x), (TposAnchor.y-scaleMoveColumn.y), (TposAnchor.x-scaleMoveColumn.x), (TposAnchor.y-scaleMoveColumn.y)],
              tension:0.2,
              draggable:false,
              throughColumn: true
            });

            this.mainLayer.add(this.newBranche);
            this.newBranche.moveDown();
            this.ptBranche++;
            this.closeMenuCreateBranche(true);
          }
        }else{
          var ptsBranche = this.newBranche.points();
          ptsBranche = ptsBranche.slice(0, ptsBranche.length - 2);

          this.newBranche.points(ptsBranche);
          var tmpLength = this.computedLength(this.visualBranche);
          var tmpCoude90 = this.defaultCoude90;
          if(this.newBranche.getAttr('throughColumn')==true){
            tmpCoude90+=1;
            //ajoute coude90 si colonne bouche emplacement différent

            //rejet façade calculer longueur
            //rejet toiture distance forcément = 1.50 pas calculer longueur
          }

          //add Tag Branche x coude 90 | x coude 45
          /*var labelCoude90 = new window.Konva.Text({
            text: this.defaultCoude90 +' coude(s) 90 | '+this.defaultCoude45+' coude(s) 45',
            x: ptsBranche[0]+((ptsBranche[2]-ptsBranche[0])/2),
            y: ptsBranche[1]+((ptsBranche[3]-ptsBranche[1])/2),
            offsetY: 25,
            offsetX: 20,
            fontSize: 20,
            id: 'branche_tag_'+this.visualBranche+'_'+this.selectedLayer,
            listening: false,
            fill:'red'
          });

          var m = (ptsBranche[3]-ptsBranche[1])/(ptsBranche[2]-ptsBranche[0]);
          var theta = Math.atan(m);
          var angle = (theta*180) / Math.PI;
          labelCoude90.rotation(angle);
          var toShowLabel = false;
          var textLabelBranche = "";
          if(this.defaultCoude90>0){
            toShowLabel = true;
            textLabelBranche = this.defaultCoude90 +' coude(s) 90';
          }
          if(this.defaultCoude45>0){
            toShowLabel = true;
            if(textLabelBranche!=""){
              textLabelBranche += ' | ';
            }
            textLabelBranche += this.defaultCoude45+' coude(s) 45';
          }

          labelCoude90.text(textLabelBranche);
          if(!toShowLabel){
            labelCoude90.hide();
          }
          this.mainLayer.add(labelCoude90);*/

          this.newBranche.setAttr('length',tmpLength);
          this.newBranche.setAttr('typeBranche',type);
          switch(type){
            case 'cuisine':
              this.newBranche.setAttr('idBranche',0);
              this.tempCuisine[0].longueur = tmpLength;
              this.tempCuisine[0].coude_90 = tmpCoude90;
              this.tempCuisine[0].coude_45 = this.defaultCoude45;
              console.log("DEFAULT GAINE CUISINE");
              console.log(this.defaultGaine);
              //this.tempCuisine[0].gaine = this.defaultGaine;
              //this.tempCuisine[0].gaine.diametre= 125;
              //diametre = 125;
            break;
            case 'sdb':
              this.tempSdb.push({longueur:tmpLength,coude_90:tmpCoude90,coude_45:this.defaultCoude45,gaine:this.defaultGaine,diametre:this.defaultDiametre});
              this.newBranche.setAttr('idBranche',this.tempSdb.length-1);
            break;
            case 'sdb_wc':
              this.tempSdb_wc.push({longueur:tmpLength,coude_90:tmpCoude90,coude_45:this.defaultCoude45,gaine:this.defaultGaine,diametre:this.defaultDiametre});
              this.newBranche.setAttr('idBranche',this.tempSdb_wc.length-1);
            break;
            case 'wc':
              this.tempWC.push({longueur:tmpLength,coude_90:tmpCoude90,coude_45:this.defaultCoude45,gaine:this.defaultGaine,diametre:this.defaultDiametre});
              this.newBranche.setAttr('idBranche',this.tempWC.length-1);
            break;
            case 'eau':
              this.tempEau.push({longueur:tmpLength,coude_90:tmpCoude90,coude_45:this.defaultCoude45,gaine:this.defaultGaine,diametre:this.defaultDiametre});
              this.newBranche.setAttr('idBranche',this.tempEau.length-1);
            break;
            case 'rejet':
              /*if(this.tempRejet.longueur==1.5){
                tmpLength = 1.5;
              }*/
              this.tempRejet.longueur = tmpLength;
              this.tempRejet.coude_90 = tmpCoude90;
              this.tempRejet.coude_45 = this.defaultCoude45;
              //160
              this.newBranche.setAttr('idBranche',-1);
            break;
          }

          if(this.newBranche.getAttr('throughColumn')==true){
            for(var c=0; c < this.planChantierSrc.length; c++){
              if(c!=this.selectedLayer){
                var tmpLayerC = this.mainStage.find("#layer_"+c);
                var tmpBrancheC = tmpLayerC[0].find("#branche_"+this.visualBranche+"_"+c);
                tmpBrancheC[0].setAttr('typeBranche',type);
                switch(type){
                  case 'cuisine':
                    tmpBrancheC[0].setAttr('idBranche',0);
                  break;
                  case 'sdb':
                    tmpBrancheC[0].setAttr('idBranche',this.tempSdb.length-1);
                  break;
                  case 'sdb_wc':
                    tmpBrancheC[0].setAttr('idBranche',this.tempSdb_wc.length-1);
                  break;
                  case 'wc':
                    tmpBrancheC[0].setAttr('idBranche',this.tempWC.length-1);
                  break;
                  case 'eau':
                    tmpBrancheC[0].setAttr('idBranche',this.tempEau.length-1);
                  break;
                  case 'rejet':
                    tmpBrancheC[0].setAttr('idBranche',-1);
                  break;
                }
              }
            }
          }

          /*console.log(this.placedRejet);
          console.log(this.tempCuisine[0]);
          console.log(this.tempCuisine[0].longueur);
          console.log(this.tempCuisine[0].longueur>0);*/
          if(this.placedRejet){
            this.disableValidateTrace = false;
          }

          this.computeDebit();
          this.ptBranche = 0;
          this.visualBranche++;
          this.disableRejet = false;
          this.defaultCoude90 = 0;
          this.defaultCoude45 = 0;
          this.closeMenuCreateBranche(false);
          if(this.customRejet && type=="rejet"){
            var menuNode = document.getElementById('menuRejet');
            menuNode.style.display = 'flex';
          }
          /*if(this.newBranche.getAttr('throughColumn')==true){
            //switch back to vmc
            var tmpLayerColID = '#layer_'+this.selectedLayer;
            var tmpLayerCol = this.mainStage.find(tmpLayerColID);
            tmpLayerCol[0].hide();

            var vmcLayer = this.mainStage.find("#vmc");
            var layerVMCID = "#layer_"+vmcLayer[0].getAttr('layer_id');
            var layerVMC = this.mainStage.find(layerVMCID);

            layerVMC[0].show();
            this.mainLayer = layerVMC[0];
            this.selectedLayer = vmcLayer[0].getAttr('layer_id');
            console.log(this.selectedLayrr);
          }*/
        }
      },
      closeMenuScaleBranche(){
        if(this.scale[this.selectedLayer].cm!=0){
          console.log(this.scale);
          var menuNode = document.getElementById('menuScale');
          menuNode.style.display = 'none';
          var scaleLabelIDTmp = "#scale_tag_"+this.selectedLayer;
          var scaleLabel = this.mainLayer.find(scaleLabelIDTmp);
          if(scaleLabel.length>0){
            scaleLabel[0].text(this.scale[this.selectedLayer].cm +'m');
          }else{
            var idscaleLineTmp = '#scale_'+this.selectedLayer;
            var scaleLineTmp = this.mainLayer.find(idscaleLineTmp);
            var pointsScale = scaleLineTmp[0].points();
            console.log(pointsScale);
            var labelScale = new window.Konva.Text({
              text: this.scale[this.selectedLayer].cm +'m',
              x: pointsScale[0]+((pointsScale[2]-pointsScale[0])/2),
              y: pointsScale[1]+((pointsScale[3]-pointsScale[1])/2),
              offsetY: 25,
              offsetX: 20,
              fontSize: 20,
              id: 'scale_tag_'+this.selectedLayer,
              listening: true,
              fill:'red'
            });
            labelScale.setAttr('cm',this.scale[this.selectedLayer].cm);
            labelScale.setAttr('px',this.scale[this.selectedLayer].px);
            var m = (pointsScale[3]-pointsScale[1])/(pointsScale[2]-pointsScale[0]);
            var theta = Math.atan(m);
            var angle = (theta*180) / Math.PI;

            console.log(labelScale);
            console.log(angle);
            labelScale.rotation(angle);
            var selfLabel = this;
            labelScale.on('dblclick dbltap',function(e){
              if(!selfLabel.disableScale){
                console.log(e);
                var menuNodeScale = document.getElementById('menuScale');
                menuNodeScale.style.display = 'flex';
              }
            });
            this.mainLayer.add(labelScale);
          }
          this.firstPointDefine = false;
          if(this.scale.length>1){
            var indexScaleUndefined = this.scale.findIndex(scale => scale.cm == 0);
            if(indexScaleUndefined>=0){
              var tmpLayerScaleId = '#layer_'+this.selectedLayer;
              var tmpScaleLayer = this.mainStage.find(tmpLayerScaleId);
              tmpScaleLayer[0].hide();

              tmpLayerScaleId = '#layer_'+indexScaleUndefined;
              tmpScaleLayer = this.mainStage.find(tmpLayerScaleId);
              tmpScaleLayer[0].show();
              this.selectedLayer = indexScaleUndefined;
              this.mainLayer = tmpScaleLayer[0];
              this.defineScale[this.selectedLayer] = true;
            }
          }
        }
      },
      closeMenuCreateBranche(definedBranche){
        var menuNode = document.getElementById('menuCreateBranche');
        menuNode.style.display = 'none';
        this.defineBranche = definedBranche;
      },
      closeMenuCreateRejet(){
        var menuNode = document.getElementById('menuRejet');
        menuNode.style.display = 'none';
      },
      closeMenuEditBranche(){
        var menuNode = document.getElementById('menuEditBranche');
        menuNode.style.display = 'none';
      },
      deleteBranche(){
        //need to update id branche of branche after deletion
        console.log("deleteBranche");
        console.log(this.selectedBranche);
        var id_branche = "#branche_"+this.selectedBranche+'_'+this.selectedLayer;
        var branche = this.mainLayer.find(id_branche)[0];
        console.log(branche);
        var id_branche_compute = branche.getAttr('idBranche');
        var type_branche = branche.getAttr('typeBranche');
        var throughColumn = branche.getAttr('throughColumn');
        switch(type_branche){
          case 'cuisine':
            this.placedCuisine = false;
            this.tempCuisine[id_branche_compute].longueur=0;
            this.tempCuisine[id_branche_compute].coude_90=0;
            this.tempCuisine[id_branche_compute].coude_45=0;
          break;
          case 'sdb':
            this.tempSdb.splice(id_branche_compute,1);
          break;
          case 'sdb_wc':
            this.tempSdb_wc.splice(id_branche_compute,1);
          break;
          case 'wc':
            this.tempWC.splice(id_branche_compute,1);
          break;
          case 'eau':
            this.tempEau.splice(id_branche_compute,1);
          break;
          case 'rejet':
           this.tempRejet.longueur=0;
           this.tempRejet.coude_90=0;
           this.tempRejet.coude_45=0;
           this.placedRejet = false;
          break;
        }
        if(!this.placedRejet){
          this.disableValidateTrace = true;
        }
        branche.destroy();
        if(throughColumn==true){
          console.log("delete column branche");
          this.mainStage.find('Line').forEach((brancheMainStage) =>{
            if(brancheMainStage.getAttr('idBranche')==id_branche_compute && brancheMainStage.getAttr('typeBranche')==type_branche){
              console.log(brancheMainStage);
              var selectedBrancheTmp = brancheMainStage.getAttr('id').split("_")[1];
              console.log(selectedBrancheTmp);
              brancheMainStage.destroy();
              var foundTmp=true;
              var iTmp=0;
              var pt_tmp;
              var id_pt_tmp;
              var toDeletePt = [];
              while(foundTmp){
                id_pt_tmp = "#pt_"+selectedBrancheTmp+"_"+iTmp;
                console.log(id_pt_tmp);
                console.log(this.mainStage.find(id_pt_tmp));
                var pt_length = this.mainStage.find(id_pt_tmp).length;
                if(pt_length>0){
                  for(var j = 0 ; j < pt_length; j++){
                    console.log(pt_length);
                    pt_tmp = this.mainStage.find(id_pt_tmp)[j];
                    console.log(pt_tmp);
                    var tmpPtLayer = pt_tmp.getLayer().getAttr('id').split("_")[1];

                    console.log(tmpPtLayer);
                    console.log(this.selectedLayer);
                    if(this.selectedLayer!=tmpPtLayer){
                      toDeletePt.push(pt_tmp);
                    }
                  }
                  iTmp++;
                }else{
                  foundTmp=false;
                }
              }

              console.log(toDeletePt);
              while(toDeletePt.length>0){
                toDeletePt[0].destroy();
                toDeletePt.splice(0,1);
              }
            }
          });
        }


        this.mainStage.find('Line').forEach((branchesLine) => {
          console.log(branchesLine);
          if(branchesLine.getAttr('typeBranche')==type_branche){
            if(id_branche_compute<branchesLine.getAttr('idBranche')){
              branchesLine.setAttr('idBranche',(branchesLine.getAttr('idBranche')-1));
            }
          }
        });
        var found=true;
        var i=0;
        while(found){
          var id_pt = "#pt_"+this.selectedBranche+"_"+i;
          console.log(id_pt);
          var pt = this.mainLayer.find(id_pt)[0];
          console.log(pt);
          console.log(i);
          if(pt!=undefined){
            //delete image not working;
            pt.destroy();
          }else if(i>0){
            console.log(i);
            found=false;
          }
          i++;
        }
        //this.visualBranche--;
        this.computeDebit();
        var menuNode = document.getElementById('menuEditBranche');
        menuNode.style.display = 'none';
      },
      hasPlan() {
        return !!this.planChantier || this.planChantierSrc.length==0;
      },
      setTraceByInput(byInput){
        this.actionchoosed = true;
        this.traceByInput = byInput;
        if(byInput){
          this.placedCuisine = true;
        }
        this.traceByDraw = !byInput;

      },
      defineScaleBtn(evt){
        evt.preventDefault();
        this.defineScale[this.selectedLayer] = true;
        //stop listening to drag move plan
      },
      placeEA(evt){
        evt.preventDefault();
        this.defineEA = true;
        this.defineBalyage = false;
      },
      placeBalyage(evt){
        evt.preventDefault();
        this.defineBalyage = true;
        this.defineEA = false;
      },
      placeVMC(evt){
        evt.preventDefault();
        this.placedVMC = true;
        this.defineEA = false;
        this.defineBalyage = false;
        // proportionnelle echelle
        // place au centre du plan
        // 591 * 473
        var computedWidth = (90*this.scale[this.selectedLayer].px)/(this.scale[this.selectedLayer].cm*100);
        var computedHeight = (70*this.scale[this.selectedLayer].px)/(this.scale[this.selectedLayer].cm*100);
        var computedX = (this.maxWidth-computedWidth)/2;
        var computedY = (this.maxHeight-computedHeight)/2;
        /*console.log(computedWidth);
        console.log(computedHeight);
        console.log(computedX);
        console.log(computedY);*/
        var imageObj = new Image();
        var self3 = this;
        imageObj.onload = function () {
          self3.mainVMC = new window.Konva.Image({
            x: computedX,
            y: computedY,
            image: imageObj,
            width: computedWidth,
            height: computedHeight,
            id: 'vmc',
            draggable:true,
            layer_id: self3.selectedLayer
          });
          self3.mainVMC.setAttr('source',imageObj.src);

          //console.log(self3.mainVMC);

          self3.mainLayer.add(self3.mainVMC);
          self3.mainVMC.on('dragstart', (e) => {
             self3.oldPosX = e.target.x();
             self3.oldPosY = e.target.y();
          });
          self3.mainVMC.on('dragmove', function (e) {
            //var tmpPos = self3.mainVMC.absolutePosition();
            //var mousePos = self3.mainStage.getPointerPosition();
            console.log(self3.mainStage.getChildren());
            const diffX = e.target.x() -  self3.oldPosX;
            const diffY = e.target.y() -  self3.oldPosY;
            /*console.log(tmpPos);
            console.log(e.target.x());
            console.log(e.target.y());
            console.log(self3.visualBranche);*/
            for(var i= 0; i<self3.visualBranche; i++){
              //var offset = {x: tmpPos.x-mousePos.x,y:tmpPos.y-mousePos.y};
              var tmpBranche = self3.mainLayer.find('#branche_'+i+"_"+self3.selectedLayer);
              if(tmpBranche.length>0){
                var tmpPointsFirst = tmpBranche[0].points();
                /*console.log(tmpPointsFirst[0]);
                console.log(tmpPointsFirst[1]);*/
                tmpPointsFirst[0] = tmpPointsFirst[0] + diffX;
                tmpPointsFirst[1] = tmpPointsFirst[1] + diffY;
                tmpBranche[0].points(tmpPointsFirst);
                var tmpLength = self3.computedLength(i);
                tmpBranche[0].setAttr('length',tmpLength);
                switch(tmpBranche[0].getAttr('typeBranche')){
                  case 'cuisine':
                    self3.tempCuisine[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'sdb':
                    console.log(self3.tempSdb);
                    console.log(tmpBranche[0].getAttr('idBranche'));
                    self3.tempSdb[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'sdb_wc':
                    self3.tempSdb_wc[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'wc':
                    self3.tempWC[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'eau':
                    self3.tempEau[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'rejet':
                    self3.tempRejet.longueur = tmpLength;
                  break;
                }
                self3.mainLayer.batchDraw();
              }
            }
            self3.oldPosX = e.target.x();
            self3.oldPosY = e.target.y();
          });
          self3.mainVMC.on('click', function () {
            //console.log("ok");
            const pos = self3.mainStage.getPointerPosition();
            self3.defineEA = false;
            self3.defineBalyage = false;
            self3.defineBranche = true;

            self3.newBranche = new window.Konva.Line({
              id:'branche_'+self3.visualBranche+'_'+self3.selectedLayer,
              stroke: 'red',
              strokeWidth: 8,
              dash: [33, 10],
              // remove line from hit graph, so we can check intersections
              listening: true,
              points: [pos.x, pos.y, pos.x, pos.y],
              tension:0.2,
              draggable:false,
              throughColumn: false
            });

            self3.mainLayer.add(self3.newBranche);
            self3.ptBranche++;
            self3.mainVMC.moveToTop();
          });
        };
        imageObj.src = '/img/base/vmc.png';
        console.log(imageObj.src);
        //this.steps++;
        /*this.mainVMC = new window.Konva.Rect({
          x: computedX,
          y: computedY,
          width: computedWidth,
          height: computedHeight,
          fill: 'red',
          stroke: 'black',
          strokeWidth: 5,
          draggable:true,
          id: 'vmc'
        });*/


      },
      back(evt) {
        evt.preventDefault();
        this.$store.dispatch('devis/setTrace',{});
        this.$router.push('chantier');
      },
      addRemoveConfig(typo,index,toAdd){
        console.log(typo);console.log(index);console.log(toAdd);
        if(toAdd){
          var gaineDefault = this.$store.state.devis.devis.chantier.gaine;
          var diametreDefault;
          var typediametreDefault;
          if(gaineDefault.lidic==813930){
            diametreDefault = 80;
            typediametreDefault = [
              {value:80,text:'80mm'},{value:150,text:'150mm'}
            ];
          }else{
            diametreDefault = 90;
            typediametreDefault = [
              {value:90,text:'90mm'},{value:180,text:'2x90mm'}
            ];
          }
          switch(typo){
            case 'sdb':
              this.tempSdb.splice((index+1), 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:diametreDefault});
              this.typeDiametreTmp['sdb'].splice((index+1), 0,typediametreDefault);
            break;
            case 'wc':
              this.tempWC.splice((index+1), 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:diametreDefault});
              this.typeDiametreTmp['wc'].splice((index+1), 0,typediametreDefault);
            break;
            case 'sdb_wc':
              this.tempSdb_wc.splice((index+1), 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:diametreDefault});
              this.typeDiametreTmp['sdbwc'].splice((index+1), 0,typediametreDefault);
            break;
            case 'eau':
              this.tempEau.splice((index+1), 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:diametreDefault});
              this.typeDiametreTmp['eau'].splice((index+1), 0,typediametreDefault);
            break;
          }
        }else{
          switch(typo){
            case 'sdb':
              this.tempSdb.splice(index, 1);
              if(this.tempSdb.length==0) { this.configTrace['sdb'] = false; this.typeDiametreTmp['sdb'] = []; }
            break;
            case 'wc':
              this.tempWC.splice(index, 1);
              if(this.tempWC.length==0) { this.configTrace['wc'] = false; this.typeDiametreTmp['wc'] = []; }
            break;
            case 'sdb_wc':
              this.tempSdb_wc.splice(index, 1);
              if(this.tempSdb_wc.length==0) { this.configTrace['sdb_wc'] = false; this.typeDiametreTmp['sdbwc'] = []; }
            break;
            case 'eau':
              this.tempEau.splice(index, 1);
              if(this.tempEau.length==0) { this.configTrace['eau'] = false; this.typeDiametreTmp['eau'] = []; }
            break;
          }
        }
        this.computeDebit();
      },
      updateConfig(typo){
        console.log(this.configTrace[typo]);
        console.log(typo);
        if(this.configTrace[typo]){
          console.log('toAdd');
          let toAdd = 0;
          var gaineDefault = this.$store.state.devis.devis.chantier.gaine;
          var diametreDefault;
          var typediametreDefault;
          if(gaineDefault.lidic==813930){
            diametreDefault = 80;
            typediametreDefault = [
              {value:80,text:'80mm'},{value:150,text:'150mm'}
            ];
          }else{
            diametreDefault = 90;
            typediametreDefault = [
              {value:90,text:'90mm'},{value:180,text:'2x90mm'}
            ];
          }
          switch(typo){
            case 'cuisine':
              this.tempCuisine.splice(toAdd, 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:125,pression:0});
            break;
            case 'sdb':
              this.tempSdb.splice(toAdd, 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:diametreDefault});
              this.typeDiametreTmp['sdb'].splice(toAdd, 0,typediametreDefault);
            break;
            case 'wc':
              this.tempWC.splice(toAdd, 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:diametreDefault});
              this.typeDiametreTmp['wc'].splice(toAdd, 0,typediametreDefault);
            break;
            case 'sdb_wc':
              this.tempSdb_wc.splice(toAdd, 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:diametreDefault});
              this.typeDiametreTmp['sdbwc'].splice(toAdd, 0,typediametreDefault);
            break;
            case 'eau':
              this.tempEau.splice(toAdd, 0, {longueur:0,coude_90:0,coude_45:0,gaine:gaineDefault,diametre:diametreDefault});
              this.typeDiametreTmp['eau'].splice(toAdd, 0,typediametreDefault);
            break;
          }
        }else{
          switch(typo){

            case 'cuisine':
              this.tempCuisine = [];
              this.typeDiametreTmp['cuisine'] = [];
            break;
            case 'sdb':
              this.tempSdb = [];
              this.typeDiametreTmp['sdb'] = [];
            break;
            case 'wc':
              this.tempWC = [];
              this.typeDiametreTmp['wc'] = [];
            break;
            case 'sdb_wc':
              this.tempSdb_wc = [];
              this.typeDiametreTmp['sdbwc'] = [];
            break;
            case 'eau':
              this.tempEau = [];
              this.typeDiametreTmp['eau'] = [];
            break;
          }
        }
        this.computeDebit();
        this.form = {"cuisine":this.tempCuisine,'rejet':this.tempRejet,'eau':this.tempEau,'sdb':this.tempSdb,'wc':this.tempWC,'sdb_wc':this.tempSdb_wc}
        let validate = this.tempCuisine.longueur!=0;
        console.log('updateConfig');
        console.log(this.form);
        this.validate = validate;
      },
      onSubmit(evt) {
        console.log("onSumbit");
        console.log(this.tempCuisine);
        console.log(this.$store.state.devis.devis.trace);
        evt.preventDefault();
        var cuisineData = this.tempCuisine;
        let data = {"traceByDraw":false,"cuisine":cuisineData,'rejet':this.tempRejet,'eau':this.tempEau,'sdb':this.tempSdb,'wc':this.tempWC,'sdb_wc':this.tempSdb_wc, "debit_min":this.debit_min, "debit_max":this.debit_max, "debit_fuite":this.debit_fuite, "debit_branche":this.debit_branche};
        this.networkAction = true;
        if(this.traceByDraw){
          if(!this.placedCuisine){
            data.cuisine = [];
          }
          data.traceByDraw = true;
          var stageSaved = this.mainStage.toJSON();
          console.log(stageSaved);
          var layersImgSaved= [];
          var allLayers = this.mainStage.getLayers();
          for(var i = 1 ; i < allLayers.length ; i++){
            allLayers[i].show();
            console.log(allLayers[i]);
            var tmpIndex = i - 1 ;
            console.log("#plan_"+tmpIndex);
            var tmpPlan = allLayers[i].find("#plan_"+tmpIndex);
            console.log(tmpPlan[0]);
            console.log(tmpPlan[0].width());
            var rotationOffsetX = 0;
            var rotationOffsetY = 0;
            var widthToSave = (tmpPlan[0].width()*tmpPlan[0].scaleX());
            var heightToSave = (tmpPlan[0].height()*tmpPlan[0].scaleY());
            var tmpToSave = 0;
            console.log("rotation Plan");
            console.log(tmpPlan[0].rotation());
            console.log(widthToSave);
            console.log(heightToSave);
            if(tmpPlan[0].rotation()==90){
              rotationOffsetX -= heightToSave;
              tmpToSave = widthToSave;
              widthToSave = heightToSave;
              heightToSave = tmpToSave;
            }else if(tmpPlan[0].rotation()==-90){
            //  rotationOffsetX += tmpPlan[0].width();
              rotationOffsetY -= widthToSave;
              tmpToSave = widthToSave;
              widthToSave = heightToSave;
              heightToSave = tmpToSave;
            }else if(tmpPlan[0].rotation()==180){
              rotationOffsetX -= widthToSave;
              rotationOffsetY -= heightToSave;
            }
            else if(tmpPlan[0].rotation()==-180){
              rotationOffsetX -= widthToSave;
              rotationOffsetY -= heightToSave;
            }
            var offsetXSave = rotationOffsetX + tmpPlan[0].x()-10;
            var offsetYSave = rotationOffsetY + tmpPlan[0].y()-10;
            layersImgSaved.push({
              image:allLayers[i].toDataURL({
                x: offsetXSave,
                y: offsetYSave,
                width: widthToSave+10,
                height: heightToSave+10,
                pixelRatio: 2
              }),
              title:this.planChantierLabel[(i-1)]
            });
            allLayers[i].hide();
          }
          allLayers[this.selectedLayer].show();
          console.log(layersImgSaved);
          data['layersImg'] = layersImgSaved;
          data['layersLabel'] = this.planChantierLabel;
          data['visualBranche'] = this.visualBranche;
          data['selectedLayer'] = this.selectedLayer;
          data['stageSaved'] = stageSaved;
          data['scale'] = this.scale;

        }
        this.$store.dispatch('devis/setTrace',data);
        console.log(this.$store.state.devis.devis.trace);
        const baseURI = this.$api_url+'perte';
        var tmpTrace = { ...this.$store.state.devis.devis.trace };
        delete tmpTrace.layersImg;
        delete tmpTrace.layersLabel;
        delete tmpTrace.visualBranche;
        delete tmpTrace.selectedLayer;
        delete tmpTrace.stageSaved;
        console.log("debug chantier diametre");
        console.log(this.$store.state.devis.devis.chantier.gaine);
        this.$http.post(baseURI,{'chantier':this.$store.state.devis.devis.chantier,'trace':tmpTrace}).then((result) => {
          if(result){
            console.log(result.data);
            this.$store.dispatch('devis/setCaissons',result.data.caissons);
            this.$store.dispatch('devis/setBranche',{max:result.data.perteBranche.max,min:result.data.perteBranche.min});
            this.$store.dispatch('devis/setAllBranche',{cuisine: result.data.perteBranche.cuisine, sdb: result.data.perteBranche.sdb, sdbwc: result.data.perteBranche.sdbwc,wc: result.data.perteBranche.wc,eau: result.data.perteBranche.eau,rejet: result.data.perteBranche.rejet});
            if(this.$dev){
              this.$store.dispatch('devis/setInformations',{
                ref_chantier: "dev",
                siret: "dev",
                departement:  "dev",
                ville: "dev",
                societe: "dev",
                distributeur: "dev",
                devis_fait_par: "dev",
                mail : "dev",
                telephone :"dev",
                demandeur: "dev",
                mail_demandeur: "dev",
                telephone_demandeur: "dev"
              });
              const baseURI = this.$api_url+'report';
              console.log(this.$store.state.devis.devis);
              var tmpDevis = JSON.parse(JSON.stringify(this.$store.state.devis.devis));
              delete tmpDevis.trace.layersImg;
              delete tmpDevis.trace.layersLabel;
              delete tmpDevis.trace.visualBranche;
              delete tmpDevis.trace.selectedLayer;
              delete tmpDevis.trace.stageSaved;
              console.log(tmpDevis);

              this.$http.post(baseURI,{'devis':tmpDevis})
              .then((result) => {
                if(result){
                  this.$store.state.devis.devis.id = result.data.id;
                  this.$router.push('caissons');
                }
              });
            }else{
              this.$router.push('informations');
            }
          }
        });
      },
      onReset(evt) {
        evt.preventDefault();
        this.$router.go(-1);
      },
      onSubmitDraw(evt) {
        evt.preventDefault();
        let data = {"cuisine":this.tempCuisine,'rejet':this.tempRejet,'eau':this.tempEau,'sdb':this.tempSdb,'wc':this.tempWC,'sdb_wc':this.tempSdb_wc, "debit_min":this.debit_min, "debit_max":this.debit_max, "debit_fuite":this.debit_fuite, "debit_branche":this.debit_branche};
        this.$store.dispatch('devis/setTrace',data);
        console.log(this.$store.state.devis.devis.trace);
        const baseURI = this.$api_url+'perte';
        this.$http.post(baseURI,{'chantier':this.$store.state.devis.devis.chantier,'trace':this.$store.state.devis.devis.trace}).then((result) => {
          if(result){
            console.log(result.data);
            this.$store.dispatch('devis/setCaissons',result.data.caissons);
            this.$store.dispatch('devis/setBranche',{max:result.data.perteBranche.max,min:result.data.perteBranche.min});

            if(this.$dev){
              this.$store.dispatch('devis/setInformations',{});
              this.$router.push('caissons');

            }else{
              this.$router.push('informations');
            }
          }
        });
      },
      onResetDraw(evt) {
        evt.preventDefault();
        this.$router.go(-1);
      },
      setConfig(){
        let validate = this.tempCuisine.longueur!=0;
        console.log(validate);
        this.validate = validate;
      },
      computeDebit(){
        console.log("compute debit");
        console.log(this.$store.state.devis.devis.debit);

        var cuisineTemp = this.tempCuisine;
        if(!this.placedCuisine){
          cuisineTemp = [];
        }

        console.log(this.tempSdb.length);
        console.log(this.tempEau.length);
        console.log(this.tempWC.length);
        console.log(this.tempSdb_wc.length);
        console.log(cuisineTemp.length);
        console.log((this.tempSdb.length + this.tempEau.length + this.tempWC.length + this.tempSdb_wc.length));
        if((this.tempSdb.length + this.tempEau.length + this.tempWC.length + this.tempSdb_wc.length) == 0){
          this.debit_min.cuisine = this.debit_min.total = this.$store.state.devis.devis.debit.debit[0].debit.cuisine.debit_min*cuisineTemp.length;
          this.debit_max.cuisine = this.debit_max.total = this.$store.state.devis.devis.debit.debit[0].debit.cuisine.debit_max*cuisineTemp.length;


        }else{
          //calcul debit min
          var current_debit = -1;
          //var tmpDebit = 0;
          if(this.$store.state.devis.devis.chantier.typologie>=6){
            if(this.tempSdb.length==1){ //si sdbwc >=2
              //condition pas de wc séparé à rajouté
              if(this.tempWC.length==0){
                current_debit = this.$store.state.devis.devis.debit.debit.findIndex(item => item.sdbwc == 2);
              }else{
                current_debit = this.$store.state.devis.devis.debit.debit.findIndex(item => (item.sdbwc == 1 && item.sdb==1));
              }
              /*this.debit_min.sdb = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbsupp;
              this.debit_min.sdbwc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc1>this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc2?this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc1:this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc2;
              this.debit_min.wc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wcsupp;
              this.debit_min.eau = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.eau;
              tmpDebit += this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc1 + this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc2 + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwcsupp * (this.tempSdb_wc.length-2))
              + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wcsupp * (this.tempWC.length))
              + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbsupp * (this.tempSdb.length));*/
            }else if(this.tempSdb.length>=2){ //si sdb >=2
              if(this.tempWC.length==0){
                current_debit = this.$store.state.devis.devis.debit.debit.findIndex(item => item.sdbwc == 2);
              }else{
                current_debit = this.$store.state.devis.devis.debit.debit.findIndex(item => item.sdb == 2);
              }
              /*this.debit_min.sdb = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb1>this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb2?this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb1:this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb2;
              this.debit_min.sdbwc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwcsupp;
              this.debit_min.wc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wc;
              this.debit_min.eau = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.eau;
              var wcDebit1 = 0;
              if(this.tempWC.length>0){
                 wcDebit1 = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wc + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wcsupp * (this.tempWC.length-1))
              }
              tmpDebit += this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb1 + this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb2 + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbsupp * (this.tempSdb.length-2))
              + wcDebit1
              + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwcsupp * (this.tempSdb_wc.length));*/
            }else{
              current_debit = this.$store.state.devis.devis.debit.debit.findIndex(item => item.sdbwc == 2);
              /*this.debit_min.sdb = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb1;
              this.debit_min.sdbwc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc1;
              this.debit_min.wc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wc;
              this.debit_min.eau = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.eau;
              var wc = 0;
              if(this.tempWC.length>0){
                 wc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wc + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wcsupp * (this.tempWC.length-1))
              }
              tmpDebit += this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc1 + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwcsupp * (this.tempSdb_wc.length-1))
              + this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb1 + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbsupp * (this.tempSdb.length-1))
              + wc;*/
            }
          }else{
            //condition trop strict pas mettre sdb = 0
            if(this.tempSdb_wc.length>=1 && this.tempWC.length==0){
              current_debit = this.$store.state.devis.devis.debit.debit.findIndex(item => item.sdbwc == 1);
              /*this.debit_min.sdb = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbsupp;
              this.debit_min.sdbwc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc1;
              this.debit_min.wc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wcsupp;
              this.debit_min.eau = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.eau;
              tmpDebit += this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwc1 + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwcsupp * (this.tempSdb_wc.length-1))
              + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wcsupp * (this.tempWC.length))
              + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbsupp * (this.tempSdb.length));*/
            }else{
              if(this.tempSdb.length==0){
                current_debit = this.$store.state.devis.devis.debit.debit.findIndex(item => (item.sdb == 0 && item.wc==1));
              }else{
                current_debit = this.$store.state.devis.devis.debit.debit.findIndex(item => (item.sdb == 1 && item.wc==1));
              }
              /*console.log(this.$store.state.devis.devis.debit.debit_min[current_debitmin]);
              this.debit_min.sdb = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb1;
              this.debit_min.sdbwc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwcsupp;
              this.debit_min.wc = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wc;
              this.debit_min.eau = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.eau;
              var sdb = 0;
              if(this.tempSdb.length>0){
                sdb = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdb1 + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbsupp * (this.tempSdb.length-1));
              }

              var wcDebit = 0;
              if(this.tempWC.length>0){
                wcDebit = this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wc + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.wcsupp * (this.tempWC.length -1))
              }

              tmpDebit += sdb + wcDebit + (this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.sdbwcsupp * (this.tempSdb_wc.length));
              console.log(tmpDebit);*/
            }
          }
          /*this.debit_min.cuisine = this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_min*cuisineTemp.length;
          console.log(this.$store.state.devis.devis.chantier.fuite);
          this.debit_min.total = ((this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.cuisine*cuisineTemp.length)
          +(this.$store.state.devis.devis.debit.debit_min[current_debitmin].debit.eau*this.tempEau.length) + tmpDebit)*/
          // debit min il faut récupéré max ( debit min bouche ; 35)
          this.$store.dispatch('devis/updateDebits',current_debit);
          console.log(this.$store.state.devis.devis.debit);
          this.debit_fuite.cuisine = this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max>35?((this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max/100)*this.$store.state.devis.devis.chantier.fuite):(((35/100)*this.$store.state.devis.devis.chantier.fuite));
          this.debit_fuite.sdb1 = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_max>35?((this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_max/100)*this.$store.state.devis.devis.chantier.fuite):(((35/100)*this.$store.state.devis.devis.chantier.fuite));
          this.debit_fuite.sdb2 = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb2.debit_max>35?((this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb2.debit_max/100)*this.$store.state.devis.devis.chantier.fuite):(((35/100)*this.$store.state.devis.devis.chantier.fuite));
          this.debit_fuite.sdbsupp = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_max>35?((this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_max/100)*this.$store.state.devis.devis.chantier.fuite):(((35/100)*this.$store.state.devis.devis.chantier.fuite));
          console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit);
          console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_max);
          console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_max>35);
          this.debit_fuite.sdbwc1 = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max>35?((this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max/100)*this.$store.state.devis.devis.chantier.fuite):(((35/100)*this.$store.state.devis.devis.chantier.fuite));
          this.debit_fuite.sdbwc2 = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_max>35?((this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_max/100)*this.$store.state.devis.devis.chantier.fuite):(((35/100)*this.$store.state.devis.devis.chantier.fuite));
          this.debit_fuite.sdbwcsupp = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_max>35?((this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_max/100)*this.$store.state.devis.devis.chantier.fuite):(((35/100)*this.$store.state.devis.devis.chantier.fuite));

          this.debit_fuite.wc = ((this.$store.state.devis.devis.debit.debit[current_debit].debit.wcsupp.debit_max/100)*this.$store.state.devis.devis.chantier.fuite);
          this.debit_fuite.eau = this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_max>35?((this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_max/100)*this.$store.state.devis.devis.chantier.fuite):(((35/100)*this.$store.state.devis.devis.chantier.fuite));
          console.log(this.debit_fuite);
          /*var tmpDebitMinBouche = {
            cuisine: >35?this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_min:35,
            sdb1:this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_min>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_min:35,
            sdb2:this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb2.debit_min>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb2.debit_min:35,
            sdbsupp:this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_min>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_min:35,
            sdbwc1:this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_min>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_min:35,
            sdbwc2:this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_min>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_min:35,
            sdbwcsupp:this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min:35,
            wcsupp:this.$store.state.devis.devis.debit.debit[current_debit].debit.wcsupp.debit_min,
            eau:this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min:35
          };*/
          console.log("SDBWC2 MIN");
          console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_min);
          console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_max);
          console.log(this.debit_fuite.sdbwc2);
          var debit_min = {
            cuisine:this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_min + this.debit_fuite.cuisine,
            sdb1: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_min + this.debit_fuite.sdb1,
            sdb2: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb2.debit_min + this.debit_fuite.sdb2,
            sdbsupp: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_min + this.debit_fuite.sdbsupp,
            sdbwc1: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_min + this.debit_fuite.sdbwc1,
            sdbwc2:this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_min + this.debit_fuite.sdbwc2,
            sdbwcsupp:this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min + this.debit_fuite.sdbwcsupp,
            wcsupp:this.$store.state.devis.devis.debit.debit[current_debit].debit.wcsupp.debit_min + this.debit_fuite.wc,
            eau:this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min + this.debit_fuite.eau
          };
          console.log(debit_min);

          this.debit_min.total = (debit_min.cuisine*cuisineTemp.length) + debit_min.sdb1 + debit_min.sdb2
          + (debit_min.sdbsupp*(this.tempSdb.length-2)) + debit_min.sdbwc1 + debit_min.sdbwc2
          + (debit_min.sdbwcsupp*(this.tempSdb_wc.length-2)) + (debit_min.wcsupp * this.tempWC.length)
          + (debit_min.eau*this.tempEau.length);
          this.debit_min.sdb1 = debit_min.sdb1;
          this.debit_min.sdb2 = debit_min.sdb2;
          this.debit_min.sdbsupp = debit_min.sdbsupp;
          this.debit_min.sdbwc1 = debit_min.sdbwc1;
          this.debit_min.sdbwc2 = debit_min.sdbwc2;
          this.debit_min.sdbwcsupp = debit_min.sdbwcsupp;
          this.debit_min.wc = debit_min.wcsupp;
          this.debit_min.eau = debit_min.eau;
          this.debit_min.cuisine = debit_min.cuisine*cuisineTemp.length;
          console.log(this.debit_min);
          console.log(debit_min);
          //this.debit_min.total = this.debit_min.total*(1+(this.$store.state.devis.devis.chantier.fuite/100));

          //3 sdb sans WC prendre cas 1wc non séparé avec 1sdb
          if(cuisineTemp.length==1){
            if(this.tempWC.length==0){
              this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max;
              this.debit_max.wc = 0;
              this.debit_max.sdb = 0;
              this.debit_max.eau = this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_max;
              if(this.tempSdb_wc.length==1 && this.tempSdb.length==0){
                this.debit_max.total = (this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max*cuisineTemp.length) + this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max;
                if(this.tempEau.length>0){
                  this.debit_max.total += (this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_max);
                }
                if(this.tempEau.length>1){ this.debit_max.total += (this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min*(this.tempEau.length-1)); }
              }else if(this.tempSdb.length>=1 && this.tempSdb_wc.length>=1){
                console.log("ok");
                console.log(this.tempSdb.length);
                this.debit_max.sdb = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_max;
                this.debit_max.total = (this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max*cuisineTemp.length) + this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max + this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_max;
                if(this.tempSdb.length>=2){
                  this.debit_max.total += this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb2.debit_min + (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_min*(this.tempSdb.length-2));
                }
                if(this.tempSdb_wc.length>=2){
                  this.debit_max.total += this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_min + (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min*(this.tempSdb_wc.length-2));
                }

                this.debit_max.total += (this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min*this.tempEau.length);
              }else if(this.tempSdb.length==0 && this.tempSdb_wc.length>=2){
                console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max);
                console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_max);
                console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min);
                console.log(this.tempEau.length);
                this.debit_max.sdb = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_max;
                this.debit_max.total = (this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max*cuisineTemp.length) + this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max + this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_max +
                (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min*(this.tempSdb_wc.length-2)) + (this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min*this.tempEau.length);
              }
            }else{
              this.debit_max.wc = this.$store.state.devis.devis.debit.debit[current_debit].debit.wc.debit_max;
              this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max;
              this.debit_max.eau = this.tempEau.length > 0 ? this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_max : 0;
              if(this.tempSdb.length==0){
                this.debit_max.sdb = 0;
                this.debit_max.total = (this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max*cuisineTemp.length) + this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max + this.$store.state.devis.devis.debit.debit[current_debit].debit.wc.debit_max;
                if(this.tempWC.length>1){
                  this.debit_max.total += (this.$store.state.devis.devis.debit.debit[current_debit].debit.wcsupp.debit_min*(this.tempWC.length-1));
                }

                if(this.tempSdb_wc.length>=2){
                  this.debit_max.total += this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_min + (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min*(this.tempSdb_wc.length-2));
                }

                this.debit_max.total += (this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min*this.tempEau.length);
              }else{
                this.debit_max.sdb = this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_max;

                this.debit_max.total = (this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max*cuisineTemp.length) + this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_max + this.$store.state.devis.devis.debit.debit[current_debit].debit.wc.debit_max +
                (this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min*this.tempEau.length);

                if(this.tempSdb_wc.length>=2){
                  this.debit_max.total += this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_min + this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_min + (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min*(this.tempSdb_wc.length-2));
                }else if(this.tempSdb_wc.length==1){
                  this.debit_max.total += this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_min;
                }

                if(this.tempWC.length>1){
                  this.debit_max.total += (this.$store.state.devis.devis.debit.debit[current_debit].debit.wcsupp.debit_min*(this.tempWC.length-1));
                }

                if(this.tempSdb.length>1){
                  console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit);
                  this.debit_max.total += this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb2.debit_min + (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_min*(this.tempSdb.length-2));
                }

              }
            }
          }else{
            //calcul page 25 CPT
            //debit min 27
            //debit max téorique 60, débit fuité 60 + 3*4.2 = 72
            var secondTmpDebitMax = {
                sdb1: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb1.debit_max,
                sdb2: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdb2.debit_max,
                sdbsupp: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_max,
                sdbwc1: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc1.debit_max,
                sdbwc2: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwc2.debit_max,
                sdbwcsupp: this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_max,
                wc: this.$store.state.devis.devis.debit.debit[current_debit].debit.wc.debit_max,
                wcsupp: this.$store.state.devis.devis.debit.debit[current_debit].debit.wcsupp.debit_max,
                eau: this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_max
            };
            if(this.tempSdb.length==0){
              secondTmpDebitMax.sdb1 = secondTmpDebitMax.sdb2 = secondTmpDebitMax.sdbsupp = 0;
            }

            if(this.tempSdb_wc.length==0){
              secondTmpDebitMax.sdbwc1 = secondTmpDebitMax.sdbwc2 = secondTmpDebitMax.sdbwcsupp = 0;
            }

            if(this.tempWC.length==0){
              secondTmpDebitMax.wc = secondTmpDebitMax.wcsupp = 0;
            }

            if(this.tempEau.length==0){
              secondTmpDebitMax.eau = 0;
            }

            var index1 = -1; var index2 = -1; var index3 = -1;
            var max3 = 0;
            var max2 = 0;
            var max1 = 0;
            var sdbLength = 0; var sdbwcLength = 0; var wcLength = 0; var eauLength = 0;
            var pieceSanitaire = this.tempSdb.length + this.tempSdb_wc.length + this.tempWC.length + this.tempEau.length;
            console.log(secondTmpDebitMax);
            console.log(pieceSanitaire);
            if(pieceSanitaire>0){
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdb1>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.eau){
              index1 = "sdb1";
            }
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdb2
            && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbsupp>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.eau){
              index1 = "sdbsupp";
            }
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdb2>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.eau){
              index1 = "sdb2";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwc2>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.eau){
              index1 = "sdbwc2";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwc1>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.eau){
              index1 = "sdbwc1";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwcsupp>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.eau){
              index1 = "sdbwcsupp";
            }
            if((this.tempWC.length-wcLength)>0 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.wcsupp>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.eau){
              index1 = "wcsupp";
            }
            if((this.tempWC.length-wcLength)>0 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.wc >= secondTmpDebitMax.wcsupp && secondTmpDebitMax.wc>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.wc >= secondTmpDebitMax.eau){
              index1 = "wc";
            }
            if((this.tempEau.length-eauLength)>0 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.eau >= secondTmpDebitMax.wcsupp && secondTmpDebitMax.eau>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.eau >= secondTmpDebitMax.wc){
              index1 = "eau";
            }

            console.log(index1);
            if(index1!=-1){
              max1 = secondTmpDebitMax[index1];
              secondTmpDebitMax[index1] = 0;
              if(index1=="sdb1" || index1=="sdb2" ||index1=="sdbsupp"){
                sdbLength++;
              }
              if(index1=="sdbwc1" || index1=="sdbwc2" ||index1=="sdbwcsupp"){
                sdbwcLength++;
              }
              if(index1=="wc" || index1=="wcsupp"){
                wcLength++;
              }
              if(index1=="eau" || index1=="eausupp"){
                eauLength++;
              }

              if((this.tempWC.length-wcLength)==0){
                secondTmpDebitMax['wc'] = 0;
                secondTmpDebitMax['wcsupp'] = 0;
              }
              if((this.tempSdb_wc.length-sdbwcLength)==0){
                secondTmpDebitMax['sdbwc1'] = 0;
                secondTmpDebitMax['sdbwc2'] = 0;
                secondTmpDebitMax['sdbwcsupp'] = 0;
              }
              if((this.tempSdb.length-sdbLength)==0){
                secondTmpDebitMax['sdb1'] = 0;
                secondTmpDebitMax['sdb2'] = 0;
                secondTmpDebitMax['sdbsupp'] = 0;
              }

              if((this.tempEau.length-eauLength)>0 && index1=="eau"){
                secondTmpDebitMax["eau"] = max1;
              }
            }
          }
          if(pieceSanitaire>1){
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdb1>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.eau){
              index2 = "sdb1";
            }
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdb2
            && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbsupp>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.eau){
              index2 = "sdbsupp";
            }
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdb2>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.eau){
              index2 = "sdb2";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwc2>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.eau){
              index2 = "sdbwc2";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwc1>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.eau){
              index2 = "sdbwc1";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwcsupp>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.eau){
              index2 = "sdbwcsupp";
            }
            if((this.tempWC.length-wcLength)>0 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.wcsupp>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.eau){
              index2 = "wcsupp";
            }
            if((this.tempWC.length-wcLength)>0 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.wc >= secondTmpDebitMax.wcsupp && secondTmpDebitMax.wc>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.wc >= secondTmpDebitMax.eau){
              index2 = "wc";
            }
            if((this.tempEau.length-eauLength)>0 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.eau >= secondTmpDebitMax.wcsupp && secondTmpDebitMax.eau>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.eau >= secondTmpDebitMax.wc){
              index2 = "eau";
            }
            if(index2!=-1){
              max2 = secondTmpDebitMax[index2];
              secondTmpDebitMax[index2] = 0;
              if(index2=="sdb1" || index2=="sdb2" || index2=="sdbsupp"){
                sdbLength++;
              }
              if(index2=="sdbwc1" || index2=="sdbwc2" || index2=="sdbwcsupp"){
                sdbwcLength++;
              }
              if(index2=="wc" || index2=="wcsupp"){
                wcLength++;
              }
              if(index2=="eau" || index2=="eausupp"){
                eauLength++;
              }

              if((this.tempWC.length-wcLength)==0){
                secondTmpDebitMax['wc'] = 0;
                secondTmpDebitMax['wcsupp'] = 0;
              }else{
                if(index2=="wcsupp") secondTmpDebitMax["wcsupp"] = max2;
              }
              if((this.tempSdb_wc.length-sdbwcLength)==0){
                secondTmpDebitMax['sdbwc1'] = 0;
                secondTmpDebitMax['sdbwc2'] = 0;
                secondTmpDebitMax['sdbwcsupp'] = 0;
              }
              if((this.tempSdb.length-sdbLength)==0){
                secondTmpDebitMax['sdb1'] = 0;
                secondTmpDebitMax['sdb2'] = 0;
                secondTmpDebitMax['sdbsupp'] = 0;
              }

              if((this.tempEau.length-eauLength)>0 && index2=="eau"){
                secondTmpDebitMax["eau"] = max2;
              }
            }
          }
          if(pieceSanitaire>2){
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdb1>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdb1 >= secondTmpDebitMax.eau){
              index3 = "sdb1";
            }
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdb2
            && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbsupp>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbsupp >= secondTmpDebitMax.eau){
              index3 = "sdbsupp";
            }
            if((this.tempSdb.length-sdbLength)>0 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdb2>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdb2 >= secondTmpDebitMax.eau){
              index3 = "sdb2";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbwc1 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwc2>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwc2 >= secondTmpDebitMax.eau){
              index3 = "sdbwc2";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.sdbwcsupp
          && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwc1>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwc1 >= secondTmpDebitMax.eau){
              index3 = "sdbwc1";
            }
            if((this.tempSdb_wc.length-sdbwcLength)>0 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.sdbwcsupp>=secondTmpDebitMax.wcsupp && secondTmpDebitMax.sdbwcsupp >= secondTmpDebitMax.eau){
              index3 = "sdbwcsupp";
            }
            if((this.tempWC.length-wcLength)>0 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.wc && secondTmpDebitMax.wcsupp>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.wcsupp >= secondTmpDebitMax.eau){
              index3 = "wcsupp";
            }
            if((this.tempWC.length-wcLength)>0 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.wc >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.wc >= secondTmpDebitMax.wcsupp && secondTmpDebitMax.wc>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.wc >= secondTmpDebitMax.eau){
              index3 = "wc";
            }
            if((this.tempEau.length-eauLength)>0 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdb1 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbsupp
            && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbwc2 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdb2 && secondTmpDebitMax.eau >= secondTmpDebitMax.sdbwc1
          && secondTmpDebitMax.eau >= secondTmpDebitMax.wcsupp && secondTmpDebitMax.eau>=secondTmpDebitMax.sdbwcsupp && secondTmpDebitMax.eau >= secondTmpDebitMax.wc){
              index3 = "eau";
            }

            if(index3!=-1){
              max3 = secondTmpDebitMax[index3];
              if(index3=="sdb1" || index3=="sdb2" || index3=="sdbsupp"){
                sdbLength++;
              }
              if(index3=="sdbwc1" || index3=="sdbwc2" || index3=="sdbwcsupp"){
                sdbwcLength++;
              }
              if(index3=="wc" || index3=="wcsupp"){
                wcLength++;
              }
              if(index3=="eau" || index3=="eausupp"){
                eauLength++;
              }

              if((this.tempWC.length-wcLength)==0){
                secondTmpDebitMax['wc'] = 0;
                secondTmpDebitMax['wcsupp'] = 0;
              }
              if((this.tempSdb_wc.length-sdbwcLength)==0){
                secondTmpDebitMax['sdbwc1'] = 0;
                secondTmpDebitMax['sdbwc2'] = 0;
                secondTmpDebitMax['sdbwcsupp'] = 0;
              }
              if((this.tempSdb.length-sdbLength)==0){
                secondTmpDebitMax['sdb1'] = 0;
                secondTmpDebitMax['sdb2'] = 0;
                secondTmpDebitMax['sdbsupp'] = 0;
              }
            }
          }

            var minSum = (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_min*(this.tempSdb.length-sdbLength)) + (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_min*(this.tempSdb_wc.length-sdbwcLength))
            + (this.$store.state.devis.devis.debit.debit[current_debit].debit.wcsupp.debit_min*(this.tempWC.length-wcLength)) + (this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_min*(this.tempEau.length-eauLength));

            console.log(minSum);
            console.log(max1);
            console.log(max2);
            console.log(max3);
            this.debit_max.total = max1 + max2 + max3 + minSum;
            console.log("secondaire total théorique sans cuisine");
            console.log(this.debit_max.total);
          }
          this.debit_max.cuisine = (this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max*cuisineTemp.length);

          //this.debit_max.total = this.debit_max.total*(1+(this.$store.state.devis.devis.chantier.fuite/100));
          this.debit_max.total_fuite = this.debit_max.total + (this.debit_fuite.cuisine*cuisineTemp.length)+ (this.debit_fuite.eau*this.tempEau.length) + (this.debit_fuite.wc*this.tempWC.length);
          if(this.tempSdb.length>=3){
            this.debit_max.total_fuite += this.debit_fuite.sdb1 + this.debit_fuite.sdb2 + (this.debit_fuite.sdbsupp*(this.tempSdb.length-2));
          }else if(this.tempSdb.length==2){
            this.debit_max.total_fuite += this.debit_fuite.sdb1 + this.debit_fuite.sdb2;
          }else if(this.tempSdb.length==1){
            this.debit_max.total_fuite += this.debit_fuite.sdb1;
          }

          if(this.tempSdb_wc.length>=3){
            this.debit_max.total_fuite += this.debit_fuite.sdbwc1 + this.debit_fuite.sdbwc2 + (this.debit_fuite.sdbwcsupp*(this.tempSdb_wc.length-2));
          }else if(this.tempSdb_wc.length==2){
            this.debit_max.total_fuite += this.debit_fuite.sdbwc1 + this.debit_fuite.sdbwc2;
          }else if(this.tempSdb_wc.length==1){
            this.debit_max.total_fuite += this.debit_fuite.sdbwc1;
          }


          var tmpDebitMax = {
            cuisine: this.$store.state.devis.devis.debit.debit[current_debit].debit.cuisine.debit_max,
            sdb: (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_max>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbsupp.debit_max:35),
            sdbwc: (this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_max>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.sdbwcsupp.debit_max:35),
            wc: this.$store.state.devis.devis.debit.debit[current_debit].debit.wcsupp.debit_max,
            eau: (this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_max>35?this.$store.state.devis.devis.debit.debit[current_debit].debit.eau.debit_max:35),
          }
          console.log("debug debit max");
          console.log(tmpDebitMax);
          console.log(this.$store.state.devis.devis.debit.debit[current_debit].debit);


          this.debit_branche.cuisine =  tmpDebitMax.cuisine + this.debit_fuite.cuisine;
          this.debit_branche.sdb =  tmpDebitMax.sdb + this.debit_fuite.sdb1;
          this.debit_branche.sdbwc = tmpDebitMax.sdbwc + this.debit_fuite.sdbwc1;
          this.debit_branche.wc = tmpDebitMax.wc + this.debit_fuite.wc;
          this.debit_branche.eau = tmpDebitMax.eau + this.debit_fuite.eau;
          console.log("SHOW DEBITS SAVED");
          console.log(this.debit_fuite);
          console.log(this.debit_branche);
          //calcul debit max
          /*var current_debitmax = -1;
          var tmpDebitMax = 0;

          if(this.tempWC.length>0){
            if(this.$store.state.devis.devis.chantier.typologie>=6){
              if(this.tempSdb.length>=2){
                current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == 1 && item.sdb==2 ));
                this.debit_max.sdb = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1>this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb2?this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1:this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb2;
                this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp;
                this.debit_max.wc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc;
                this.debit_max.eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eau;

                tmpDebitMax += this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb2 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc
                 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbsupp * (this.tempSdb.length-2))
                 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp * (this.tempSdb_wc.length))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp * (this.tempEau.length))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wcsupp * (this.tempWC.length-1));
              }else if(this.tempSdb.length==1){
                current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == 1 && item.sdb==1 ));
                this.debit_max.sdb = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1;
                this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1;
                this.debit_max.wc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc;
                this.debit_max.eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eau;

                tmpDebitMax += this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc
                 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp * (this.tempSdb_wc.length-1))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp * (this.tempEau.length))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wcsupp * (this.tempWC.length-1));
              }else{
                current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == 1 && item.sdbwc==2));
                this.debit_max.sdb = 0;
                this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1>this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc2?this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1:this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc2;
                this.debit_max.wc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc;
                this.debit_max.eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eau;

                tmpDebitMax += this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc2 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc
                 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp * (this.tempSdb_wc.length-2))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp * (this.tempEau.length))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wcsupp * (this.tempWC.length-1));
              }
            }else{
              if(this.tempSdb.length>0){
                current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == 1 && item.sdb==1 ));
                this.debit_max.sdb = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1;
                this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp;
                this.debit_max.wc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc;
                this.debit_max.eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eau;
                tmpDebitMax += this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc
                 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbsupp * (this.tempSdb.length-1))
                 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp * (this.tempSdb_wc.length))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp * (this.tempEau.length))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wcsupp * (this.tempWC.length-1));
              }else{
                current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == 1 && item.sdb==-1 ));

                this.debit_max.sdb = 0;
                this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1;
                this.debit_max.wc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc;
                this.debit_max.eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp;
                tmpDebitMax += this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wc
                 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp * (this.tempSdb_wc.length-1))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp * (this.tempEau.length))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.wcsupp * (this.tempWC.length-1));
              }
            }

          }else{
            if(this.tempSdb.length>0){
              current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == -1 && item.sdb==1 ));
              console.log(current_debitmax);
              if(current_debitmax==-1){
                current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == -1 && item.sdb==0 ));
              }
              //bug avec T7 Cuisine Bouche puis SDB
              this.debit_max.sdb = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1;
              //bug avec T7
              this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1;
              this.debit_max.wc = 0;
              this.debit_max.eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp;
              var sdbwc = 0;
              if(this.tempSdb_wc.length>0){
                sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp * (this.tempSdb_wc.length-1));
              }
              tmpDebitMax += this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdb1 +
               + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbsupp * (this.tempSdb.length-1))
              + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp * (this.tempEau.length))
              + sdbwc;
            }else{
              if(this.tempSdb_wc.length>=2){
                current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == -1 && item.sdb==-1 && item.sdbwc==2));
                if(current_debitmax==-1){
                  current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc == -1 && item.sdbwc==2));
                }
                this.debit_max.sdb = 0;
                this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1;
                this.debit_max.wc = 0;
                this.debit_max.eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp;

                tmpDebitMax += this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1 + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc2
                 + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwcsupp * (this.tempSdb_wc.length-2))
                + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp * (this.tempEau.length));
              }else{
                current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc==-1 && item.sdb==-1 && item.sdbwc==1));
                if(current_debitmax==-1){
                  current_debitmax = this.$store.state.devis.devis.debit.debit_max.findIndex(item => (item.wc==-1 && item.sdb==0));
                }
                this.debit_max.sdb = 0;
                this.debit_max.sdbwc = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1;
                this.debit_max.wc = 0;
                this.debit_max.eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eau;

                var eau = 0;
                if(this.tempEau.length>0){
                  eau = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eau + (this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.eausupp * (this.tempEau.length-1));
                }
                tmpDebitMax += this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.sdbwc1 + eau
              }

            }
          }
          this.debit_max.cuisine = this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.cuisine*cuisineTemp.length;
          console.log(this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.cuisine);
          this.debit_max.total = (tmpDebitMax + this.$store.state.devis.devis.debit.debit_max[current_debitmax].debit.cuisine*cuisineTemp.length)*(1+(this.$store.state.devis.devis.chantier.fuite/100));*/
        }

        console.log(this.debit_min);
        console.log(this.debit_max);
        if(this.customRejet){
          this.tempRejet.points_fonctionnement[0].debit = this.debit_min.total.toFixed(1);
          this.tempRejet.points_fonctionnement[1].debit = this.debit_max.total.toFixed(1);
        }
      },
    },
    watch: {
      planChantier(newValue, oldValue) {
        console.log('load plan chantier');
        console.log(newValue);
        console.log(oldValue);
        if (newValue !== oldValue) {
          if (newValue) {
            console.log(newValue);
            var promises = [];
            for(var i = 0 ; i < newValue.length; i++){
                promises.push(base64Encode(newValue[i]));
            }
            var self = this;
            Promise.all(promises).then((values) => {
                  var tmpValues = [];
                  for(var l = 0 ; l < values.length; l++){
                    if(Array.isArray(values[l])){
                      for(var k = 0 ; k < values[l].length; k++){
                        tmpValues.push(values[l][k]);
                      }
                    }else{
                      tmpValues.push(values[l]);
                    }
                  }

                  var tmpIndexes = 0;
                  var image = new Array(tmpValues.length);
                  var tmpCalqueIndex = 0;
                  if(self.planChantierSrc.length>0){
                    tmpCalqueIndex = self.planChantierSrc.length;
                    tmpIndexes = tmpCalqueIndex;
                    image = new Array((tmpCalqueIndex+tmpValues.length));
                  }
                  for(var j = 0 ; j < tmpValues.length; j++){
                      self.planChantierSrc[tmpCalqueIndex] = tmpValues[j];
                      if(tmpValues.length>1 || tmpIndexes>0){
                        self.planChantierLabel[tmpCalqueIndex] = "Plan Niveau "+(tmpCalqueIndex+1);
                      }else{
                        self.planChantierLabel[tmpCalqueIndex] = "Plan RDC";
                      }
                      image[tmpCalqueIndex] = new Image();
                      var selfImg = self;
                      image[tmpCalqueIndex].onload = function() {
                        console.log(tmpIndexes);
                        console.log(image[tmpIndexes].src);

                        var img_width = image[tmpIndexes].width;
                        var img_height = image[tmpIndexes].height;
                        console.log(img_width);
                        console.log(img_height);

                        // calculate dimensions to get max 300px
                        var max = selfImg.maxHeight;
                        /*console.log(max);
                        console.log(img_width);*/
                        var ratio;
                        var maxHeightImg=0;
                        var maxWidthImg=0;
                        var offsetX=0;
                        var offsetY=0;
                        if(img_width > img_height){
                          ratio = (img_width / img_height);
                          if((img_width*ratio)>selfImg.maxWidth){
                            if((selfImg.maxWidth/ratio)>max){
                              maxHeightImg = max;
                              maxWidthImg = maxHeightImg*ratio;
                            }else{
                              maxWidthImg = selfImg.maxWidth;
                              maxHeightImg = maxWidthImg/ratio;
                              offsetY = (selfImg.maxHeight-maxHeightImg)/2;
                            }
                          }else{
                            maxHeightImg = max;
                            maxWidthImg = maxHeightImg*ratio;
                          }
                        }else{
                          ratio = (img_height / img_width);
                          maxHeightImg = max;
                          maxWidthImg = maxHeightImg/ratio;

                          console.log(ratio);
                          console.log(maxWidthImg);
                          console.log(maxHeightImg);
                          console.log(offsetX);
                          console.log(offsetY);
                        }

                        // now load the Konva image
                        var theImg = new window.Konva.Image({
                          image: image[tmpIndexes],
                          x: offsetX,
                          y: offsetY,
                          width: maxWidthImg,
                          height: maxHeightImg,
                          draggable: true,
                          id: 'plan_'+tmpIndexes,
                          maxWidth:selfImg.maxWidth,
                          maxHeight:selfImg.maxHeight
                        });
                        console.log(theImg.absolutePosition());
                        var theTransformer = new window.Konva.Transformer({
                          id: 'transformer_'+tmpIndexes,
                          rotationSnaps: [0, 90, 180, 270],
                          enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
                          rotateAnchorOffset: -50
                        });
                        //end rotate deselect img

                        theTransformer.findOne('.rotater').on('mouseenter', () => {
                          // "content" property is not documented and private
                          // but for now you can use it
                          // it is element where transformer is applying its styles
                          selfImg.mainStage.content.style.cursor = 'pointer';
                          //selfImg.mainStage.content.style.cursor =  'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' fill=\'red\' height=\'24\'%3E%3Cpath d=\'M15 10h6V4h-2v2.339a9 9 0 1 0 1.716 7.91l-1.936-.5A7.028 7.028 0 1 1 17.726 8H15z\'/%3E%3C/svg%3E") 16 16, pointer';
                        });

                        theImg.on('click',function(){
                          selfImg.transformActive = true;
                          var transformerID = '#transformer_'+selfImg.selectedLayer;
                           const transformerNode = selfImg.mainLayer.find(transformerID);
                           console.log(this);
                           console.log(transformerNode);
                           transformerNode[0].nodes([this]);
                           /*transformerNode[0].anchorStyleFunc(function(anchor) {
                            // anchor is a simple Konva.Rect instance
                            // it will be executed AFTER all attributes are set, like 'anchorStrokeWidth' or 'anchorFill'
                            if (anchor.hasName('.rotater')) {
                              // make rotater anchor filled black and looks like a circle
                              anchor.fill('red');
                              anchor.strokewidth(15);
                              anchor.cornerRadius(5);
                            }
                          });*/
                               var shape = transformerNode[0].findOne('.rotater');
                               var icon = new window.Konva.Path({
                                 fill:"white",
                                 data: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-rotate</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M0.9,0.5c0.1,0,0.3,0.1,0.3,0.3L1.1,2.9c1-1.4,2.6-2.4,4.5-2.4c2.9,0,5.3,2.4,5.3,5.3c0,2.9-2.4,5.3-5.3,5.3c-1.4,0-2.6-0.5-3.6-1.4c-0.1-0.1-0.1-0.3,0-0.4L2.3,9c0.1-0.1,0.3-0.1,0.4,0c0.7,0.7,1.7,1.1,2.8,1.1c2.3,0,4.2-1.9,4.2-4.2S7.8,1.7,5.5,1.7c-1.7,0-3.2,1-3.8,2.5l2.7-0.1c0.1,0,0.3,0.1,0.3,0.3v0.6c0,0.1-0.1,0.3-0.3,0.3H0.3C0.1,5.2,0,5.1,0,4.9V0.8c0-0.1,0.1-0.3,0.3-0.3H0.9z"/></svg>',
                                 name: 'rotater-icon'
                               });
                               icon.position(shape.position());
                               icon.x(shape.x() - 5.25); icon.y(shape.y() - 5.25);
                               transformerNode[0].add(icon);

                               var rotationAnchor = transformerNode[0].findOne('.rotater');
                               rotationAnchor.cornerRadius(15);
                               rotationAnchor.stroke('red');
                               rotationAnchor.fill('red');
                               rotationAnchor.scale({
                                 x: 2,
                                 y:2
                               });
                               selfImg.mainStage.draw();
                        });

                        theImg.on('transform', function(){
                          if(selfImg.transformActive){
                            var transformerID = '#transformer_'+selfImg.selectedLayer;
                            var transformerNode = selfImg.mainLayer.find(transformerID);
                            transformerNode[0].update();
                            var shape = transformerNode[0].findOne('.rotater');
                            var icon = transformerNode[0].findOne('.rotater-icon');
                            icon.position(shape.position());
                            icon.x(icon.x() - 5.25); icon.y(icon.y() - 5.25);

                            shape.cornerRadius(15);
                            shape.stroke('red');
                            shape.fill('red');
                            shape.scale({
                              x: 2,
                              y:2
                            });
                            console.log(icon.position());
                            selfImg.mainLayer.batchDraw();
                          }
                        });

                        theImg.on('transformend',function(e){
                          console.log(this);
                          console.log(e.target);
                          console.log(this.absolutePosition());
                          console.log(this.getSelfRect());
                          console.log("cropped Status");
                          console.log(this.getAttr('croppedEnded'));
                          console.log(this.rotation());
                          console.log((currentRotation < -135 && currentRotation >=-180) || (currentRotation <=-180 && currentRotation > -225));
                          console.log((currentRotation < -45 && currentRotation >=-90) || (currentRotation <=-90 && currentRotation > -135));
                          if(this.getAttr('croppedEnded')){
                            var tmpWidth = this.width();
                            var tmpHeight = this.height();
                            var ratio = 0;
                            var tmpmaxWidthImg = 0;
                            var tmpmaxHeightImg = 0;
                            if(tmpWidth > tmpHeight){
                              ratio = (tmpWidth / tmpHeight);
                            }else{
                              ratio = (tmpHeight / tmpWidth);

                            }
                            console.log(ratio);
                            var currentRotation = this.rotation();
                            if(currentRotation!=selfImg.oldRotation){

                            if(currentRotation < 45 && currentRotation>-45){
                              this.rotation(0);
                              this.x(0);
                              this.y(0);
                              if(tmpWidth > tmpHeight){
                                if((tmpWidth*ratio)>selfImg.maxWidth){
                                  if((selfImg.maxWidth/ratio)>selfImg.maxHeight){
                                    tmpmaxHeightImg = selfImg.maxHeight;
                                    tmpmaxWidthImg = tmpmaxHeightImg*ratio;
                                  }else{
                                    tmpmaxWidthImg = selfImg.maxWidth;
                                    tmpmaxHeightImg = tmpmaxWidthImg/ratio;
                                  }
                                }else{
                                  tmpmaxHeightImg = selfImg.maxHeight;
                                  tmpmaxWidthImg = tmpmaxHeightImg*ratio;
                                }
                              }else{
                                maxHeightImg = selfImg.maxHeight;
                                tmpmaxWidthImg = maxHeightImg/ratio;
                              }
                              this.scaleX((tmpmaxWidthImg/tmpWidth));
                              this.scaleY((tmpmaxHeightImg/tmpHeight));

                            }else if((currentRotation > 45 && currentRotation <=90) || (currentRotation >=90 && currentRotation < 135)){
                              console.log("ok");
                              this.rotation(90);
                              if(tmpWidth > tmpHeight){
                                if((tmpWidth*ratio)>selfImg.maxHeight){
                                  if((selfImg.maxHeight/ratio)>selfImg.maxWidth){
                                    tmpmaxHeightImg = selfImg.maxWidth;
                                    tmpmaxWidthImg = tmpmaxHeightImg*ratio;
                                  }else{
                                    tmpmaxWidthImg = selfImg.maxHeight;
                                    tmpmaxHeightImg = tmpmaxWidthImg/ratio;
                                  }
                                }else{
                                  tmpmaxWidthImg = selfImg.maxHeight;
                                  tmpmaxHeightImg = tmpmaxWidthImg*ratio;
                                }
                              }else{
                                tmpmaxWidthImg = selfImg.maxHeight;
                                tmpmaxHeightImg = tmpmaxWidthImg*ratio;
                              }
                              this.scaleX((tmpmaxWidthImg/tmpWidth));
                              this.scaleY((tmpmaxHeightImg/tmpHeight));

                              this.x(0+(this.height()*this.scaleY()));
                              this.y(0);

                            }else if((currentRotation > 135 && currentRotation <=180)){
                              this.rotation(180);

                              if(tmpWidth > tmpHeight){
                                if((tmpWidth*ratio)>selfImg.maxWidth){
                                  if((selfImg.maxWidth/ratio)>selfImg.maxHeight){
                                    tmpmaxHeightImg = selfImg.maxHeight;
                                    tmpmaxWidthImg = tmpmaxHeightImg*ratio;
                                  }else{
                                    tmpmaxWidthImg = selfImg.maxWidth;
                                    tmpmaxHeightImg = tmpmaxWidthImg/ratio;
                                  }
                                }else{
                                  tmpmaxHeightImg = selfImg.maxHeight;
                                  tmpmaxWidthImg = tmpmaxHeightImg*ratio;
                                }
                              }else{
                                tmpmaxHeightImg = selfImg.maxHeight;
                                tmpmaxWidthImg = tmpmaxHeightImg/ratio;
                              }
                              this.scaleX((tmpmaxWidthImg/tmpWidth));
                              this.scaleY((tmpmaxHeightImg/tmpHeight));

                              this.x(0+(this.width()*this.scaleX()));
                              this.y(0+(this.height()*this.scaleY()));
                            }else if((currentRotation < -45 && currentRotation >=-90) || (currentRotation <=-90 && currentRotation > -135)){
                              console.log("ok rotation");
                              this.rotation(-90);
                              if(tmpWidth > tmpHeight){
                                if((tmpWidth*ratio)>selfImg.maxHeight){
                                  if((selfImg.maxHeight/ratio)>selfImg.maxWidth){
                                    tmpmaxHeightImg = selfImg.maxWidth;
                                    tmpmaxWidthImg = tmpmaxHeightImg*ratio;
                                  }else{
                                    tmpmaxWidthImg = selfImg.maxHeight;
                                    tmpmaxHeightImg = tmpmaxWidthImg/ratio;
                                  }
                                }else{
                                  tmpmaxWidthImg = selfImg.maxHeight;
                                  tmpmaxHeightImg = tmpmaxWidthImg*ratio;
                                }
                              }else{
                                tmpmaxWidthImg = selfImg.maxHeight;
                                tmpmaxHeightImg = tmpmaxWidthImg*ratio;
                              }
                              this.scaleX((tmpmaxWidthImg/tmpWidth));
                              this.scaleY((tmpmaxHeightImg/tmpHeight));
                              this.x(0);
                              this.y(0+(this.width()*this.scaleX()));
                            }else if((currentRotation < -135 && currentRotation >=-180)){
                              console.log("rotation ok");
                              this.rotation(-180);
                              if(tmpWidth > tmpHeight){
                                if((tmpWidth*ratio)>selfImg.maxWidth){
                                  if((selfImg.maxWidth/ratio)>selfImg.maxHeight){
                                    tmpmaxHeightImg = selfImg.maxHeight;
                                    tmpmaxWidthImg = tmpmaxHeightImg*ratio;
                                  }else{
                                    tmpmaxWidthImg = selfImg.maxWidth;
                                    tmpmaxHeightImg = tmpmaxWidthImg/ratio;
                                  }
                                }else{
                                  tmpmaxHeightImg = selfImg.maxHeight;
                                  tmpmaxWidthImg = tmpmaxHeightImg*ratio;
                                }
                              }else{
                                tmpmaxHeightImg = selfImg.maxHeight;
                                tmpmaxWidthImg = tmpmaxHeightImg/ratio;
                              }
                              this.scaleX((tmpmaxWidthImg/tmpWidth));
                              this.scaleY((tmpmaxHeightImg/tmpHeight));
                              this.x(0+(this.width()*this.scaleX()));
                              this.y(0+(this.height()*this.scaleY()));
                            }
                            selfImg.oldRotation = this.rotation();
                          }else{
                            this.rotation(e.target.rotation());
                            this.scaleX(e.target.scaleX());
                            this.scaleY(e.target.scaleY());
                            this.width(e.target.width());
                            this.height(e.target.height());
                          }

                            selfImg.transformActive = true;
                            var transformerID = '#transformer_'+selfImg.selectedLayer;
                            const transformerNode = selfImg.mainLayer.find(transformerID);
                            transformerNode[0].nodes([]);
                          }
                        });

                        theImg.setAttr('source',image[tmpIndexes].src);
                        theImg.enableCropOnDblClick();

                        var tmpLayer = new window.Konva.Layer({
                          id: 'layer_'+tmpIndexes
                        });
                        var tmpMainStage = selfImg.$refs.stage.getNode();
                        tmpMainStage.add(tmpLayer);
                        tmpLayer.add(theImg);
                        tmpLayer.add(theTransformer);
                        tmpLayer.draw();
                        theImg.cropStart();

                        if(tmpIndexes>0){
                          tmpLayer.hide();
                          selfImg.scale.push({
                            px:0,
                            cm:0
                          });
                          selfImg.defineScale.push(false);
                        }else{
                          selfImg.mainLayer = tmpLayer;
                        }
                        tmpIndexes++;
                      };
                      image[tmpCalqueIndex].src = tmpValues[j];
                      tmpCalqueIndex++;
                  }
                  console.log(self.planChantierSrc);
                  console.log(self.planChantierLabel);
                  self.$refs.planUploader.value = '';
                }).catch(() => {
                  self.planChantierSrc= [];
                });
            }
          } else {
            if(!this.loadingSaveChantier){ this.planChantierSrc= []; }
          }
        }
    },
    computed:{
      /*typeDiametre() {
        console.log("change diametre");
        console.log(this.typeDiametreTmp);
        return this.typeDiametreTmp;
      },*/
      hasPlanChantier() {
        return !!this.planChantier || this.planChantierSrc.length==0;
      },
      cssProps() {
        return {
          '--maincolor': this.$maincolor
        }
      },
      form: {
        get:function() {
          let state = {};
          if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['trace']!=undefined){
            console.log("Try retrieve old devis");
            state = this.$store.state.devis.devis['trace'];
          }else{
            state['cuisine'] = {longueur:0,coude_90:0,coude_45:0};
            state['rejet'] = {longueur:0,coude_90:0,coude_45:0};
            state['eau'] = [];
            state['sdb'] = [];
            state['wc'] = [];
            state['sdb_wc'] = [];
          }
          return state;
        },
        set:function(value){
          console.log('set form');
          this.tempSdb = value['sdb'];
          this.tempEau = value['eau'];
          this.tempWC = value['wc'];
          this.tempSdb_wc = value['sdb_wc'];
          this.tempCuisine = value['cuisine'];
          this.tempRejet = value['rejet'];
        }
      },
    },mounted(){
      /*console.log(this.selectedLayer);
      console.log(this.scale);*/
      console.log("mounted Trace");
      console.log(this.$store.state.devis.devis);
      this.networkAction = false;
      if(this.$store.state.devis.devis['chantier']!=undefined){
        console.log("GAINE");
        console.log(this.$store.state.devis.devis.chantier.gaine);
        this.defaultGaine = this.$store.state.devis.devis.chantier.gaine;
        console.log("DEFAULT GAINE CUISINE");
        console.log(this.defaultGaine);
        console.log(this.tempCuisine);
        //this.tempCuisine[0].gaine = this.defaultGaine;
        //this.tempCuisine[0].gaine.diametre= 125;

        this.rejets = this.$store.state.devis.rejets;
        for(var tet = 0 ; tet < this.$store.state.devis.gaines.length ; tet++){
          this.typeGaine.push({value:this.$store.state.devis.gaines[tet].lidic,text:this.$store.state.devis.gaines[tet].libelle});
        }
        if(this.$store.state.devis.devis['chantier'].gaine.lidic==810400){
          this.pluggit = true;
          this.defaultDiametre = 90;
        }else{
          this.pluggit = false;
          this.defaultDiametre = 80;
        }

        if(this.$store.state.devis.devis['trace']!=undefined && Object.keys(this.$store.state.devis.devis['trace']).length>0){
          //console.log("mounted trace");
          //console.log(this.$store.state.devis.devis['trace']['rejet'])
          this.tempRejet = this.$store.state.devis.devis['trace']['rejet'];

          if(this.$store.state.devis.devis['chantier'].rejet.lidic=="custom"){
            this.customRejet=true;
          }else{
            this.customRejet=false;
          }
        }else{
          this.tempRejet.longueur = this.$store.state.devis.devis['chantier'].rejet.longueur_min;
          this.tempRejet.points_fonctionnement = this.$store.state.devis.devis['chantier'].rejet.points_fonctionnement;
          if(this.$store.state.devis.devis['chantier'].rejet.lidic=="custom"){
            this.customRejet=true;

            this.tempRejet.points_fonctionnement[0] = {debit:0,pression:0};
            this.tempRejet.points_fonctionnement[1] = {debit:0,pression:0};

            //this.computeDebit();
          }else{
            this.customRejet=false;
          }
        }
        console.log(this.customRejet);
      }
      console.log(this.$store.state.devis);
      this.$nextTick(() => {
        window.scrollTo(0, 0);
        console.log("mouted");
        console.log(this.$store.state.devis);
        this.$store.dispatch('devis/setCaissons',[]);
        console.log(this.$store.state.devis.devis['chantier']['rejet'].lidic);
        document.getElementsByClassName("rejets_"+this.$store.state.devis.devis['chantier']['rejet'].lidic)[0].classList.add('active');
        if(this.customRejet){
          var menuNodeScale = document.getElementById('menuRejet');
          menuNodeScale.style.display = 'flex';
        }
        const imageBouche = new window.Image();
        imageBouche.src = '/img/base/boucheTrace.png';
        imageBouche.onload = () => {
          // set image only when it is loaded
          this.imageBouche = imageBouche;
        };

        const imageRejet = new window.Image();
        imageRejet.src = '/img/base/boucheRejet.png';
        imageRejet.onload = () => {
          // set image only when it is loaded
          console.log("ok loading REJET");
          console.log(imageRejet);
          this.imageRejet = imageRejet;
        };

        const eaBouche = new window.Image();
        eaBouche.src = '/img/base/boucheEA.png';
        eaBouche.onload = () => {
          // set image only when it is loaded
          console.log("ok loading Bouche EA");
          console.log(eaBouche);
          this.eaBouche = eaBouche;
        };

        const balayageBouche = new window.Image();
        balayageBouche.src = '/img/base/boucheBalayage.png';
        balayageBouche.onload = () => {
          // set image only when it is loaded
          this.balayageBouche = balayageBouche;
        };

        if(this.$store.state.devis && this.$store.state.devis.devis){
          let scaleLine;
          console.log(this.$refs);
          this.mainLayer = this.$refs.layer.getNode();
          this.mainStage = this.$refs.stage.getNode();
          var self2 = this;
          window.addEventListener('contextmenu', function (e) {
            e.preventDefault();
          }, false);

          /*console.log(this.mainLayer);
          console.log( window.innerHeight);
          console.log(this.$refs.collectif.clientHeight);*/
          this.configKonva.width = this.maxWidth = this.$refs.collectif.clientWidth;
          this.configKonva.height = this.maxHeight = ((window.innerHeight - this.$refs.collectif.clientHeight - 200)<750)?750:(window.innerHeight - this.$refs.collectif.clientHeight - 200);
          //console.log(this.$store.state.devis.devis['chantier']);


          if(this.$store.state.devis.devis['trace']!=undefined && Object.keys(this.$store.state.devis.devis['trace']).length>0){
            //here to add code
          let state = this.$store.state.devis.devis['trace'];
          console.log(state);
          this.traceByDraw = state['traceByDraw'];
          this.traceByInput = !state['traceByDraw'];
          this.actionchoosed = true;
          /*if(this.traceByInput){
            this.placedCuisine = true;
          }*/
          if(state['traceByDraw']){

            console.log("need to load staged saved");
            this.loadingSaveChantier = true;
            this.steps = 4;
            this.mainStage = window.Konva.Node.create(state['stageSaved'],"plan");
            var tmpLayerId = '#layer_'+this.selectedLayer;
            var tmpLayer = this.mainStage.find(tmpLayerId);
            this.mainLayer = tmpLayer[0];
            this.visualBranche = 0;
            this.mainStage.find('Image').forEach((imageNode) => {
                const src = imageNode.getAttr('source');
                console.log("load image");
                var id = imageNode.id().split("_");
                if(id[0]=="pt"){
                  var rejet = imageNode.getAttr('rejet');
                  if(rejet==true){
                    self2.mainRejet = imageNode;
                    self2.placedRejet = true;
                  }
                  self2.visualBranche++;
                  imageNode.on('dragmove',function(e){
                    var id = e.target.id().split("_");
                    console.log(id);
                    var isColonne = e.target.getAttr('colonne');
                    var mousePos = self2.mainStage.getPointerPosition();
                    var id_branche = '#branche_'+id[1]+'_'+self2.selectedLayer;
                    var branche = self2.mainLayer.find(id_branche);
                    var points = branche[0].points();
                    console.log(points);
                    var x_index = id[2]*2;
                    var y_index = (id[2]*2)+1;
                    points[x_index] = mousePos.x;
                    points[y_index] = mousePos.y;
                    branche[0].points(points);
                    if(isColonne){
                      //update colonne point
                      var id_anchorC = "#anchor_"+self2.selectedLayer;
                      var tmpAnchorC = self2.mainLayer.find(id_anchorC);
                      var posAnchor = tmpAnchorC[0].absolutePosition();
                      var moveColumn = {
                        x: (posAnchor.x - mousePos.x),
                        y: (posAnchor.y - mousePos.y)
                      };
                      for(var tmpLayerID = 0; tmpLayerID < self2.planChantierSrc.length ; tmpLayerID++){
                        if(tmpLayerID!=self2.selectedLayer){
                          var tmpLayer = self2.mainStage.find("#layer_"+tmpLayerID);
                          var Tid_anchor = "#anchor_"+tmpLayerID;
                          var TtmpAnchor = tmpLayer[0].find(Tid_anchor);
                          var TposAnchor = TtmpAnchor[0].absolutePosition();
                          var circles = tmpLayer[0].getChildren(function(node){
                             return ((node.getClassName() === 'Circle') && (node.getAttr('colonne')==true));
                          });
                          for(var k = 0 ; k < circles.length; k++){
                            var tmpIDCol = circles[k].id().split("_");
                            if(tmpIDCol[1]==id[1]){
                              var newPos = {
                                x: (TposAnchor.x-moveColumn.x),
                                y: (TposAnchor.y-moveColumn.y),
                              };
                              circles[k].position(newPos);

                              var tmpBrancheID = "#branche_"+id[1]+"_"+tmpLayerID;
                              var tmpBrancheC = tmpLayer[0].find(tmpBrancheID);
                              var tmpBranchePoints = tmpBrancheC[0].points();
                              var x_index_tmpBranche = tmpIDCol[2]*2;
                              var y_index_tmpBranche = (tmpIDCol[2]*2)+1;
                              tmpBranchePoints[x_index_tmpBranche] = newPos.x;
                              tmpBranchePoints[y_index_tmpBranche] = newPos.y;
                              tmpBrancheC[0].points(tmpBranchePoints);
                            }
                          }
                        }
                      }
                    }

                    var tmpLength = self2.computedLength(id[1]);
                    branche[0].setAttr('length',tmpLength);
                    switch(branche[0].getAttr('typeBranche')){
                      case 'cuisine':
                        self2.tempCuisine[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempCuisine[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'sdb':
                        self2.tempSdb[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempSdb[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'sdb_wc':
                        self2.tempSdb_wc[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempSdb_wc[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'wc':
                        self2.tempWC[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempWC[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'eau':
                        self2.tempEau[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempEau[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'rejet':
                        //if(self2.tempRejet.longueur==1.5){tmpLength=1.5;}
                        self2.tempRejet.longueur = tmpLength;
                      break;
                    }
                    self2.mainLayer.batchDraw();
                  });
                }else if(id[0]=="vmc"){
                  self2.visualBranche++;
                  self2.placedVMC = true;
                  self2.mainVMC = imageNode;
                  self2.mainVMC.on('dragstart', (e) => {
                     self2.oldPosX = e.target.x();
                     self2.oldPosY = e.target.y();
                  });
                  self2.mainVMC.on('dragmove', function (e) {
                    //var tmpPos = self2.mainVMC.absolutePosition();
                    //var mousePos = self3.mainStage.getPointerPosition();
                    const diffX = e.target.x() -  self2.oldPosX;
                    const diffY = e.target.y() -  self2.oldPosY;
                    /*console.log(tmpPos);
                    console.log(e.target.x());
                    console.log(e.target.y());
                    console.log(self2.visualBranche);*/
                    for(var i= 0; i<self2.visualBranche; i++){
                      //var offset = {x: tmpPos.x-mousePos.x,y:tmpPos.y-mousePos.y};
                      //console.log(self2.selectedLayer);
                      var tmpBranche = self2.mainLayer.find('#branche_'+i+"_"+self2.selectedLayer);
                      /*console.log(self2.mainLayer.find('#branche_'+i+"_1"));
                      console.log(tmpBranche);*/
                      if(tmpBranche.length>0){
                        var tmpPointsFirst = tmpBranche[0].points();
                        /*console.log(tmpPointsFirst[0]);
                        console.log(tmpPointsFirst[1]);*/
                        tmpPointsFirst[0] = tmpPointsFirst[0] + diffX;
                        tmpPointsFirst[1] = tmpPointsFirst[1] + diffY;
                        tmpBranche[0].points(tmpPointsFirst);
                        var tmpLength = self2.computedLength(i);
                        tmpBranche[0].setAttr('length',tmpLength);
                        switch(tmpBranche[0].getAttr('typeBranche')){
                          case 'cuisine':
                            self2.tempCuisine[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                          break;
                          case 'sdb':
                            self2.tempSdb[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                          break;
                          case 'sdb_wc':
                            self2.tempSdb_wc[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                          break;
                          case 'wc':
                            self2.tempWC[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                          break;
                          case 'eau':
                            console.log(self2.tempEau);
                            console.log(tmpBranche[0]);
                            self2.tempEau[tmpBranche[0].getAttr('idBranche')].longueur = tmpLength;
                          break;
                          case 'rejet':
                            self2.tempRejet.longueur = tmpLength;
                          break;
                        }
                        self2.mainLayer.batchDraw();
                      }
                    }
                    self2.oldPosX = e.target.x();
                    self2.oldPosY = e.target.y();
                  });
                  self2.mainVMC.on('click', function () {
                    const pos = self2.mainStage.getPointerPosition();
                    self2.defineBranche = true;
                    self2.defineEA = false;
                    self2.newBranche = new window.Konva.Line({
                      id:'branche_'+self2.visualBranche+'_'+self2.selectedLayer,
                      stroke: 'red',
                      strokeWidth: 8,
                      dash: [33, 10],
                      // remove line from hit graph, so we can check intersections
                      listening: true,
                      points: [pos.x, pos.y, pos.x, pos.y],
                      tension:0.2,
                      draggable:false,
                      throughColumn: false
                    });

                    self2.mainLayer.add(self2.newBranche);
                    self2.ptBranche++;
                    self2.mainVMC.moveToTop();
                  });
                }else{
                  console.log(self2.scale);
                  console.log(self2.planChantierSrc.length);
                  if(self2.planChantierSrc.length>=1){
                    self2.scale.push({
                      px:0,
                      cm:0
                    });
                  }
                  self2.planChantier.push(imageNode);
                  self2.planChantierSrc.push(src);
                  //self2.planChantierLabel.push('Plan Niveau '+self2.planChantierSrc.length);
                }
                const image = new Image();
                image.onload = () => {
                    console.log('loading image');
                    console.log(imageNode);
                    console.log(image);
                    imageNode.image(image);
                    if(imageNode.getAttr('cropElementEnd')){
                      console.log("cropElementEnd");
                      var tmpCropElement = JSON.parse(JSON.parse(imageNode.getAttr('cropElementEnd')));
                      console.log(tmpCropElement);

                      imageNode._cropElement = new window.Konva.Shape();
                      imageNode._cropElement.setAttrs(tmpCropElement['attrs']);
                      //imageNode.cropTransform(true);

                    }
                    imageNode.getLayer().batchDraw();
                }
                //console.log(src);
                //console.log(!src.includes("https://performair."));
                //if(!src.includes("https://performair.")){
                  image.crossOrigin = 'Anonymous';
                  image.setAttribute('crossorigin', 'anonymous');
                //}
                image.src = src;

            });
            console.log(this.visualBranche);
            console.log(this.planChantierSrc);
            this.mainStage.find('Circle').forEach((circleNode) => {
                /*var rejet = circleNode.getAttr('rejet');
                if(rejet==true){
                  self2.mainRejet = circleNode;
                  self2.placedRejet = true;
                  self2.visualBranche++;
                }*/
                  circleNode.on('dragmove',function(e){
                    var id = e.target.id().split("_");
                    //console.log(id);
                    var isColonne = e.target.getAttr('colonne');
                    var mousePos = self2.mainStage.getPointerPosition();
                    var id_branche = '#branche_'+id[1]+'_'+self2.selectedLayer;
                    var branche = self2.mainLayer.find(id_branche);
                    var points = branche[0].points();
                    //console.log(points);
                    var x_index = id[2]*2;
                    var y_index = (id[2]*2)+1;
                    points[x_index] = mousePos.x;
                    points[y_index] = mousePos.y;
                    branche[0].points(points);
                    if(isColonne){
                      //update colonne point
                      var id_anchorC = "#anchor_"+self2.selectedLayer;
                      var tmpAnchorC = self2.mainLayer.find(id_anchorC);
                      var posAnchor = tmpAnchorC[0].absolutePosition();
                      var moveColumn = {
                        x: (posAnchor.x - mousePos.x),
                        y: (posAnchor.y - mousePos.y)
                      };
                      for(var tmpLayerID = 0; tmpLayerID < self2.planChantierSrc.length ; tmpLayerID++){
                        if(tmpLayerID!=self2.selectedLayer){
                          var tmpLayer = self2.mainStage.find("#layer_"+tmpLayerID);
                          var Tid_anchor = "#anchor_"+tmpLayerID;
                          var TtmpAnchor = tmpLayer[0].find(Tid_anchor);
                          var TposAnchor = TtmpAnchor[0].absolutePosition();
                          var circles = tmpLayer[0].getChildren(function(node){
                             return ((node.getClassName() === 'Circle') && (node.getAttr('colonne')==true));
                          });
                          for(var k = 0 ; k < circles.length; k++){
                            var tmpIDCol = circles[k].id().split("_");
                            if(tmpIDCol[1]==id[1]){
                              var newPos = {
                                x: (TposAnchor.x-moveColumn.x),
                                y: (TposAnchor.y-moveColumn.y),
                              };
                              circles[k].position(newPos);

                              var tmpBrancheID = "#branche_"+id[1]+"_"+tmpLayerID;
                              var tmpBrancheC = tmpLayer[0].find(tmpBrancheID);
                              var tmpBranchePoints = tmpBrancheC[0].points();
                              var x_index_tmpBranche = tmpIDCol[2]*2;
                              var y_index_tmpBranche = (tmpIDCol[2]*2)+1;
                              tmpBranchePoints[x_index_tmpBranche] = newPos.x;
                              tmpBranchePoints[y_index_tmpBranche] = newPos.y;
                              tmpBrancheC[0].points(tmpBranchePoints);
                            }
                          }
                        }
                      }
                    }

                    var tmpLength = self2.computedLength(id[1]);
                    branche[0].setAttr('length',tmpLength);
                    switch(branche[0].getAttr('typeBranche')){
                      case 'cuisine':
                        self2.tempCuisine[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempCuisine[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'sdb':
                        self2.tempSdb[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempSdb[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'sdb_wc':
                        self2.tempSdb_wc[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempSdb_wc[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'wc':
                        self2.tempWC[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempWC[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'eau':
                        self2.tempEau[branche[0].getAttr('idBranche')].longueur = tmpLength;
                        self2.tempEau[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                      break;
                      case 'rejet':
                        //if(self2.tempRejet.longueur==1.5){tmpLength=1.5;}
                        self2.tempRejet.longueur = tmpLength;
                      break;
                    }
                    self2.mainLayer.batchDraw();
                  });
            });

            this.mainStage.find('RegularPolygon').forEach((triangleNode) => {
              self2.countEA++;
              triangleNode.on('click',function(e){
                if(e.evt.which==3){
                  e.target.destroy();
                }
              });
            });

            this.mainStage.find('Shape').forEach((shapeNode) => {
                  var idShape = shapeNode.id().split("_");
                  if(idShape[0]=="anchor"){
                    shapeNode.sceneFunc(function(context, shape) {
                        context.beginPath();
                        // don't need to set position of rect, Konva will handle it
                        //context.rect(0, 0, shape.getAttr('width'), shape.getAttr('height'));
                        context.arc(0,0,shape.getAttr('radius')+3,0,2*Math.PI);
                        context.closePath();
                        context.fillStrokeShape(shape);

                        context.beginPath();

                        context.arc(0,0,shape.getAttr('radius'),0,2*Math.PI);
                        context.closePath();
                        context.fillStrokeShape(shape);

                        context.beginPath();

                        context.moveTo((0-shape.getAttr('radius')-5),(shape.getAttr('radius')/2)-3);
                        context.lineTo((shape.getAttr('radius')+5),(shape.getAttr('radius')/2)-3);
                        context.closePath();
                        context.fillStrokeShape(shape);

                        context.beginPath();
                        context.moveTo((shape.getAttr('radius')/2)-3,(0-shape.getAttr('radius')-5));
                        context.lineTo((shape.getAttr('radius')/2)-3,(shape.getAttr('radius')+5));
                        context.closePath();
                        context.fillStrokeShape(shape);
                    });
                  }else if(idShape[0]=="pt"){
                    var column = shapeNode.getAttr('colonne');
                    if(column==true){
                      shapeNode.sceneFunc(function(context, shape) {
                        context.beginPath();
                        // don't need to set position of rect, Konva will handle it
                        //context.rect(0, 0, shape.getAttr('width'), shape.getAttr('height'));
                        context.arc(0,0,shape.getAttr('radius')+7,0,2*Math.PI);
                        context.closePath();
                        context.fillStrokeShape(shape);
                        context.beginPath();
                        context.arc(0,0,shape.getAttr('radius'),0,2*Math.PI);
                        context.fill();
                        context.closePath();
                        // (!) Konva specific method, it is very important
                        // it will apply are required styles
                        context.fillStrokeShape(shape);
                      });
                      shapeNode.on('dragmove',function(e){
                        //TODEBUGDRAGMOVECOLONNE
                        var id = e.target.id().split("_");
                        //console.log(id);
                        var isColonne = e.target.getAttr('colonne');
                        var mousePos = self2.mainStage.getPointerPosition();
                        var id_branche = '#branche_'+id[1]+'_'+self2.selectedLayer;
                        var branche = self2.mainLayer.find(id_branche);
                        var points = branche[0].points();
                        //console.log(points);
                        var x_index = id[2]*2;
                        var y_index = (id[2]*2)+1;
                        points[x_index] = mousePos.x;
                        points[y_index] = mousePos.y;
                        branche[0].points(points);
                        if(isColonne){
                          //update colonne point
                          var id_anchorC = "#anchor_"+self2.selectedLayer;
                          var tmpAnchorC = self2.mainLayer.find(id_anchorC);
                          var posAnchor = tmpAnchorC[0].absolutePosition();
                          var moveColumn = {
                            x: (posAnchor.x - mousePos.x),
                            y: (posAnchor.y - mousePos.y)
                          };
                          for(var tmpLayerID = 0; tmpLayerID < self2.planChantierSrc.length ; tmpLayerID++){
                            if(tmpLayerID!=self2.selectedLayer){
                              var tmpLayer = self2.mainStage.find("#layer_"+tmpLayerID);
                              var Tid_anchor = "#anchor_"+tmpLayerID;
                              var TtmpAnchor = tmpLayer[0].find(Tid_anchor);
                              var TposAnchor = TtmpAnchor[0].absolutePosition();

                              //SCALE INTO CONSIDERATION COLSCALE
                              var ThigherScaleCurrent = false;
                              if((self2.scale[self2.selectedLayer].cm/self2.scale[self2.selectedLayer].px) > (self2.scale[tmpLayerID].cm/self2.scale[tmpLayerID].px)){
                                ThigherScaleCurrent = true;
                              }

                              var TratioScale;
                              var TscaleMoveColumn;
                              if(ThigherScaleCurrent){
                                TratioScale = (self2.scale[self2.selectedLayer].cm/self2.scale[self2.selectedLayer].px)/(self2.scale[tmpLayerID].cm/self2.scale[tmpLayerID].px);
                                TscaleMoveColumn = {
                                  x: moveColumn.x * TratioScale,
                                  y: moveColumn.y * TratioScale
                                }
                              }else{
                                TratioScale = (self2.scale[tmpLayerID].cm/self2.scale[tmpLayerID].px)/(self2.scale[self2.selectedLayer].cm/self2.scale[self2.selectedLayer].px);
                                TscaleMoveColumn = {
                                  x: moveColumn.x / TratioScale,
                                  y: moveColumn.y / TratioScale
                                }
                              }

                              var circles = tmpLayer[0].getChildren(function(node){
                                 return ((node.getClassName() === 'Shape') && (node.getAttr('colonne')==true));
                              });
                              for(var k = 0 ; k < circles.length; k++){
                                var tmpIDCol = circles[k].id().split("_");
                                if(tmpIDCol[1]==id[1]){
                                  var newPos = {
                                    x: (TposAnchor.x-TscaleMoveColumn.x),
                                    y: (TposAnchor.y-TscaleMoveColumn.y),
                                  };
                                  circles[k].position(newPos);

                                  var tmpBrancheID = "#branche_"+id[1]+"_"+tmpLayerID;
                                  var tmpBrancheC = tmpLayer[0].find(tmpBrancheID);
                                  var tmpBranchePoints = tmpBrancheC[0].points();
                                  var x_index_tmpBranche = tmpIDCol[2]*2;
                                  var y_index_tmpBranche = (tmpIDCol[2]*2)+1;
                                  tmpBranchePoints[x_index_tmpBranche] = newPos.x;
                                  tmpBranchePoints[y_index_tmpBranche] = newPos.y;
                                  tmpBrancheC[0].points(tmpBranchePoints);
                                }
                              }
                            }
                          }
                        }

                        var tmpLength = self2.computedLength(id[1]);
                        branche[0].setAttr('length',tmpLength);
                        switch(branche[0].getAttr('typeBranche')){
                          case 'cuisine':
                            self2.tempCuisine[branche[0].getAttr('idBranche')].longueur = tmpLength;
                            self2.tempCuisine[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                          break;
                          case 'sdb':
                            self2.tempSdb[branche[0].getAttr('idBranche')].longueur = tmpLength;
                            self2.tempSdb[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                          break;
                          case 'sdb_wc':
                            self2.tempSdb_wc[branche[0].getAttr('idBranche')].longueur = tmpLength;
                            self2.tempSdb_wc[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                          break;
                          case 'wc':
                            self2.tempWC[branche[0].getAttr('idBranche')].longueur = tmpLength;
                            self2.tempWC[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                          break;
                          case 'eau':
                            self2.tempEau[branche[0].getAttr('idBranche')].longueur = tmpLength;
                            self2.tempEau[branche[0].getAttr('idBranche')].coude90 = self2.addDefaultCoude ? 1 : 0;
                          break;
                          case 'rejet':
                            //if(self2.tempRejet.longueur==1.5){tmpLength=1.5;}
                            self2.tempRejet.longueur = tmpLength;
                          break;
                        }
                        self2.mainLayer.batchDraw();
                      });
                    }
                  }
            });

            this.mainStage.find('Text').forEach((textNode) => {
              var id_text = textNode.id().split("_");
              if(id_text[0]=="scale" && id_text[1]=="tag"){
                console.log(textNode);
                console.log(self2.scale);
                self2.scale[id_text[2]].px = textNode.getAttr('px');
                self2.scale[id_text[2]].cm = textNode.getAttr('cm');
              }
            });
            console.log("Data loaded All");
            console.log(this.mainStage);
            this.visualBranche = state['visualBranche'];
            console.log(state['selectedLayer']);
            console.log(this.selectedLayer);
            console.log(this.scale);
            if(this.selectedLayer!=state['selectedLayer']){
              console.log("update Layer Selected");
              var tmpLayerIdLoad = '#layer_'+this.selectedLayer;
              var tmpLayerLoad = this.mainStage.find(tmpLayerIdLoad);
              tmpLayerLoad[0].hide();
              this.selectedLayer = state['selectedLayer'];
              tmpLayerIdLoad = '#layer_'+this.selectedLayer;
              tmpLayerLoad = this.mainStage.find(tmpLayerIdLoad);
              tmpLayerLoad[0].show();
              this.mainLayer = tmpLayerLoad[0];
            }

            var allLayers = this.mainStage.getLayers();
            this.planChantierSrc.length = allLayers.length-1;
            this.planChantierLabel = state['layersLabel'];
            this.firstStep = false;
            this.disableRejet = false;
            this.placedVMC = true,
            this.placedRejet = true;
            this.disableValidateTrace = false;
            this.scale = state['scale'];
          }
          this.configTrace['cuisine'] = state['cuisine'].length>0;
          this.configTrace['eau'] = state['eau'].length>0;
          this.configTrace['sdb_wc'] = state['sdb_wc'].length>0;
          this.configTrace['sdb'] = state['sdb'].length>0;
          this.configTrace['wc'] = state['wc'].length>0;

          this.tempCuisine = state['cuisine'];
          if(this.tempCuisine.length>0){
            this.placedCuisine = true;
          }
          console.log(state['rejet']);
          this.tempRejet = state['rejet'];
          console.log(this.tempRejet);
          var self = this;
          setTimeout(function(){
            console.log(self.tempSdb);
            console.log(state['sdb']);
            self.tempSdb = state['sdb'];
            self.tempEau= state['eau'];
            self.tempSdb_wc= state['sdb_wc'];
            self.tempWC = state['wc'];

            self.computeDebit();
          },150);
          let validate = this.tempCuisine.longueur!=0;
          console.log(validate);
          this.validate = validate;
        }else{
          this.computeDebit();
        }
        this.mainStage.on('dblclick', function () {
          if(self2.defineBranche){
            if(!self2.newBranche){
              return;
            }
            const pos = self2.mainStage.getPointerPosition();
            let pts = self2.newBranche.points();
            pts.push(pos.x, pos.y);
            self2.newBranche.points(pts);
            self2.defineBranche = false;

          }
        });
        this.mainStage.on('click', function (e) {
          console.log(e.target.id());
          var tmpID = e.target.id().split("_");
          if(e.target.id()=="vmc"){
            return;
          }else if(tmpID[0]=="column"){
            self2.newBranche.setAttr('throughColumn',true);
            if(self2.planChantierSrc.length==2){
              var tmpLayerIdT = '#layer_'+self2.selectedLayer;
              var tmpLayerT = self2.mainStage.find(tmpLayerIdT);
              tmpLayerT[0].hide();
              if(self2.selectedLayer==1){
                self2.selectedLayer--;
              }else{
                self2.selectedLayer++;
              }
              tmpLayerIdT = '#layer_'+self2.selectedLayer;
              tmpLayerT = self2.mainStage.find(tmpLayerIdT);
              tmpLayerT[0].show();
              self2.mainLayer = tmpLayerT[0];

              //create branche on selected Layer
              //get column on selected layer and use his pos
              var id_ColumnB = "#column_"+self2.selectedLayer;
              var tmpColumnB = self2.mainLayer.find(id_ColumnB);
              var posColumnB = tmpColumnB[0].absolutePosition();
              self2.newBranche = new window.Konva.Line({
                id:'branche_'+self2.visualBranche+'_'+self2.selectedLayer,
                stroke: 'red',
                strokeWidth: 8,
                dash: [33, 10],
                // remove line from hit graph, so we can check intersections
                listening: true,
                points: [posColumnB.x, posColumnB.y, posColumnB.x, posColumnB.y],
                tension:0.2,
                draggable:false,
                throughColumn: true
              });

              self2.mainLayer.add(self2.newBranche);
              self2.ptBranche++;
            }else{
              console.log("ok");
            }
          }else{
          console.log("click");
          console.log(self2.defineBranche);
          console.log(self2.defineScale[self2.selectedLayer]);
          console.log(self2.firstPointDefine);
          if(self2.defineScale[self2.selectedLayer]){
            if(self2.firstPointDefine){
              self2.defineScale[self2.selectedLayer] = false;
              var menuNodeScale = document.getElementById('menuScale');
              menuNodeScale.style.display = 'flex';
              var scaleLineTMPID = '#scale_'+self2.selectedLayer;
              var scaleLineTmp = self2.mainLayer.find(scaleLineTMPID);
              var pointsScale = scaleLineTmp[0].points();
              self2.scale[self2.selectedLayer].px = Math.sqrt(Math.pow((pointsScale[2]-pointsScale[0]),2)+Math.pow((pointsScale[3]-pointsScale[1]),2));
            }else{
              console.log("define line");
              const pos = self2.mainStage.getPointerPosition();
              scaleLine = new window.Konva.Arrow({
                id: 'scale_'+self2.selectedLayer,
                stroke: 'red',
                pointerAtBeginning: true,
                pointerAtEnding: true,
                fill :'red',
                // remove line from hit graph, so we can check intersections
                listening: true,
                draggable:true,
                points: [pos.x, pos.y, pos.x, pos.y]
              });
              scaleLine.on('dragstart',function(e){
                var tmpPoints = e.target.points();
                self2.moveIndexArrow = -1;
                if(Math.abs(e.evt.layerX - tmpPoints[0]) < 15 && Math.abs(e.evt.layerY - tmpPoints[1]) < 15){
                  self2.moveIndexArrow = 0;
                }else if(Math.abs(e.evt.layerX - tmpPoints[2]) < 15 && Math.abs(e.evt.layerY - tmpPoints[3]) < 15 ){
                  self2.moveIndexArrow = 1;
                }
                self2.poseIndexArrow.x = e.target.x();
                self2.poseIndexArrow.y = e.target.y();
              });
              scaleLine.on('dragmove',function(e){
                if(self2.moveIndexArrow>-1){
                  var tmpPoints = e.target.points();
                  tmpPoints[(2*self2.moveIndexArrow)] = e.evt.layerX;
                  tmpPoints[((2*self2.moveIndexArrow)+1)] = e.evt.layerY;
                  e.target.x(self2.poseIndexArrow.x);
                  e.target.y(self2.poseIndexArrow.y);
                  e.target.points(tmpPoints);
                  self2.scale[self2.selectedLayer].px = Math.sqrt(Math.pow((tmpPoints[2]-tmpPoints[0]),2)+Math.pow((tmpPoints[3]-tmpPoints[1]),2));

                  var tmpScaleTag = self2.mainStage.find('#scale_tag_'+self2.selectedLayer);
                  if(tmpScaleTag[0]){
                    tmpScaleTag[0].x(tmpPoints[0]+((tmpPoints[2]-tmpPoints[0])/2));
                    tmpScaleTag[0].y(tmpPoints[1]+((tmpPoints[3]-tmpPoints[1])/2));
                  }
                }
              });
              console.log(self2.mainLayer);
              self2.mainLayer.add(scaleLine);
              self2.firstPointDefine = true;
            }
            console.log(self2.firstPointDefine);
          }else if(self2.defineEA){
            const pos = self2.mainStage.getPointerPosition();
            self2.countEA++;
            console.log(self2.eaBouche);
            var tmpX = pos.x-(6*self2.scale[self2.selectedLayer].px)/(self2.scale[self2.selectedLayer].cm*100);
            var tmpY = pos.y-(6*self2.scale[self2.selectedLayer].px)/(self2.scale[self2.selectedLayer].cm*100);
            var tmpHeight = (12*self2.scale[self2.selectedLayer].px)/(self2.scale[self2.selectedLayer].cm*100);
            var tmpWidth = (12*self2.scale[self2.selectedLayer].px)/(self2.scale[self2.selectedLayer].cm*100);
            if(tmpHeight < 50){
                tmpX = pos.x - 15;
                tmpY = pos.y - 15;
                tmpHeight = 30;
                tmpWidth = 30;
            }

            var groupEA = new window.Konva.Image({
              x: tmpX,
              y: tmpY,
              height: tmpHeight,
              width: tmpWidth,
              /*fill: 'black',
              stroke: 'black',
              strokeWidth: 6,*/
              image: self2.eaBouche,
              draggable:true,
              listening:true,
              id:'ea_'+self2.selectedLayer+'_'+self2.countEA
            });
            groupEA.setAttr('source',self2.eaBouche.src);
            groupEA.on('click',function(e){
              if(e.evt.which==3){
                  e.target.destroy();
              }
            });

            self2.mainLayer.add(groupEA);
          }else if(self2.defineBalyage){
            const pos = self2.mainStage.getPointerPosition();
            self2.countBalyage++;
            console.log(self2.balayageBouche);
            var tmp_x = pos.x-(6*self2.scale[self2.selectedLayer].px)/(self2.scale[self2.selectedLayer].cm*100);
            var tmp_y = pos.y-(6*self2.scale[self2.selectedLayer].px)/(self2.scale[self2.selectedLayer].cm*100);
            var tmp_height = (12*self2.scale[self2.selectedLayer].px)/(self2.scale[self2.selectedLayer].cm*100);
            var tmp_width = (12*self2.scale[self2.selectedLayer].px)/(self2.scale[self2.selectedLayer].cm*100);
            console.log(tmp_height);
            if(tmp_height < 50){

                tmp_x = pos.x - 15;
                tmp_y = pos.y - 15;
                tmp_height = 30;
                tmp_width = 30;
            }
            var groupBal = new window.Konva.Image({
              x: tmp_x,
              y: tmp_y,
              height: tmp_height,
              width: tmp_width,
              /*fill: 'black',
              stroke: 'black',
              strokeWidth: 6,*/
              image: self2.balayageBouche,
              draggable:true,
              listening:true,
              id:'ea_'+self2.selectedLayer+'_'+self2.countBalyage
            });
            groupBal.setAttr('source',self2.balayageBouche.src);
            groupBal.on('click',function(e){
              if(e.evt.which==3){
                  e.target.destroy();
              }
            });

            self2.mainLayer.add(groupBal);
          }else if(self2.defineBranche){
            self2.defineEA = false;
            console.log("new branche");

            console.log(self2.newBranche);
            if(!self2.newBranche){
              return;
            }

            const pos = self2.mainStage.getPointerPosition();
            if(e.evt.which==3){
              self2.tmpPos = pos;
              self2.defineBranche = false;
              var menuNodeCreateZ = document.getElementById('menuCreateBranche');
              menuNodeCreateZ.style.display = 'flex';
            }else{
              let pts = self2.newBranche.points();
              pts.push(pos.x, pos.y);
              self2.newBranche.points(pts);
              var pt = new window.Konva.Circle({
                x: pos.x,
                y: pos.y,
                radius: 6,
                id:'pt_'+self2.visualBranche+'_'+self2.ptBranche,
                fill: 'red',
                stroke: 'white',
                strokeWidth: 2,
                listening: true,
                draggable:true
              });

              self2.ptBranche++;
              pt.on('dragmove',function(e){
                var id = e.target.id().split("_");
                var mousePos = self2.mainStage.getPointerPosition();
                var id_branche = '#branche_'+id[1]+'_'+self2.selectedLayer;
                var branche = self2.mainLayer.find(id_branche);
                var points = branche[0].points();
                var x_index = id[2]*2;
                var y_index = (id[2]*2)+1;
                points[x_index] = mousePos.x;
                points[y_index] = mousePos.y;
                branche[0].points(points);
                var tmpLength = self2.computedLength(id[1]);
                branche[0].setAttr('length',tmpLength);
                switch(branche[0].getAttr('typeBranche')){
                  case 'cuisine':
                    self2.tempCuisine[branche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'sdb':
                    self2.tempSdb[branche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'sdb_wc':
                    self2.tempSdb_wc[branche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'wc':
                    self2.tempWC[branche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                  case 'eau':
                    self2.tempEau[branche[0].getAttr('idBranche')].longueur = tmpLength;
                  break;
                }
                self2.mainLayer.batchDraw();
              });
              self2.mainLayer.add(pt);
            }
            /*if(e.evt.which==3){
            }*/

            console.log(self2.newBranche.points());
          }else if(e.evt.which==3){
            var target_id = e.target.id().split("_");
            if(target_id[0]=="pt" || target_id[0]=="branche"){
              console.log(target_id);
              self2.selectedBranche = target_id[1];
              var id_branche = '#branche_'+self2.selectedBranche+'_'+self2.selectedLayer;
              var branche = self2.mainLayer.find(id_branche);
              self2.rejetBranche = false;
              if(branche[0].getAttr('typeBranche')=="rejet"){
                self2.rejetBranche = true;
              }

              var menuNode = document.getElementById('menuEditBranche');
              menuNode.style.display = 'flex';
              if(self2.customRejet){
                var menuNodeCustomRejet = document.getElementById('menuRejet');
                menuNodeCustomRejet.style.display = 'flex';
              }
            }

          }else if(self2.defineColumn){
            // chaque colonne est indépendante et propre à la branche
            const pos = self2.mainStage.getPointerPosition();
            var column = new window.Konva.Circle({
              x: pos.x,
              y: pos.y,
              radius: 7,
              id:'column_'+self2.selectedLayer,
              fill: 'silver',
              stroke: 'black',
              strokeWidth: 2,
              listening: true,
              draggable:true
            });
            self2.mainLayer.add(column);

            var id_anchorC = "#anchor_"+self2.selectedLayer;
            var tmpAnchorC = self2.mainLayer.find(id_anchorC);
            var posAnchor = tmpAnchorC[0].absolutePosition();
            console.log(pos);
            console.log(posAnchor);
            var moveColumn = {
              x: (posAnchor.x - pos.x),
              y: (posAnchor.y - pos.y)
            };
            console.log(moveColumn);
            for(var tmpLayerID = 0; tmpLayerID < self2.planChantierSrc.length ; tmpLayerID++){
              if(tmpLayerID!=self2.selectedLayer){
                console.log("ok");
                var tmpLayer = self2.mainStage.find("#layer_"+tmpLayerID);
                var Tid_anchor = "#anchor_"+tmpLayerID;
                var TtmpAnchor = tmpLayer[0].find(Tid_anchor);
                var TposAnchor = TtmpAnchor[0].absolutePosition();
                console.log(TtmpAnchor[0]);
                console.log(TposAnchor);
                var tmpColmun = new window.Konva.Circle({
                  x: (TposAnchor.x-moveColumn.x),
                  y: (TposAnchor.y-moveColumn.y),
                  radius: 15,
                  id:'column_'+tmpLayerID,
                  fill: 'silver',
                  stroke: 'black',
                  strokeWidth: 2,
                  listening: true,
                  draggable:true
                });
                tmpLayer[0].add(tmpColmun);
              }
            }

          }else if(self2.defineAnchor[self2.selectedLayer]){
            const pos = self2.mainStage.getPointerPosition();
            var id_anchor = "#anchor_"+self2.selectedLayer;
            var tmpAnchor = self2.mainLayer.find(id_anchor);
            if(tmpAnchor.length==0){
              var anchor = new window.Konva.Shape({
                x: pos.x,
                y: pos.y,
                radius: 5,
                id:'anchor_'+self2.selectedLayer,
                stroke: 'black',
                strokeWidth: 2,
                listening: true,
                draggable:true,
                anchor: true,
                sceneFunc: function (context, shape) {
                  context.beginPath();
                  // don't need to set position of rect, Konva will handle it
                  //context.rect(0, 0, shape.getAttr('width'), shape.getAttr('height'));
                  context.arc(0,0,shape.getAttr('radius')+3,0,2*Math.PI);
                  context.closePath();
                  context.fillStrokeShape(shape);

                  context.beginPath();

                  context.arc(0,0,shape.getAttr('radius'),0,2*Math.PI);
                  context.closePath();
                  context.fillStrokeShape(shape);

                  context.beginPath();

                  context.moveTo((0-shape.getAttr('radius')-5),(shape.getAttr('radius')/2)-3);
                  context.lineTo((shape.getAttr('radius')+5),(shape.getAttr('radius')/2)-3);
                  context.closePath();
                  context.fillStrokeShape(shape);

                  context.beginPath();
                  context.moveTo((shape.getAttr('radius')/2)-3,(0-shape.getAttr('radius')-5));
                  context.lineTo((shape.getAttr('radius')/2)-3,(shape.getAttr('radius')+5));
                  context.closePath();
                  context.fillStrokeShape(shape);
                }
              });
              self2.mainLayer.add(anchor);
              /*if(self2.defineAnchor.length>1){
                console.log("switch layer anchor");
                console.log(self2.defineAnchor);
                var indexAnchroUndefined = self2.defineAnchor.findIndex(anchor => anchor == false);
                console.log(indexAnchroUndefined);
                if(indexAnchroUndefined>=0){
                  var tmpLayerAnchorId = '#layer_'+self2.selectedLayer;
                  var tmpAnchorLayer = self2.mainStage.find(tmpLayerAnchorId);
                  tmpAnchorLayer[0].hide();

                  tmpLayerAnchorId = '#layer_'+indexAnchroUndefined;
                  tmpAnchorLayer = self2.mainStage.find(tmpLayerAnchorId);
                  tmpAnchorLayer[0].show();
                  self2.selectedLayer = indexAnchroUndefined;
                  self2.mainLayer = tmpAnchorLayer[0];
                  self2.defineAnchor[self2.selectedLayer] = true;
                }
              }*/
            }
          }
          }
        });

        this.mainStage.on('mousemove', () => {
          if(self2.defineScale[self2.selectedLayer] && self2.firstPointDefine){
            console.log("move Line");
            if (!scaleLine) {
              return;
            }
            const pos = self2.mainStage.getPointerPosition();
            const points = scaleLine.points().slice();
            if(Math.abs(points[2]-pos.x)>Math.abs(points[3]-pos.y)){
              points[2] = pos.x;
            }else{
              points[3] = pos.y;
            }


            scaleLine.points(points);
            self2.mainLayer.batchDraw();
          }else if(self2.defineBranche){
            if(!self2.newBranche){
              return;
            }
            const pos = self2.mainStage.getPointerPosition();
            const points = self2.newBranche.points();
            points[points.length-2] = pos.x;
            points[points.length-1] = pos.y;
            self2.newBranche.points(points);
            self2.mainLayer.batchDraw();
          }
        });
        }
      });

    }
}
</script>

<style lang="scss">
#collectif{
  img.abm_picto {
      width: 60px;
      height: auto;
      position: absolute;
      right: 1em;
      top: 0em;
  }
  .trace-item{
    @media (max-width: 576px) {
        margin: 2em 0px;
    }
    background: linear-gradient(-122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
    padding: 2em 2.5em;
    text-align: center;
    margin: 2em 30px;
    width: 100%;
    max-width: 400px;
    border-radius: 2em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    h5{
      color:black;
      font-size:2em;
      font-weight: bold;
      margin-bottom:0.5em;
    }
    h6{
      img{
        background-color: transparent;
        border-radius:0;
        box-shadow:none;
        padding:0;
        width:54px;
        margin-right: 1em;
      }

      span {
        z-index:12;
        position: relative;
      }
    }
    p{ margin-top: auto; margin-bottom: auto;}
    .button-next {
      background: linear-gradient(-70deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
      width:100px;
      height:100px;
      margin:0 auto;
      border-radius: 50%;
      margin-top: 1em;
      img{
        transform: rotate(180deg);
        padding: 0.8em 2.2em;
      }
    }
  }
  //padding: 3em 3em 0;
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;

  h3{
    margin-bottom:2em;
  }
  @media (max-width: 576px) {
      padding:3em 0 0;
  }
  .row { margin-bottom: 2em;
    @media (max-width: 576px) {
        margin-bottom: 0;
    }
  }

  .main_content {
    .col-12 { padding: 0 15px; }
    .btn-group{
      button{
        &.prev{
          border-radius: 50%;
          border: 0;
          width: 100px;
          height: 100px;
          margin-left: 1em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
        }

        &.next{
          border-radius: 50%;
          border: 0;
          width: 100px;
          height: 100px;
          margin-left: 1em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);

          img{
            margin-left: 0.5em;
            transform: rotate(180deg);
          }
        }
      }
    }
    .menuTrace {
      display: none;
      .options-content{
        background: linear-gradient(-122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
        padding: 2em 0.5em;
        text-align: center;
        @media (max-width: 576px) {
            margin: 2em 0px;
        }
        margin: 2em 30px;
        width: 100%;
        max-width: 300px;
        border-radius: 1em;
        cursor: pointer;

        &.disabled{
            opacity: 0.5;
        }

        &.hide_options {
          display:none;
        }
        &.active{
            border: 2px solid var(--maincolor);
        }
        h6{
          color:#333;
          font-weight: bold;
          font-size:1.5em;

          strong{color: var(--maincolor);}
        }

        img{
          width:70%;
          box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
          border-radius: 1em;
          padding: 0.5em 1em;
          background-color: white;
          margin: 1em 0;
        }
      }
      button {
        width: 100%;
        background-color: white;
        border: none;
        margin: 0;
        padding: 10px 5px;
        font-size:0.8em;
        &:hover {
          background-color: lightgray;
        }
        small{
          //font-size: 50%;
        }
      }
    }
    .col-12 div.content-block {
      &.selected_block{
        border: 2px solid var(--maincolor);
      }
      padding: 1em 1em 1.5em 1em;
      .content-form{

        &.switchBox{
          padding-top: 1em;
          padding-bottom: 1.5em;
          .switch-label{
            font-size: 1.5em;
            text-align:left;
            padding-left:15px;
            &.active{
              color:var(--maincolor);
            }
          }
        }
        text-align: center;

        .custom-control-label {
          color: #666;
          text-align: left;
          font-size: 1.5em;
        }

        .custom-switch  {
          //padding-left: 3.25rem;
          .custom-control-label::before {
              //left: -2.25rem;
              //width: 1.75rem;
              pointer-events: all;
              border-radius: 1.5rem;
              padding: 0.9rem 1.75rem;
              background: transparent !important;
              box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
              border: 0px;
              top: 0.3rem
          }

          .custom-control-label::after {
              //top: calc(.25rem + 2px);
              //left: calc(-2.25rem + 2px);
              width: calc(1.9rem - 10px);
              height: calc(1.9rem - 10px);
              background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              border-radius: 2.5rem;
              top: 0.55rem;
              left:calc(-2.25rem + 5px);
              transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          .custom-control-input:checked~.custom-control-label::before, .custom-control-input:disabled:checked~.custom-control-label::before{
            background: linear-gradient(-122deg, rgba(51,51,51,1) 0%, rgba(102,102,102,1) 100%) !important;
            box-shadow: none;
          }

          .custom-control-input:checked~.custom-control-label::after {
              background: white;
              transform: translateX(1.6rem);
          }
        }

        &.checkBox{
          margin-bottom:4em;
          margin-top: 5em;
          border-radius:1em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          button{
            box-shadow: none;
            background: transparent !important;
            border:0px;
            color: #666;
            margin:1em 1em;
            padding:1em;
            text-transform: none;
            width: 81%;

            &.active{
              color: var(--maincolor);
              box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            }
          }
        }

        &.inputBox{
          padding-top: 1.5em;
          input{
            background-color: transparent;
            border-radius: 2em;
            border:0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            color: #000;
            padding: 1.5em 1em;
            &::placeholder {
              color:#999;
            }
          }
        }
        &.spinner{
          text-align: left;
          &.twolines{
            padding-top: 3.2em;
            padding-bottom: 2.5em;
          }
          .row { margin-bottom: 0em;}
          .b-form-spinbutton{
          &.small-version{
            .btn.btn-sm {
              &:first-of-type{
                margin-right:0;
              }
              &:last-of-type{
                margin-left:0;
              }
            }
            output{
              padding:0;
            }
          }
          margin-bottom:1em;
          border-radius:2em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          .btn.btn-sm {
            padding:0;
            color: #666;
          }
        }
        }
        &.roundButton {
          &.labelline{
              .carre.active,.rectangle.active,.forme_en_l.active { color: var(--maincolor); }
              .forme_c{
                &.active span{background: var(--maincolor);}
                span{
                  width:20px;
                  height:20px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_r {
                &.active span{background: var(--maincolor);}
                span{
                  width:15px;
                  height:28px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_l {
                &.active span{
                  background: var(--maincolor);
                  span.head_button {
                    background: var(--maincolor);
                  }
                }
                span{
                  width:11px;
                  height:33px;
                  background: #666;
                  display:block;
                  position: relative;
                  margin: 0 25%;
                    span.head_button{
                    width:20px;
                    height:11px;
                    background: #666;
                    display:block;
                    position: absolute;
                    bottom:0;
                    left:0;
                  }
                }
              }
          }
          &.oneline{
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top:0.5em;
            button{
              font-size:0.8em;
            }
          }
          button{
            width: 60px;
            height: 60px;
            padding: 7px 6px;
            border-radius: 50%;
            font-size: 1em;
            text-align: center;
            color: #666666;
            background: linear-gradient(122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
            border: 0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
            &.active{
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              color: var(--maincolor);
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
</style>
