<template>
  <div id="individuel" fluid="xl" :style="cssProps">
    <b-row style="margin-bottom:3em;">
      <b-col md="6" lg="5" xl="4">
        <div class="header inner-shadow-box">
          <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Performair" id="performair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
        </div>
      </b-col>
      <b-col md="6" lg="7" xl="8">
        <b-row>
          <b-col>
            <div class="arianne">
              <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <span class='selected'>Chantier</span> <span class='decoration'>></span> Tracé <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
            </div>
          </b-col>
        </b-row><b-row>
          <b-col>
            <h3>Sélectionnez la configuration de votre chantier</h3>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="main_content">
          <b-row>
            <b-col cols="12" md="6" lg="5" xl="4">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_typo}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Type</span><br> de Maison</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/type-icon.png" style="max-width: 110px;" />
                  </b-col>
                </div>
                <div class="content-form roundButton oneline row">
                  <b-col class="my-auto">
                    <b-button @click.prevent="setTypologie(1)" class="typologie T1" id="typoT1">T1
                      <b-tooltip target="typoT1" triggers="hover">
                        Logement avec <b>1 pièce principale</b> séjour/ chambre
                      </b-tooltip>
                    </b-button>
                    <b-button @click.prevent="setTypologie(2)" class="typologie T2" id="typoT2">T2
                      <b-tooltip target="typoT2" triggers="hover">
                      Logement avec <b>2 pièces principales</b> 1 séjour + 1 chambre
                      </b-tooltip>
                    </b-button>
                    <b-button @click.prevent="setTypologie(3)" class="typologie T3" id="typoT3">T3
                      <b-tooltip target="typoT3" triggers="hover">
                        Logement avec <b>3 pièces principales</b> 1 séjour + 2 chambres
                      </b-tooltip>
                    </b-button>
                    <b-button @click.prevent="setTypologie(4)" class="typologie T4" id="typoT4">T4
                      <b-tooltip target="typoT4" triggers="hover">
                        Logement avec <b>4 pièces principales</b> 1 séjour + 3 chambres
                      </b-tooltip>
                    </b-button>
                    <b-button @click.prevent="setTypologie(5)" class="typologie T5" id="typoT5">T5
                      <b-tooltip target="typoT5" triggers="hover">
                        Logement avec <b>5 pièces principales</b> 1 séjour + 4 chambres
                      </b-tooltip>
                    </b-button>
                    <b-button @click.prevent="setTypologie(6)" class="typologie T6" id="typoT6">T6
                      <b-tooltip target="typoT6" triggers="hover">
                        Logement avec <b>6 pièces principales</b> 1 séjour + 5 chambres
                      </b-tooltip>
                    </b-button>
                    <b-button @click.prevent="setTypologie(7)" class="typologie T7" id="typoT7">T7
                      <b-tooltip target="typoT7" triggers="hover">
                        Logement avec <b>7 pièces principales</b> 1 séjour + 6 chambres
                      </b-tooltip>
                    </b-button>
                  </b-col>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="5" xl="4">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_hygro}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Type</span><br> de Système</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/categ-icon.png" style="max-width: 110px;" />
                  </b-col>
                </div>
                <div class="content-form roundButton oneline row">
                  <b-col class="my-auto">
                  <b-button @click.prevent="setHygro('HA')" class="HygroA Hygro" id="HygroHA">Hygro A
                    <b-tooltip target="HygroHA" triggers="hover">
                      Entrées d’air autoréglables et bouches d’extraction hygroréglables
                    </b-tooltip>
                  </b-button>
                  <b-button @click.prevent="setHygro('HB')" class="active HygroB Hygro" id="HygroHB">Hygro B
                    <b-tooltip target="HygroHB" triggers="hover">
                      Entrées d’air et bouches d’extraction hygroréglables
                    </b-tooltip>
                  </b-button>
                  </b-col>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="options-item" v-if="gaines.length>0">
              <h5><span>TYPE DE</span> CONDUIT</h5>
              <b-row>
                <b-col cols="12" md="6" lg="3" style="padding: 2em 0.5em;margin: 0em 30px;width: 100%;max-width: 400px;">
                  <div class="content-block shadow-box" :class="{ 'missing' : empty_fuite}">
                    <div class='row header-form align-items-center'>
                      <b-col cols="8">
                        <p><span>Classe</span><br>d’étanchéité du réseau</p>
                      </b-col>
                      <b-col cols="4">
                        <b-img src="../assets/vmc-icon.png" />
                      </b-col>
                    </div>
                    <div class="content-form roundButton oneline row">
                      <b-col class="my-auto" style="padding-bottom: 1em;padding-top: 1em;">
                        <b-button @click.prevent="setFuites(12)" class="active fuites F10" id="FuiteF10">fuite de 12%
                          <b-tooltip target="FuiteF10" triggers="hover">
                            Valeur par défaut de dimensionnement du Cahier des Prescriptions Technique Ventilation Hygroreglable
                          </b-tooltip>
                        </b-button>
                        <b-button @click.prevent="setFuites(5)" class="fuites F5" id="FuiteF5">fuite de 5%
                          <b-tooltip target="FuiteF5" triggers="hover">
                            Système justifiant d’un avis technique ou d’un rapport d’essai de laboratoire accrédité
                          </b-tooltip>
                        </b-button>
                        <b-button @click.prevent="setFuites(0)" class="fuites F0" id="FuiteF0">fuite de 0%
                          <b-tooltip target="FuiteF0" triggers="hover">
                            Système justifiant d’un avis technique ou d’un rapport d’essai de laboratoire accrédité
                          </b-tooltip>
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </b-col>
                <b-col class="options-content gaine" :class="gaineStatus(gaine.lidic)" cols="12" md="6" lg="3" v-for="(gaine,index2) in gaines" :key="index2" @click="selectGaine(gaine,index2,$event)">
                  <h6>{{gaine.libelle}}</h6>
                  <b-img :src="`/img/${gaine.lidic}.jpg`" fluid class='vignette'/>
                </b-col>
              </b-row>
          </div>
          <div class="options-item" v-if="rejets.length>0">
              <h5><span>TYPE DE</span> REJET</h5>
              <b-row>
                <b-col class="options-content rejet" :class="'rejets_'+rejet.lidic" cols="12" md="6" lg="3" v-for="(rejet,index) in rejets" :key="index" @click="selectRejet(rejet,index,$event)">
                  <h6><strong>{{firstWord(rejet.libelle)}}</strong><br> {{restWord(rejet.libelle)}}</h6>
                  <b-img :src="`/img/${rejet.lidic}.jpg`" fluid class='vignette'/>
                </b-col>
                <b-col class="options-content rejet" :class="'rejets_custom'" cols="12" md="6" lg="3" @click="selectRejet({lidic:'custom',longueur_min:0,diametre:160,points_fonctionnement:[]},-1,$event)">
                  <h6><strong>Autre</strong><br> Rejet Conforme CPT</h6>
                  <b-img :src="`/img/874478.jpg`" fluid class='vignette'/>
                </b-col>
              </b-row>
          </div>
          <b-row class="footer align-items-between no-gutters">
            <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
              <b-button @click="back($event);">
                <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
              </b-button>
            </b-col>
            <b-col md="8" cols="12" order="1" order-md="2" class="content">
              <!-- inner-shadow-box -->
              <!--<div class="content-block" style="background:none;">
                <p>Au dela de 7 pièces, de 200m², ou de 2 niveaux habitables, <strong>contactez votre conseiller</strong>.</p>
                <p>Pour une ventilation double flux, S&amp;P France préconise l'installation en local technique chauffé / isolé.</p>
              </div>-->
            </b-col>
            <b-col md="2" cols="6" order="3" order-md="3" style="text-align:right;">
              <b-button type="submit" class="next" :disabled="!validate">
                <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
              </b-button>
            </b-col>
          </b-row>
    </b-form>
    <b-card class="mt-3" v-if="debugging" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'Chantier',
  data() {
      return {
        debugging:false,
        validate: false,
        typologie: [{ text: 'Typologie', value: null },
        { text: 'T1', value: 1 },{ text: 'T2', value: 2 },{ text: 'T3', value: 3 },
        { text: 'T4', value: 4 },{ text: 'T5', value: 5 },{ text: 'T6', value: 6 },{ text: 'T7', value: 7 }],
        show: true,
        items: [
          {
            text: 'Accueil',
            href: '/'
          },
          {
            text: 'Chantier',
            active: true
          }
        ],
        empty_typo:false,
        empty_hygro:false,
        empty_conduit:false,
        empty_fuite: false,
        gaines:[],
        rejets:[],
      }
    },
    methods: {
      gaineStatus(lidic){
        var res = 'gaines_'+lidic;

        /*if(lidic=="810400"){
          res+=" disabled";
        }*/

        return res;
      },
      firstWord(libelle){
        return libelle.split(" ")[0];
      },
      restWord(libelle){
        var tmp = libelle.split(" ");
        tmp.shift();
        return tmp.join(" ");
      },
      back(evt){
        evt.preventDefault();
        this.$store.dispatch('devis/setChantier',{});
        this.$router.push('/');
      },
      selectGaine(gaine,index2,ev){
        console.log(gaine);
        //quand clique pluggit retire 1 coude90 sur chaque branche et min coude 90 = 0
        if(gaine.lidic==810400){
          var newTrace = this.$store.state.devis.devis.trace;
          console.log(newTrace);
          if(newTrace!=undefined){
            for(var p = 0; p < newTrace.cuisine.length; p++){
              if(newTrace.cuisine[p].coude_90>0) newTrace.cuisine[p].coude_90--;
            }
            for(var l = 0; l < newTrace.sdb_wc.length; l++){
              if(newTrace.sdb_wc[l].coude_90>0) newTrace.sdb_wc[l].coude_90--;
            }
            for(var m = 0; m < newTrace.sdb.length; m++){
              if(newTrace.sdb[m].coude_90>0) newTrace.sdb[m].coude_90--;
            }
            for(var o = 0; o < newTrace.wc.length; o++){
              if(newTrace.wc[o].coude_90>0) newTrace.wc[o].coude_90--;
            }
            for(var k = 0; k < newTrace.eau.length; k++){
              if(newTrace.eau[k].coude_90>0) newTrace.eau[k].coude_90--;
            }
            this.$store.dispatch('devis/updateCoude90',newTrace);

            console.log(this.$store.state.devis.devis.trace);
          }
        }
        let res = this.form;
        if(ev.currentTarget.classList.contains('active')){
          res.gaine = null;
          ev.currentTarget.classList.toggle("active");
        }else{
          let x = document.getElementsByClassName("gaine");
          for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
          ev.currentTarget.classList.toggle("active");
          res.gaine = gaine;
        }
        console.log(res.gaine);
        this.form = res;
      },
      selectRejet(rejet,index2,ev){
        console.log(rejet);
        let res = this.form;
        if(rejet.lidic=="custom" && this.$store.state.devis.devis.trace!=undefined){
          rejet.points_fonctionnement = this.$store.state.devis.devis.chantier.rejet.points_fonctionnement;
        }
        if(ev.currentTarget.classList.contains('active')){
          res.rejet = null;
          ev.currentTarget.classList.toggle("active");
        }else{
          let x = document.getElementsByClassName("rejet");
          for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
          ev.currentTarget.classList.toggle("active");
          res.rejet = rejet;
        }
        this.form = res;
      },
      getImgUrl(img) {
        return require(this.$css_path+img);
      },
      onSubmit(evt) {
        evt.preventDefault();
        if(this.validate){
          console.log("before validate");
          console.log(this.$store.state.devis);
          this.$store.dispatch('devis/setRejets',this.rejets);
          this.$store.dispatch('devis/setChantier',this.form);
          const baseURI = this.$api_url+'debit';
          this.$http.post(baseURI,{'chantier':this.$store.state.devis.devis.chantier}).then((result) => {
            if(result){
              console.log(result.data);
              this.$store.dispatch('devis/setDebits',result.data);
              console.log(this.$store.state.devis.devis.debit);
              console.log(this.$store.state.devis);
              //this.$router.push('caissons');
              this.$router.push('trace');
            }
          });
        }else{
          this.empty_typo = (this.form.typologie==null);
          this.empty_hygro = (this.form.hygro==null);
        }
      },
      onReset(evt) {
        evt.preventDefault();
        this.$router.go(-1);
      },
      setHygro(hygro){
        let x = document.getElementsByClassName("Hygro");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(hygro){
          case 'HA':
            document.getElementsByClassName("HygroA")[0].classList.add('active');
          break;
          case 'HB':
            document.getElementsByClassName("HygroB")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.hygro = hygro;
        this.form = res;
      },
      setTypologie(typologie){
        let x = document.getElementsByClassName("typologie");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(typologie){
          case 1:
            document.getElementsByClassName("T1")[0].classList.add('active');
          break;
          case 2:
            document.getElementsByClassName("T2")[0].classList.add('active');
          break;
          case 3:
            document.getElementsByClassName("T3")[0].classList.add('active');
          break;
          case 4:
            document.getElementsByClassName("T4")[0].classList.add('active');
          break;
          case 5:
            document.getElementsByClassName("T5")[0].classList.add('active');
          break;
          case 6:
            document.getElementsByClassName("T6")[0].classList.add('active');
          break;
          case 7:
            document.getElementsByClassName("T7")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.typologie = typologie;
        this.form = res;
      },
      setFuites(fuite){
        let x = document.getElementsByClassName("fuites");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(fuite){
          case 12:
            document.getElementsByClassName("F10")[0].classList.add('active');
          break;
          case 5:
            document.getElementsByClassName("F5")[0].classList.add('active');
          break;
          case 0:
            document.getElementsByClassName("F0")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.fuite = fuite;
        this.form = res;
      },
      setConduit(conduit){
        let x = document.getElementsByClassName("conduit");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(conduit){
          case 'R':
            document.getElementsByClassName("conduit_r")[0].classList.add('active');
          break;
          case 'F':
            document.getElementsByClassName("conduit_f")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.conduit = conduit;
        this.form = res;
      },
      setSdb(){
        console.log(this.form);
        let res = this.form;
        this.form = res;
      },
    },
    computed:{
      cssProps() {
          return {
            '--maincolor': this.$maincolor
          }
        },
      form: {
        get:function() {
          let state = {};
          if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['chantier']!=undefined){
            state = this.$store.state.devis.devis['chantier'];
            console.log(state);
          }else{
            state['typologie'] = null;
            state['hygro'] = 'HB';
            state['conduit'] = null;
            state['gaine'] = null;
            state['rejet'] = null;
            state['fuite'] = 12 ;

            console.log("GET");
            console.log(state);
          }
          return state;
        },
        set:function(){
          console.log(this.form);
          let validate = this.form.typologie!=null && this.form.hygro!=null && this.form.gaine!=null && this.form.rejet!=null;
          this.validate = validate;
        }
      },
    },mounted(){
      window.scrollTo(0, 0);
      console.log(this.$css_path);
      console.log("mouted");
      //this.$store.dispatch('devis/setTrace',{});

      const baseURI = this.$api_url+'chantier';
      this.$http.get(baseURI)
      .then((result) => {
        console.log(result);
        this.gaines = result.data.gaines;
        this.$store.dispatch('devis/setGaines',this.gaines);
        this.rejets = result.data.rejets;
        if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['chantier']!=undefined){
          let state = this.$store.state.devis.devis['chantier'];
          console.log(this.$store.state.devis);
          if(state!=undefined){
            this.validate = this.form.typologie!=null && this.form.hygro!=null && this.form.gaine!=null && this.form.rejet!=null;
          }

          switch(state['hygro']){
            case 'HA':
              document.getElementsByClassName("HygroA")[0].classList.add('active');
            break;
            case 'HB':
              document.getElementsByClassName("HygroB")[0].classList.add('active');
            break;
          }

          switch(state['conduit']){
            case 'R':
              document.getElementsByClassName("conduit_r")[0].classList.add('active');
            break;
            case 'F':
              document.getElementsByClassName("conduit_f")[0].classList.add('active');
            break;
          }

          switch(state['typologie']){
            case 1:
              document.getElementsByClassName("T1")[0].classList.add('active');
            break;
            case 2:
              document.getElementsByClassName("T2")[0].classList.add('active');
            break;
            case 3:
              document.getElementsByClassName("T3")[0].classList.add('active');
            break;
            case 4:
              document.getElementsByClassName("T4")[0].classList.add('active');
            break;
            case 5:
              document.getElementsByClassName("T5")[0].classList.add('active');
            break;
            case 6:
              document.getElementsByClassName("T6")[0].classList.add('active');
            break;
            case 7:
              document.getElementsByClassName("T7")[0].classList.add('active');
            break;
          }

          setTimeout(function(){
            if(state['gaine']!=null){
               document.getElementsByClassName("gaines_"+state['gaine'].lidic)[0].classList.add('active')
            }

            if(state['rejet']!=null){
               document.getElementsByClassName("rejets_"+state['rejet'].lidic)[0].classList.add('active')
            }

            let x = document.getElementsByClassName("fuites");
            for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
            console.log(state['fuite']);
            switch(state['fuite']){
              case 12:
                document.getElementsByClassName("F10")[0].classList.add('active');
              break;
              case 5:
                document.getElementsByClassName("F5")[0].classList.add('active');
              break;
              case 0:
                document.getElementsByClassName("F0")[0].classList.add('active');
              break;
            }
          },100);

        }
      });
    }
}
</script>

<style lang="scss">
.header {
  border-radius:2em;
  padding-right:1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  #performair-logo { max-width: 370px; width:85%}
  #logo-business { max-width: 75px; width:15%; margin-top: -10px;}
}

.arianne{
  margin: 1em 0;
  p{
    color: #999999;
    a,.decoration { color: black}
    .selected{
      color: var(--maincolor);
    }
  }
}

.headerToDraw{
  .content{
    border-radius: 2em;
    color: #666666;
    @media (max-width: 576px) {
        padding: 1em;
    }
    padding: 1em 3em 0.5em 3em;
    line-height: 1.2em;
    text-align: center;
    font-size: 1em;
  }
  button.prev, button.next{
    background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
    border-radius: 50%;
    border: 0;
    width: 7vh;
    height: 7vh;
    img{
      height:5vh;
    }
    &.next{
      background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);

      img{
        margin-left: 0.5em;
        transform: rotate(180deg);
      }
    }
  }
}

.footer{
  .content{
    border-radius: 2em;
    color: #666666;
    @media (max-width: 576px) {
        padding: 1em 1em 0.5em 1em;
    }
    padding: 1em 3em 0.5em 3em;
    line-height: 1.2em;
    text-align: center;
    font-size: 1.5em;
  }
  button{
    background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
    border-radius: 50%;
    border: 0;
    width: 13vh;
    height: 13vh;
    img{
      height:9vh;
    }
    &.next{
      background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);

      img{
        margin-left: 0.5em;
        transform: rotate(180deg);
      }
    }
  }
}

.main_content {
  .options-item{
    margin-top:2em;
    h5{
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
      span { color: var(--maincolor); }
    }

    .options-content{
      background: linear-gradient(-122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
      padding: 2em 0.5em;
      text-align: center;
      @media (max-width: 576px) {
          margin: 2em 0px;
      }
      margin: 2em 30px;
      width: 100%;
      max-width: 400px;
      border-radius: 1em;
      cursor: pointer;

      &.disabled{
          opacity: 0.5;
      }

      &.hide_options {
        display:none;
      }
      &.active{
          border: 2px solid var(--maincolor);
      }
      h6{
        color:#333;
        font-weight: bold;
        font-size:1.5em;

        strong{color: var(--maincolor);}
      }

      img{
        width:70%;
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
        border-radius: 1em;
        padding: 0.5em 1em;
        background-color: white;
        margin: 1em 0;
      }
    }
  }
  .col-12 {
    padding: 0 32px;
    @media (max-width: 1300px) {
        padding:0 10px;
        margin-bottom: 1.2em;
    }

    @media (max-width: 576px) {
        padding:0;
        margin-bottom: 1.2em;
    }

    div.content-block {
      &.selected_block{
        border: 2px solid var(--maincolor);
      }
      &.missing{ border:1px solid red; }
      background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
      border-radius: 1em;
      padding: 1em 2em 1.5em 2em;
      height:100%;
      p.colorText{
        color: var(--maincolor);
        font-size: 1em;
        font-weight:normal;
      }
      .header-form {
        position: relative;
        p {
          &.colorText{
            color: var(--maincolor);
            font-size: 1em;
            font-weight:normal;
          }
          font-size: 1.5em;
          line-height: 1em;
          font-weight: bold;
          width:65%;
          text-transform: uppercase;
          color: #666666;
          text-align: left;
          z-index: 10;
          position: relative;
          span {
            color: var(--maincolor);
          }
        }
        .col-4{ text-align:right;}
        img {
          width:100%;
        }
      }
    }
  }
}
#individuel{
  //padding: 3em 3em 0;
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;

  h3{
    margin-bottom:2em;
  }
  @media (max-width: 767.98px) {
    .main_content {
      .col-12 div.content-block {
        .header-form img { width: 50%; }
      }
    }
  }
  @media (max-width: 576px) {
      padding:3em 0 0;
  }
  .main_content > .row { margin-bottom: 2em;
    @media (max-width: 576px) {
        margin-bottom: 0;
    }
  }



  .main_content {
    .col-12 div.content-block {
      .content-form{

        &.switchBox{
          margin-top: 1em;
          .switch-label{
            font-size: 1.5em;
            text-align:left;
            padding-left:15px;
            &.active{
              color:var(--maincolor);
            }
          }
        }
        text-align: center;

        .custom-control-label {
          color: #666;
          text-align: left;
          font-size: 1.5em;
        }

        .custom-switch  {
          //padding-left: 3.25rem;
          .custom-control-label::before {
              //left: -2.25rem;
              //width: 1.75rem;
              pointer-events: all;
              border-radius: 1.5rem;
              padding: 0.9rem 1.75rem;
              background: transparent !important;
              box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
              border: 0px;
              top: 0.3rem
          }

          .custom-control-label::after {
              //top: calc(.25rem + 2px);
              //left: calc(-2.25rem + 2px);
              width: calc(1.9rem - 10px);
              height: calc(1.9rem - 10px);
              background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              border-radius: 2.5rem;
              top: 0.55rem;
              left:calc(-2.25rem + 5px);
              transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          .custom-control-input:checked~.custom-control-label::before, .custom-control-input:disabled:checked~.custom-control-label::before{
            background: linear-gradient(-122deg, rgba(51,51,51,1) 0%, rgba(102,102,102,1) 100%) !important;
            box-shadow: none;
          }

          .custom-control-input:checked~.custom-control-label::after {
              background: white;
              transform: translateX(1.6rem);
          }
        }

        &.checkBox{
          margin-top: 2em;
          border-radius:1em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          button{
            box-shadow: none;
            background: transparent !important;
            border:0px;
            color: #666;
            margin:1em 1em;
            padding:1em;
            text-transform: none;
            width: 81%;

            &.active{
              color: var(--maincolor);
              box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            }
          }
        }
        &.spinner{
          text-align: left;
          .row { margin-bottom: 0em;}
          .b-form-spinbutton{
            &.small-version{
              .btn.btn-sm {
                &:first-of-type{
                  margin-right:0;
                }
                &:last-of-type{
                  margin-left:0;
                }
              }
              output{
                padding:0;
              }
            }
          margin-bottom:1em;
          border-radius:2em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          .btn.btn-sm {
            padding:0;
            color: #666;
          }
        }
        }

        &.roundButton {
          &.labelline{
              .carre.active,.rectangle.active,.forme_en_l.active { color: var(--maincolor); }
              .forme_c{
                &.active span{background: var(--maincolor);}
                span{
                  width:20px;
                  height:20px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_r {
                &.active span{background: var(--maincolor);}
                span{
                  width:15px;
                  height:28px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_l {
                &.active span{
                  background: var(--maincolor);
                  span.head_button {
                    background: var(--maincolor);
                  }
                }
                span{
                  width:11px;
                  height:33px;
                  background: #666;
                  display:block;
                  position: relative;
                  margin: 0 25%;
                    span.head_button{
                    width:20px;
                    height:11px;
                    background: #666;
                    display:block;
                    position: absolute;
                    bottom:0;
                    left:0;
                  }
                }
              }
          }
          &.oneline{
            height: 55%;
            align-items: center;
            display: flex;
            justify-content: center;
            button{
              font-size:0.8em;
            }
          }
          button{
            width: 60px;
            height: 60px;
            padding: 7px 6px;
            border-radius: 50%;
            font-size: 1em;
            text-align: center;
            color: #666666;
            background: linear-gradient(122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
            border: 0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
            &.active{
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              color: var(--maincolor);
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
</style>
