export const DevisModule = {
  namespaced: true,
  state: {
    devis: null
  },
  mutations: {
    SET_DEVIS(state, devis){
      state.devis = devis;
    },
    SET_TYPE(state, type){
      if(state.devis == null) state.devis = {};
      state.devis['type'] = type;
    },
    SET_CHANTIER(state, chantier){
      if(state.devis == null) state.devis = {};
      state.devis['chantier'] = chantier;
    },
    DEFAULT_CHANTIER(state, chantier){
      console.log("default chantier");
      console.log(state);
      state.devis = {};
      state.devis['chantier'] = chantier;
    },
    UPDATE_REJET(state, rejet){
      state.devis['chantier'].rejet = rejet;
    },
    SET_REJETS(state, rejets){
      if(state.rejets == null) state.rejets = [];
      state.rejets = rejets;
    },
    SET_DEBIT(state, debit){
      console.log("state debit"); console.log(state.devis);
      if(state.devis == null) state.devis = {};
      state.devis['debit'] = debit;
      state.devis['debit']['index'] = 0;
    },
    UPDATE_DEBIT(state,debit_index){
      if(state.devis == null) state.devis = {};
      if(state.devis['debit'] == null) state.devis['debit'] = {};
      state.devis['debit']['index'] = debit_index;
    },
    SET_TRACE(state, trace){
      if(state.devis == null) state.devis = {};
      state.devis['trace'] = trace;
    },
    SET_OPTIONS(state, options){
      if(state.devis == null) state.devis = {};
      state.devis['options'] = options;
    },
    SET_INFORMATIONS(state, informations){
      if(state.devis == null) state.devis = {};
      state.devis['informations'] = informations;
    },
    SET_CAISSONS(state, caissons){
      if(state.devis == null) state.devis = {};
      state.devis['caissons'] = caissons;
    },
    SET_BRANCHE(state, branche){
      if(state.devis == null) state.devis = {};
      state.devis['branche'] = branche;
    },
    SET_ALLBRANCHE(state, all_branche){
      if(state.devis == null) state.devis = {};
      state.devis['allbranche'] = all_branche;
    },
    SAVE_DEVIS(state,devis_content){
      if(state.devis == null) state.devis = {};
      state.devis['content'] = devis_content;
    },
    IMPORT_DATA(state, data){
      if(state.rejets == null) state.rejets = [];
      if(state.devis == null) state.devis = {};
      state.devis = data.devis;
      state.rejets = data.rejets;
    },
    SET_GAINES(state, gaines){
      state.gaines = gaines;
    },
    UPDATE_COUDE90(state,trace){
      if(state.devis['trace']!=null){
        state.devis['trace'].cuisine = trace.cuisine;
        state.devis['trace'].sdb_wc = trace.sdb_wc;
        state.devis['trace'].sdb = trace.sdb;
        state.devis['trace'].wc = trace.wc;
        state.devis['trace'].eau = trace.eau;
      }
    }
  },
  actions: {
    updateCoude90({commit},trace){
      commit('UPDATE_COUDE90',trace);
    },
    setDevis({commit},devis){
      commit('SET_DEVIS',devis);
    },
    setChantier({commit},chantier){
      commit('SET_CHANTIER',chantier);
    },
    defaultChantier({commit},chantier){
      commit('DEFAULT_CHANTIER',chantier);
    },
    setRejets({commit},rejets){
      commit('SET_REJETS',rejets);
    },
    updateRejet({commit},rejet){
      commit('UPDATE_REJET',rejet);
    },
    setDebits({commit},debit){
      commit('SET_DEBIT',debit);
    },
    updateDebits({commit},debit){
      commit('UPDATE_DEBIT',debit);
    },
    setTrace({commit},trace){
      commit('SET_TRACE',trace);
    },
    setInformations({commit},informations){
      commit('SET_INFORMATIONS',informations);
    },
    setCaissons({commit},caissons){
      commit('SET_CAISSONS',caissons);
    },
    setBranche({commit},caissons){
      commit('SET_BRANCHE',caissons);
    },
    setAllBranche({commit},caissons){
      commit('SET_ALLBRANCHE',caissons);
    },
    importData({commit},data){
      commit('IMPORT_DATA',data);
    },
    setGaines({commit},gaines){
      commit('SET_GAINES',gaines);
    },
    getDevis({commit}){
      let devis_content = {
        id: 'devis_1',
        products: [
          {id:854096,quantite:2,name:'ECA 45',designation:"Bouche d'inspiration",prix_unite:1800},
          {id:857657,quantite:2,name:'ECA RA 45',designation:'Bouche Salle de Bain WC',prix_unite:150},
          {id:604141,quantite:1,name:'DECO² K',designation:'Caisson VMC Aspiration EcoWatts',prix_unite:2650},
          {id:813881,quantite:5,name:'GPI 80/25',designation:'Gaine PVC Dure 25MM ',prix_unite:10.58},
          {id:810415,quantite:1,name:'ACR 50/80',designation:'Rallonge Acoustique',prix_unite:150},
        ]
      };
      commit('SAVE_DEVIS',devis_content);
    }
  },
  getters: {
    getDevis(state){
      return state.devis;
    }
  }
}
