import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Chantier from '../views/Chantier.vue'
import Trace from '../views/Trace.vue'
import Caisson from '../views/Caisson.vue'
import Options from '../views/Options.vue'
import Devis from '../views/Devis.vue'
import Informations from '../views/Informations.vue'
import Reporting from '../views/Reporting.vue'
import Administration from '../views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/chantier',
    name: 'Chantier',
    component: Chantier
  },
  {
    path: '/trace',
    name: 'Trace',
    component: Trace
  },
  {
    path: '/caissons',
    name: 'Caisson',
    component: Caisson
  },
  {
    path: '/options',
    name: 'Options',
    component: Options
  },
  {
    path: '/devis',
    name: 'Devis',
    component: Devis
  },
  {
    path: '/informations',
    name: 'Informations',
    component: Informations
  },
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting
  },
  {
    path: '/admin',
    name: 'Administration',
    component: Administration
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
