<template>
  <div id="collectif_admin">
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>Bouches / Débits</span><br></p>
          </div>
          <div class="content-form roundButton">
          <b-row v-if="editDebit">
            <b-col cols="12">
              <b-row>{{labelDebit}}</b-row>
            </b-col>
          </b-row>
          <b-row v-if="editBouche">
            <b-col cols="12">
              <b-row>
                <b-col cols="2">
                  <label for="type_vmc_input">Type Flux: </label>
                </b-col>
                <b-col>
                  <b-form-select v-model="currentBouche.id" :options="type_vmc" id="type_vmc_input" disabled></b-form-select>
                </b-col>
              </b-row>
              <b-tabs pills>
                <b-tab title="Débit Min.">
                  <b-tabs pills>
                    <b-tab v-for="index in 7" :key="index" :title='"T"+index'>
                      <div class="content-block shadow-box" v-if="editParams">
                        <div class='header-form'>
                          <p><span>Configuration T{{index}}</span><br></p>
                        </div>
                        <div class="content-form roundButton">
                          <b-row>
                            <b-col cols="1">
                                <label for="sdb_puissance">SDB: </label>
                            </b-col>
                            <b-col cols="2">
                                <b-form-spinbutton inline id="sdb_puissance" v-model="currentDebit.sdb" min="0" max="300" size="sm" class="small-version"></b-form-spinbutton>
                            </b-col>
                            <b-col cols="1">
                                <label for="sdbwc_puissance">SDB/WC: </label>
                            </b-col>
                            <b-col cols="2">
                                <b-form-spinbutton inline id="sdbwc_puissance" v-model="currentDebit.sdbwc" min="0" max="300" size="sm" class="small-version"></b-form-spinbutton>
                            </b-col>
                            <b-col cols="1">
                                <label for="wc_puissance">WC: </label>
                            </b-col>
                            <b-col cols="2">
                                <b-form-spinbutton inline id="wc_puissance" v-model="currentDebit.wc" min="0" max="300" size="sm" class="small-version"></b-form-spinbutton>
                            </b-col>
                            <b-col cols="1">
                                <label for="eau_puissance">Salle d'eau: </label>
                            </b-col>
                            <b-col cols="2">
                                <b-form-spinbutton inline id="eau_puissance" v-model="currentDebit.eau" min="0" max="300" size="sm" class="small-version"></b-form-spinbutton>
                            </b-col>
                          </b-row>
                          <b-row>
                            <h4>Débit</h4>
                          </b-row>
                              <b-row>
                                <b-col cols="4">
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="sdb1_debit">SDB1: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_sdb1" inline id="sdb1_debit" type="number" v-model="currentDebit.debit.sdb1.lidic" @blur="updateDebitLidic('sdb1')" @input="updateLidicProduct('sdb1')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_sdb1">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['sdb1']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="sdb2_debit">SDB2: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_sdb2" inline id="sdb2_debit" v-model="currentDebit.debit.sdb2.lidic" @blur="updateDebitLidic('sdb2')" @input="updateLidicProduct('sdb2')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_sdb2">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['sdb2']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="sdbsupp_debit">SDB Supp: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_sdbsupp" inline id="sdbsupp_debit" v-model="currentDebit.debit.sdbsupp.lidic" @blur="updateDebitLidic('sdbsupp')" @input="updateLidicProduct('sdbsupp')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_sdbsupp">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['sdbsupp']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="4">
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="sdbwc1_debit">SDB/WC1: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_sdbwc1" inline id="sdbwc1_debit" v-model="currentDebit.debit.sdbwc1.lidic" @blur="updateDebitLidic('sdbwc1')" @input="updateLidicProduct('sdbwc1')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_sdbwc1">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['sdbwc1']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="sdbwc2_debit">SDB/WC2: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_sdbwc2" inline id="sdbwc2_debit" v-model="currentDebit.debit.sdbwc2.lidic" @blur="updateDebitLidic('sdbwc2')" @input="updateLidicProduct('sdbwc2')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_sdbwc2">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['sdbwc2']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="sdbwcsupp_debit">SDB/WC Supp: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_sdbwcsupp" inline id="sdbwcsupp_debit" v-model="currentDebit.debit.sdbwcsupp.lidic" @blur="updateDebitLidic('sdbwcsupp')" @input="updateLidicProduct('sdbwcsupp')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_sdbwcsupp">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['sdbwcsupp']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="4">
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="wc_debit">WC: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_wc" inline id="wc_debit" v-model="currentDebit.debit.wc.lidic" @blur="updateDebitLidic('wc')" @input="updateLidicProduct('wc')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_wc">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['wc']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="wcsupp_debit">WC Supp: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_wcsupp" inline id="wcsupp_debit" v-model="currentDebit.debit.wcsupp.lidic" @blur="updateDebitLidic('wcsupp')" @input="updateLidicProduct('wcsupp')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_wcsupp">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['wcsupp']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="eau_debit">Salle d'eau: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_eau" inline id="eau_debit" v-model="currentDebit.debit.eau.lidic" @blur="updateDebitLidic('eau')" @input="updateLidicProduct('eau')"  size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_eau">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['eau']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col cols="4">
                                        <label for="cuisine_debit">Cuisine: </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input list="my-list-id_cuisine" inline id="cuisine_debit" v-model="currentDebit.debit.cuisine.lidic" @blur="updateDebitLidic('cuisine')" @input="updateLidicProduct('cuisine')" size="sm" class="small-version"></b-form-input>
                                        <datalist id="my-list-id_cuisine">
                                            <option :value="prod.value" v-for="(prod,index) in lidic_product['cuisine']" :key="index">{{ prod.text }}</option>
                                        </datalist>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                          <b-row>
                            <b-col>
                              <b-button size="sm" target="_blank" @click="close('debit',index)" class="mr-1">Fermer</b-button>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <b-row align-h="end">
                        <b-col cols="4">
                          <b-button size="sm" target="_blank" @click="editItem(-1,'debit',index)" class="mr-0">Ajouter Configuration Débits</b-button>
                        </b-col>
                      </b-row>
                      <b-table responsive striped hover :items="currentBouche.debit[index]" :fields="fields_debit">
                        <template #cell(actions)="row">
                          <b-button size="sm" target="_blank" @click="editItem(row.item,'debit',index)" class="mr-1">Editer</b-button>
                          <b-button size="sm" target="_blank" @click="deleteItem(row.index,'debit',index)" class="mr-1">Supprimer</b-button>
                        </template>
                      </b-table>
                    </b-tab>
                  </b-tabs>
                </b-tab>
                  </b-tabs>
              <b-row>
                <b-col cols="2">
                  <b-button size="sm" target="_blank" @click="cancel('bouche')" class="mr-1">Annuler</b-button>
                </b-col>
                <b-col cols="8"></b-col>
                <b-col cols="2">
                  <b-button size="sm" target="_blank" @click="saveItem('bouche')" class="mr-1">Enregistrer</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row  v-if="!editBouche">
            <b-col cols="12">
              <!--<b-row align-h="end">
                <b-col cols="3">
                  <b-button size="sm" target="_blank" @click="editItem(-1,'bouche')" class="">Ajouter un type de Bouche</b-button>
                </b-col>
              </b-row>-->
              <b-table responsive striped hover :items="bouches" :fields="fields_bouches">
                <template #cell(actions)="row">
                  <b-button size="sm" target="_blank" @click="editItem(row.item,'bouche',index)" class="mr-1">Editer</b-button>
                  <!--<b-button size="sm" target="_blank" @click="deleteItem(row.item,'bouche',index)" class="mr-1">Supprimer</b-button>-->
                </template>
              </b-table>
          </b-col>
          </b-row>
        </div>
      </div>
      </b-col>
    </b-row>
    <datalist id="my-list-pt-collectif">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'BoucheAdminBackup',
  props: {
    bouches: Array,
    product: Array,
  },
  data() {
      return {
        dismissCountDown: 0,
        tmpBouche:{},
        currentVMC:{},
        currentBouche:{},
        currentVMCLidic: -1,
        currentDebit:{},
        currentParams:{},
        currentConfig:{},
        currentCalcul:{},
        currentCalculParams: {},
        typologie_calcul: [1,2,3,4,5,6,7],
        newConfig: 'Nouveau Label',
        labelDebit: "",
        currentGaine:{},
        currentGaineLidic: null,
        tmpGaineLidic: null,
        currentConfigLabel: null,
        tmpConfigLabel: null,
        lidic_options:[],
        lidic_params:[],
        lidic_product:[],
        newConfigParam: '',
        editBouche:false,
        editCalcul: false,
        editCalculParams: false,
        editOptions:false,
        editParams:false,
        editConfig:false,
        editGaines:false,
        editDebit:false,
        filter: null,
        perPage: 20,
        currentPage: 1,
        bouches_debits:[{label:'débits min',type:'debit_min'},{label:'débits max',type:'debit_max'}],
        type_vmc:[{
          value:'HA',
          text:'Hygro A'
        },
        {
          value:'HB',
          text:'Hygro B'
        },{
          value:'A',
          text:'Auto'
        }],
        fields_debit:[
          {
            key: 'sdb',
            label: 'SDB',
            sortable: true
          },
          {
            key: 'sdbwc',
            label: 'SDB/WC',
            sortable: true
          },
          {
            key: 'wc',
            label: 'WC',
            sortable: true
          },
          {
            key: 'eau',
            label: "Salle d'eau",
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_bouches_debits:[
          {
            key: 'label',
            label: 'Débits',
            sortable: true,
          },
          { key: 'actionsBouche', label: 'Actions' }],
        fields_calcul:[
          {
            key: 'id',
            label: 'Type',
            sortable: true,
            formatter: (value) => {
              let res = '';
              switch(value){
                case 'A':
                  res = 'Auto';
                break;
                case 'HA':
                  res = 'Hydro A';
                break;
                case 'HB':
                  res = 'Hydro B';
                break;
              }
              return res;
            }
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_config: [
          {
            key: 'label',
            label: 'label',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_params: [
          {
            key: 'type',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_gaines: [
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_bouches: [
          {
            key: 'id',
            label: 'Type',
            sortable: true,
            formatter: value => {
              var returnValue = "Auto";
              switch(value){
                case 'HA':
                  returnValue = "Hygro A";
                break;
                case 'HB':
                  returnValue = "Hygro B";
                break;
              }
              return returnValue
            }
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields: [
          { key: 'vignette', label: '',tdClass: 'product-vignette' },
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          {
            key: 'nom',
            label: 'Nom',
            sortable: true
          },
          {
            key: 'categorie',
            label: 'Type',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        typologie:[{
          value:1,
          text:'T1'
        },{
          value:2,
          text:'T2'
        },{
          value:3,
          text:'T3'
        },{
          value:4,
          text:'T4'
        },{
          value:5,
          text:'T5'
        },{
          value:6,
          text:'T6'
        },{
          value:7,
          text:'T7'
        }],
        reglementation:[{
          value:'E',
          text:'Existant'
        },
        {
          value:'N',
          text:'Construction Neuve'
        },{
          value:'E/N',
          text:'Existant ou Construction Neuve'
        }],
        options_type:[{value:'',text:'Classique'},{value:'checkbox',text:'Oui/Non'}],
        param_type:[{value:'bouche',text:'Bouche Commande'},
                    {value:'piece_technique','text':'Pièces Techniques'},
                   {value:'entree','text':"Bouche d'entrée"}],
        param_vmc_type:[{value:'piquetage',text:'Piquetage'},{value:'barre_horizontal',text:'Barre Horizontal'},{value:'barre_vertical',text:'Barre Vertical'}],
        flux:[{value:'Simple Flux',text:'Simple Flux'},{value:'Double Flux',text:'Double Flux'}],
        status:[{value:'valid',text:'Validé'},{value:'error',text:'Erreur Paramétrage'},{value:'waiting',text:'En attente de validation'}],
      tension:[{value:'M',text:'MonoPhase'},{value:'T',text:'Triphase'}],
      aspiration:[{value:'9',text:'90°'},{value:'L',text:'Ligne'},{value:'D',text:'Double'}],
      debit:['350','500','800','1000','1400','2000','2700','2900','3700','5000','6900','8000','9000'],
      }
  },
  methods: {
    onFiltered(filteredItems){
      console.log("filter");
      console.log(this.collectif.vmc.length);
      console.log(filteredItems.length);
      this.total_rows_vmc = filteredItems.length;
      this.currentPage = 1;
    },
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    updateConfig(){
      switch(this.currentOptions.content.type){
        case 'checkbox':
          this.$delete(this.currentOptions.content,'options');
        break;
        default:
          this.$delete(this.currentOptions.content,'items');
          this.$delete(this.currentOptions,'type');
        break;
      }
    },
    updateParams(type){
      switch(type){
        case 'vmc':
          switch(this.currentParams.type){
            case 'entree':
              this.$set(this.currentParams,'rallonge',{});
              this.$set(this.currentParams,'entree',{});
              this.$delete(this.currentParams,'sdb');
              this.$delete(this.currentParams,'sdb_wc');
              this.$delete(this.currentParams,'wc');
              this.$delete(this.currentParams,'eau');
              this.$delete(this.currentParams,'offered');
            break;
            case 'piece_technique':
              this.$delete(this.currentParams,'rallonge');
              this.$delete(this.currentParams,'entree');
              this.$set(this.currentParams,'sdb',{items:[],offered:0});
              this.$set(this.currentParams,'sdb_wc',{items:[],offered:0});
              this.$set(this.currentParams,'wc',{items:[],offered:0});
              this.$set(this.currentParams,'eau',{items:[],offered:0});
              this.$set(this.currentParams,'offered',0);
            break;
            default:
              this.$delete(this.currentParams,'sdb');
              this.$delete(this.currentParams,'sdb_wc');
              this.$delete(this.currentParams,'wc');
              this.$delete(this.currentParams,'eau');
              this.$delete(this.currentParams,'offered');
              this.$delete(this.currentParams,'rallonge');
              this.$delete(this.currentParams,'entree');
            break;
          }
        break;
        case 'calcul':
          switch(this.currentParams.type){
            case 'entree':
              this.$set(this.currentCalculParams,'rallonge',{});
              this.$set(this.currentCalculParams,'entree',{});
              this.$delete(this.currentCalculParams,'sdb');
              this.$delete(this.currentCalculParams,'sdb_wc');
              this.$delete(this.currentCalculParams,'wc');
              this.$delete(this.currentCalculParams,'eau');
              this.$delete(this.currentCalculParams,'offered');
            break;
            case 'piece_technique':
              this.$delete(this.currentCalculParams,'rallonge');
              this.$delete(this.currentCalculParams,'entree');
              this.$set(this.currentCalculParams,'sdb',{items:[],offered:0});
              this.$set(this.currentCalculParams,'sdb_wc',{items:[],offered:0});
              this.$set(this.currentCalculParams,'wc',{items:[],offered:0});
              this.$set(this.currentCalculParams,'eau',{items:[],offered:0});
              this.$set(this.currentCalculParams,'offered',0);
            break;
            default:
              this.$delete(this.currentCalculParams,'sdb');
              this.$delete(this.currentCalculParams,'sdb_wc');
              this.$delete(this.currentCalculParams,'wc');
              this.$delete(this.currentCalculParams,'eau');
              this.$delete(this.currentCalculParams,'offered');
              this.$delete(this.currentCalculParams,'rallonge');
              this.$delete(this.currentCalculParams,'entree');
            break;
          }
        break;
      }
    },
    add_item_options(){
      if(this.currentOptions.content.type == 'checkbox'){
        if(!this.currentOptions.content.items) this.$set(this.currentOptions.content,'items',[]);
        this.currentOptions.content.items.push({lidic:'',number:'1'});
      }else{
        if(!this.currentOptions.content.options) this.$set(this.currentOptions.content,'options',[]);
        this.currentOptions.content.options.push({text:'',value:''});
      }
    },
    remove_item_options(index){
      if(this.currentOptions.content.type == 'checkbox'){
        this.currentOptions.content.items.splice(index,1);
      }else{
        this.currentOptions.content.options.splice(index,1);
      }
    },
    remove_item_config(propertyName){
      delete this.currentConfig[propertyName];
      this.$forceUpdate();
    },
    add_item_config(){
      if(this.currentConfigLabel=='barre_vertical'){
        if(this.newConfigParam!=""){
          this.$set(this.currentConfig,this.newConfigParam,[0,0]);
          this.newConfigParam = '';
        }
      }else{
        if(this.newConfigParam!=""){
          this.$set(this.currentConfig,this.newConfigParam,0);
          this.newConfigParam = '';
        }
      }
    },
    add_item_commande(bouche,commande){
      this.currentCalculParams[commande][bouche].push({lidic:'',number:1});
    },
    add_item_entree(rallonge,bouche){
      if(rallonge){
        if(!this.currentCalculParams['rallonge'][bouche]) this.$set(this.currentCalculParams['rallonge'],bouche,[]);
        this.currentCalculParams['rallonge'][bouche].push({lidic:'',number:1});
      }else{
        if(!this.currentCalculParams['entree'][bouche]) this.$set(this.currentCalculParams['entree'],bouche,[]);
        this.currentCalculParams['entree'][bouche].push({lidic:'',number:1});
      }

    },
    remove_item_commande(bouche,index2,commande){
      this.currentCalculParams[commande][bouche].splice(index2,1);
    },
    remove_item_entree(rallonge,bouche,index2){
      if(rallonge){
        this.currentCalculParams['rallonge'][bouche].splice(index2,1);
      }else{
        this.currentCalculParams['entree'][bouche].splice(index2,1);
      }
    },
    remove_item_gaine(nb_etage,index){
      this.currentGaine.items[nb_etage].splice(index,1);
    },
    add_item_gaine(nb_etage){
      if(!this.currentGaine.items[nb_etage]) this.$set(this.currentGaine.items,nb_etage,[]);
      this.currentGaine.items[nb_etage].push({lidic:'',number:1});
    },
    add_item_bouche(bouche){
      if(!this.currentParams[bouche]) this.$set(this.currentParams,bouche,[]);
      this.currentParams[bouche].push({lidic:'',number:1});
    },
    remove_item_bouche(bouche,index2){
      this.currentParams[bouche].splice(index2,1);
    },
    add_item_pt(pt,commande,mainDefine,isMain,index){
      if(commande!=''){
        if(mainDefine){
          if(isMain){
            if(!this.currentCalculParams[pt][commande]['main'][index]) this.$set(this.currentCalculParams[pt][commande]['main'][index],[]);
            this.currentCalculParams[pt][commande]['main'][index].push({lidic:''});
          }else{
            if(!this.currentCalculParams[pt][commande]['supp'][index]) this.$set(this.currentCalculParams[pt][commande]['supp'][index],[]);
            this.currentCalculParams[pt][commande]['supp'][index].push({lidic:''});
          }
        }else{
          if(!this.currentCalculParams[pt][commande][index]) this.$set(this.currentCalculParams[pt][commande][index],[]);
          this.currentCalculParams[pt][commande][index].push({lidic:''});
        }
      }else{
        if(mainDefine){
          if(isMain){
            if(!this.currentCalculParams[pt]['main'][index]) this.$set(this.currentCalculParams[pt]['main'][index],[]);
            this.currentCalculParams[pt]['main'][index].push({lidic:''});
          }else{
            if(!this.currentCalculParams[pt]['supp'][index]) this.$set(this.currentCalculParams[pt]['supp'][index],[]);
            this.currentCalculParams[pt]['supp'][index].push({lidic:''});
          }
        }else{
          if(!this.currentCalculParams[pt][index]) this.$set(this.currentCalculParams[pt][index],[]);
          this.currentCalculParams[pt][index].push({lidic:''});
        }
      }

    },
    remove_item_pt(pt,commande,mainDefine,isMain,index,index2){
      if(commande!=''){
        if(mainDefine){
          if(isMain){
            this.currentCalculParams[pt][commande]['main'][index].splice(index2,1);
          }else{
            this.currentCalculParams[pt][commande]['supp'][index].splice(index2,1);
          }
        }else{
          this.currentCalculParams[pt][commande][index].splice(index2,1);
        }
      }else{
        if(mainDefine){
          if(isMain){
            this.currentCalculParams[pt]['main'][index].splice(index2,1);
          }else{
            this.currentCalculParams[pt]['supp'][index].splice(index2,1);
          }
        }else{
          this.currentCalculParams[pt][index].splice(index2,1);
        }
      }

    },
    is_option_checkbox(){
      return (this.currentOptions && this.currentOptions.content && this.currentOptions.content.type=="checkbox");
    },
    is_option_checkbox_visible(){
      console.log(this.currentOptions.content.visibility);
      return (this.currentOptions && this.currentOptions.content && this.currentOptions.content.visibility);
    },
    is_paramType(type,isCalcul){
      return isCalcul ? this.currentCalculParams.type==type : this.currentParams.type==type;
    },
    isBarreVertical(){
      return this.currentConfigLabel=="barre_vertical";
    },
    close(type){
      switch(type){
        case 'options':
          this.lidic_options = [];
          this.editOptions=false;
          console.log(this.currentOptions);
          console.log(this.currentVMC);
        break;
        case 'calcul_params':
          this.editCalculParams = false;
          console.log(this.currentCalculParams);
          console.log(this.currentCalcul);
        break;
        case 'debit':case 'debit_max':
          this.currentDebit = {};
          this.editParams=false;
          console.log(this.currentBouche);
        break;
        case 'gaines':
          this.lidic_params = [];
          this.editGaines = false;
          if(this.currentGaineLidic!=this.tmpGaineLidic && this.currentGaineLidic!=-1){
            this.$delete(this.currentParams, this.currentGaineLidic);
          }
          this.$set(this.currentParams,this.tmpGaineLidic,this.currentGaine);
          console.log(this.currentParams);
          console.log(this.currentVMC);
        break;
        case 'config':
          this.editConfig=false;
          if(this.currentConfigLabel!=this.tmpConfigLabel && this.currentConfigLabel!='Nouveau Label'){
            this.$delete(this.currentVMC.params.config, this.currentConfigLabel);
          }
          this.$set(this.currentVMC.params.config,this.tmpConfigLabel,this.currentConfig);
          console.log(this.currentConfig);
          console.log(this.currentVMC);
        break;
      }
    },
    copyItem(item,type){
      switch(type){
        case 'individuel':
          this.currentVMC = JSON.parse(JSON.stringify(item));
          this.currentVMC.lidic=null;
          this.currentVMC.status= "waiting",
          this.currentVMCLidic = -1;
          this.editVMC = true;
        break;
      }
    },
    editItem(item,type,index){
      switch(type){
        case 'calcul_params':
          if(item==-1){
            this.currentCalcul.params.items.push({type:''});
            this.currentCalculParams = this.currentCalcul.params.items[this.currentCalcul.params.items.length - 1];
          }else{
            this.currentCalculParams = item;
          }
          this.editCalculParams = true;
        break;
        case 'calcul':
          if(item==-1){
            console.log("new");
          }else{
            this.currentCalcul = item;
          }
          this.editCalcul = true;
        break;
        case 'bouche':
          if(item==-1){
            this.currentBouche = {
              id: "",
              debit_min: {1:[],2:[],3:[],4:[],5:[],6:[],7:[]},
              debit_max:{1:[],2:[],3:[],4:[],5:[],6:[],7:[]},
              status: "waiting",
            };
            this.currentVMCLidic = item;
          }else{
            this.currentBouche = item;
            this.currentVMCLidic = item.id;
          }
          this.editBouche = true;
          this.tmpBouche = JSON.parse(JSON.stringify(this.currentBouche));
        break;
        case 'debit':
          if(item==-1){
            this.currentBouche.debit[index].push({eau:0,sdb:0,sdbwc:0,wc:0,debit:{eau:{lidic:0,debit_min:5,debit_max:30},sdb1:{lidic:0,debit_min:0,debit_max:30},sdb2:{lidic:0,debit_min:0,debit_max:30},sdbsupp:{lidic:0,debit_min:0,debit_max:30},
              sdbwc1:{lidic:0,debit_min:0,debit_max:30},sdbwc2:{lidic:0,debit_min:0,debit_max:30},sdbwcsupp:{lidic:0,debit_min:0,debit_max:30},wc:{lidic:0,debit_min:0,debit_max:30},wcsupp:{lidic:0,debit_min:0,debit_max:30},cuisine:{lidic:0,debit_min:0,debit_max:30}}});
            this.currentDebit = this.currentBouche.debit[index][this.currentBouche.debit[index].length - 1];
          }else{
            this.currentDebit = item;
          }
          this.editParams = true;
          console.log(item);
          console.log(this.currentDebit);
        break;
        case 'debit_max':
        if(item==-1){
          this.currentBouche.debit_max[index].push({eau:0,sdb:0,sdbwc:0,wc:0,debit:{eau:5,sdb1:0,sdb2:0,sdbsupp:0,sdbwc1:0,sdbwc2:0,sdbwcsupp:0,wc:0,wcsupp:0,cuisine:0}});
          this.currentDebit = this.currentBouche.debit_max[index][this.currentBouche.debit_max[index].length - 1];
        }else{
          this.currentDebit = item;
        }
          this.editParams = true;
        break;
        case 'individuel_config':
          if(item==-1){
            this.currentConfig = {};
            this.currentConfigLabel = 'Nouveau label';
            this.tmpConfigLabel = 'Nouveau label';
          }else{
            this.currentConfig = this.currentVMC.params.config[item.label];
            this.currentConfigLabel = item.label;
            this.tmpConfigLabel = this.currentConfigLabel;
          }
          this.editConfig = true;
          console.log(this.currentConfig);
        break;
        case 'individuel_gaines':

          if(item==-1){
            this.currentGaine = {
              items:{
                0:[],
                1:[]
              },
              longueur:0
            };
            this.currentGaineLidic = -1;
            this.tmpGaineLidic = null;
          }else{
            this.currentGaine = this.currentParams[item.lidic];
            this.currentGaineLidic = item.lidic;
            this.tmpGaineLidic = this.currentGaineLidic;
          }
          this.editGaines = true;
          console.log(item);
        break;
      }
    },
    deleteItem(item,type,index){
      switch(type){
        case 'bouche':
          this.$http.delete(`${this.$api_url}vmc/bouche/${item.id}`).then((result) => {
            console.log(result);
            if(result){
              var index = this.bouches.findIndex((vmc) => { return item.id == vmc.id; });
              if(index>-1) this.bouches.splice(index,1);
            }else{
              console.log(result);
            }
          });
        break;
        case 'debit':
          this.currentBouche.debit[index].splice(item,1);
          console.log(this.currentBouche);
        break;
        case 'debit_max':
          this.currentBouche.debit_max[index].splice(item,1);
        break;
        case 'individuel_config':
          this.$delete(this.currentVMC.params.config, item.label);
        break;
        case 'individuel_gaines':
          this.$delete(this.currentParams, item.lidic);
        break;
      }
    },
    cancel(type){
      switch(type){
        case 'bouche':
          this.editBouche = false;
          this.currentBouche = {};
          var tmpID = this.bouches.findIndex((x) => x.id === this.tmpBouche.id);
          this.bouches[tmpID] = this.tmpBouche;

        break;
        case 'calcul_params':
          this.editCalcul = false;
          this.currentCalcul = {};
        break;
      }
    },
    saveItem(type){
      var baseURI = this.$api_url+'bouche';
      var type_content = '';
      switch(type){
        case 'calcul_params':
          console.log(this.currentCalcul);
          type_content = 'collectif_calcul';
          this.$http.put(baseURI,{vmc: this.currentCalcul,lidic: this.currentCalcul.id, type:type_content})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.collectif.calcul.findIndex((item) => { return item.id == this.currentCalcul.id; });
                this.$set(this.collectif.calcul,index,this.currentCalcul);
                this.currentCalcul = {};
                this.editCalcul = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
        break;
        case 'bouche':
          console.log(this.currentBouche);
          if(this.currentVMCLidic==-1){
            this.$http.post(baseURI,{bouche: this.currentBouche})
            .then((result) => {
              console.log(result);
              if(result){
                this.$set(this.bouches,this.bouches,this.currentBouche);
                this.currentBouche = {};
                this.editBouche = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }else{
            console.log(this.currentVMCLidic);
            this.$http.put(baseURI,{bouche: this.currentBouche,lidic:this.currentVMCLidic})
            .then((result) => {
              console.log(result);
              if(result){
                var index = this.bouches.findIndex((item) => { return item.id == this.currentVMCLidic; });
                this.$set(this.bouches,index,this.currentBouche);
                this.currentBouche = {};
                this.editBouche = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
      }
    },
    rowClass(item,type){
      if (item && type === 'row') {
        if(item.status=='valid'){
          return 'validation_success';
        }else if(item.status=='error'){
          return 'validation_error';
        }else if(item.status=="waiting"){
          return 'waiting_validation';
        }
      }else{
        return null;
      }
    },
    updateDebitLidic(type){
      if(this.currentDebit.debit[type].lidic!=""){
        let index = this.product.findIndex((item)=> {
          return item.lidic == this.currentDebit.debit[type].lidic});
        if(index!=1){
          this.currentDebit.debit[type].debit_min = parseInt(this.product[index].debit_min);
          this.currentDebit.debit[type].lidic = parseInt(this.currentDebit.debit[type].lidic);
          this.currentDebit.debit[type].debit_max = this.product[index].debit_max;
          this.currentDebit.debit[type].type = this.product[index].type;
          this.currentDebit.debit[type].name = this.product[index].nom;
        }
        console.log(this.currentDebit.debit[type]);
      }
    },
    updateLidicPiquetage(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicGaineProd(nb_etage,index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentGaine.items[nb_etage][index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentGaine.items[nb_etage][index].lidic) || item.nom.toString().includes(this.currentGaine.items[nb_etage][index].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicGaine(){
      let res = [];
      this.product.forEach((item) => {
        if(this.tmpGaineLidic.toString().length>3 && (item.lidic.toString().includes(this.tmpGaineLidic) || item.nom.toString().includes(this.tmpGaineLidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicOptions(index,isCheckbox){
      let res = [];
      if(isCheckbox){
        this.product.forEach((item) => {
          if(this.currentOptions.content.items[index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.items[index].lidic) || item.nom.toString().includes(this.currentOptions.content.items[index].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }else{
        this.product.forEach((item) => {
          if(this.currentOptions.content.options[index].value.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.options[index].value) || item.nom.toString().includes(this.currentOptions.content.options[index].value))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }
      this.lidic_params = res;
      return res;
    },
    clearLidicBouche(){
      this.lidic_params = [];
    },
    updateLidicCommande(type_commande,index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentCalculParams[type_commande][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[type_commande][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[type_commande][index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicEntree(rallonge,index,index2,type){
      let res = [];
      switch(type){
        case 'calcul':
          if(rallonge){
            this.product.forEach((item) => {
              if(this.currentCalculParams['rallonge'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams['rallonge'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams['rallonge'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }else{
            this.product.forEach((item) => {
              if(this.currentCalculParams['entree'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams['entree'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams['entree'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }
        break;
        case 'vmc':
          if(rallonge){
            this.product.forEach((item) => {
              if(this.currentParams['rallonge'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['rallonge'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['rallonge'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }else{
            this.product.forEach((item) => {
              if(this.currentParams['entree'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['entree'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['entree'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }
        break;
      }
      this.lidic_params = res;
      return res;
    },
    updateLidicBouche(index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[index][index2].lidic) || item.nom.toString().includes(this.currentParams[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicParams(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicPTCommande(pt,cmd,index,index2,mainDefine,isSupp,type){
      console.log("called");
      let res = [];
      switch(type){
        case 'calcul':
          if(mainDefine){
            if(isSupp){
              this.product.forEach((item) => {
                if(this.currentCalculParams[pt][cmd]['supp'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt][cmd]['supp'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt][cmd]['supp'][index][index2].lidic))){
                  res.push({'value':item.lidic, 'text':item.nom});
                }
              });
            }else{

              this.product.forEach((item) => {
                if(this.currentCalculParams[pt][cmd]['main'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt][cmd]['main'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt][cmd]['main'][index][index2].lidic))){
                  res.push({'value':item.lidic, 'text':item.nom});
                }
              });
            }
          }else{
            this.product.forEach((item) => {
              if(this.currentCalculParams[pt][cmd][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt][cmd][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt][cmd][index][index2].lidic))){
                res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }
        break;
        case 'vmc':
        break;
      }
      this.lidic_params = res;
      return res;
    },
    updateLidicPT(pt,index,index2,mainDefine,isSupp,type){
      let res = [];
      switch(type){
        case 'calcul':
        if(mainDefine){
          if(isSupp){
            this.product.forEach((item) => {
              if(this.currentCalculParams[pt]['supp'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt]['supp'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt]['supp'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }else{
            this.product.forEach((item) => {
              if(this.currentCalculParams[pt]['main'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt]['main'][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt]['main'][index][index2].lidic))){
              res.push({'value':item.lidic, 'text':item.nom});
              }
            });
          }
        }else{
          this.product.forEach((item) => {
            if(this.currentCalculParams[pt][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentCalculParams[pt][index][index2].lidic) || item.nom.toString().includes(this.currentCalculParams[pt][index][index2].lidic))){
            res.push({'value':item.lidic, 'text':item.nom});
            }
          });
        }
        break;
        case 'vmc':
          this.product.forEach((item) => {
            if(this.currentParams[pt].items[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[pt].items[index][index2].lidic) || item.nom.toString().includes(this.currentParams[pt].items[index][index2].lidic))){
            res.push({'value':item.lidic, 'text':item.nom});
            }
          });
        break;
      }

      this.lidic_params = res;
      return res;
    },
    updateLidicProduct(type){
      let res = [];
      console.log(this.product);
      console.log(this.currentDebit.debit);
      console.log(type);
      this.product.forEach((item) => {
        if(this.currentDebit.debit[type].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentDebit.debit[type].lidic) || item.nom.toString().includes(this.currentDebit.debit[type].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_product[type] = res;
      return res;
    }
  },
  computed: {
    getcurrentCaculType(){
      let res = "";
      if(this.currentCalcul!=null && this.currentCalcul.id!=-1){
        switch(this.currentCalcul.id){
          case 'A':
            res = 'Auto';
          break;
          case 'HA':
            res = "Hydro A";
          break;
          case 'HB':
            res = "Hydro B";
          break;
        }
      }else{
        res = 'Nouveau Calcul';
      }
      return res;
    },
    get_gaines_params(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="gaines"){
        console.log(this.currentParams);
        Object.keys(this.currentParams).filter(e => e !== 'type').forEach((item)=>{
          res.push({lidic: item});
        });
      }
      return res;
    },
    typologie_range(){
      let res = [];
      if(this.currentVMC!=null && this.currentVMC.typologie!=undefined && this.currentVMC.typologie_min!=undefined){
        let tmp = this.currentVMC.typologie_min;
        while(tmp<=this.currentVMC.typologie){
          res.push(tmp);
          tmp++;
        }
      }
      return res;
    },
    piece_technique_range(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="piece_technique" && this.currentParams['eau']!=null){
        res.push({value:'eau',text:"Salle d'eau"});
        res.push({value:'wc',text:"WC"});
        res.push({value:'sdb_wc',text:"Salle de bain avec WC"});
        res.push({value:'sdb',text:"Salle de bain"});
      }
      return res;
    },
    commande(){
      let res = [];
      if(this.currentCalculParams!=null && this.currentCalculParams.type=="piece_technique"){
        res.push({value:'M',text:"Commande Manuelle"});
        res.push({value:'P',text:"Commande à Pile"});
        res.push({value:'220V',text:"Commande 220V"});
        res.push({value:'12V',text:"Commande 12V"});
      }
      return res;
    },
    piece_technique_range_calcul(){
      let res = [];
      if(this.currentCalculParams!=null && this.currentCalculParams.type=="piece_technique" && this.currentCalculParams['eau']!=null){
        res.push({value:'eau',text:"Salle d'eau"});
        res.push({value:'wc',text:"WC"});
        res.push({value:'sdb_wc',text:"Salle de bain avec WC"});
        res.push({value:'sdb',text:"Salle de bain"});
      }
      return res;
    },
    total_devis(){
      return this.items.length;
    },
    getCurrentConfig(){
      let res = [];
      Object.keys(this.currentVMC.params.config).forEach((item)=>{
        console.log(item);
        res.push({label:item});
      });
      return res;
    },
    total_prix(){
      let res = 0;
      this.items.forEach((item) => {
        res += parseFloat(item.prix);
      });
      res = res.toFixed(2);
      return res;
    },
    items(){
      let res;
      if((this.start!=null && this.start!="") && (this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime() && new Date(elem.created_at).getTime() <= new Date(this.end).getTime());
      }else if((this.start!=null && this.start!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime());
      }else if((this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime()<= new Date(this.end).getTime());
      }else{
        res = this.devis;
      }
      return res;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
