import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import VueKonva from 'vue-konva';

Vue.use(VueKonva);

import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import CaissonAdmin from './components/CaissonAdmin';
import CatalogueAdmin from './components/CatalogueAdmin';
import ImageAdmin from './components/ImageAdmin';
import BoucheAdmin from './components/BoucheAdmin';
import GaineAdmin from './components/GaineAdmin';
import RejetAdmin from './components/RejetAdmin';
import ScriptAdmin from './components/ScriptAdmin';

Vue.prototype.$http = axios;

//DEV SP Theme
Vue.prototype.$path = '/base/';
Vue.prototype.$appName = "dev";
Vue.prototype.$dev = true;
Vue.prototype.$news = true;
Vue.prototype.$maincolor = '#ff0000';
Vue.prototype.$api_url = "https://performair.intranet-solerpalau.fr:4001/dev/";

//SP Theme
Vue.prototype.$path = '/base/';
Vue.prototype.$appName = "prod";
Vue.prototype.$maincolor = '#ff0000';
Vue.prototype.$dev = false;
Vue.prototype.$news = true;
Vue.prototype.$api_url = 'https://performair.intranet-solerpalau.fr:4000/base/';

Vue.config.productionTip = false;
Vue.component('CaissonAdmin', CaissonAdmin);
Vue.component('CatalogueAdmin', CatalogueAdmin);
Vue.component('BoucheAdmin', BoucheAdmin);
Vue.component('GaineAdmin', GaineAdmin);
Vue.component('RejetAdmin', RejetAdmin);
Vue.component('ImageAdmin', ImageAdmin);
Vue.component('ScriptAdmin', ScriptAdmin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
