<template>
  <b-container id="list" :style="cssProps">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Performair" id="performair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <router-link to="/chantier">Chantier</router-link> <span class='decoration'>></span> <router-link to="/trace">Tracé</router-link> <span class='decoration'>></span> <span class='decoration'>></span> Données <span class='decoration'>></span> <span class='selected'>Caissons</span></p>
    </div>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="9" class="thanks" style="text-align:center">
        <div class="inner-shadow-box">
          <p>S&amp;P France vous remercie d’utiliser PERFORMAIR.</p>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="12" md="4" lg="3" class="new_devis">
        <div class="shadow-box">
          <router-link to="/">
            <p>Nouveau Projet</p>
          </router-link>
        </div>
      </b-col>
      <b-col cols="12" md="4" lg="3" class="new_devis">
        <div class="shadow-box">
          <router-link to="/chantier" >
            <p>Optimiser ce Projet</p>
          </router-link>
        </div>
      </b-col>
      <b-col cols="12" md="4" lg="3" class="new_devis">
        <div class="shadow-box">
          <a href="#" @click.prevent.stop="exportProjet">
            <p>Enregistrer ce Projet</p>
          </a>
        </div>
      </b-col>
    </b-row>
    <b-row class="no-gutter align-items-center content-block shadow-box caisson" :class="!caisson.suitable?'hide':'show'" v-for="caisson in caissons" :key="caisson.lidic" style="margin-bottom:2em">
      <b-col cols="12" md="3" lg="3" style="text-align: center;padding: 2em;">
        <h3 v-html="caisson.libelle"></h3>
        <b-img :src="`/img/${caisson.lidic}.jpg`" fluid class='vignette' style="border-radius: 1em; border: 1px solid darkgrey;" :id="'caisson_vignette_'+caisson.lidic"/>
        <p v-if="caisson.suitable" style="text-align: center;font-size: 1em;margin-top:0.5em">Puissance électrique pondérée W Th-C: <strong>{{caisson.energie}}</strong></p>
      </b-col>
      <b-col cols="12" md="6" lg="6">
          <!--<p style="margin-bottom:0" v-html="caisson.designation"></p>-->
          <h5 style="margin-bottom:0;"><strong>Courbe de fonctionnement</strong></h5>
          <apexchart type="line" height="350" :options="caisson.chartOptions" :series="caisson.series" :id="'caisson_courbe_'+caisson.lidic"></apexchart>
          <p v-if="!caisson.suitable && !caisson.notsuitablePiece" style="text-align:center;font-size:1.2em;color:red;">Ce dimensionnement fait apparaitre que la branche <b>{{worstbranch()}}</b> est la plus exigeante.</p>
          <p v-if="!caisson.suitable && caisson.notsuitablePiece" style="text-align:center;font-size:1.2em;color:red;">Le nombre de pièce technique dépasse la capacité de gestion du caisson.</p>
      </b-col>
      <!--<b-col cols="12" md="3" lg="3">
          <h5>Puissance pondérée (Pa): {{caisson.ppd}}</h5>
          <h5>Pression Disponible (Pa): {{caisson.pd}}</h5>
          <h5>Pression Restante (W-Th-C): {{caisson.pr}}</h5>
      </b-col>-->
      <b-col cols="12" md="3" lg="3" v-if="caisson.suitable">
        <b-row class="justify-content-center">
          <b-col cols="12" class="new_devis">
            <div class="shadow-box">
              <a href="#" @click="downloadPDFInstal(caisson)">
                <p>Sélectionner ce produit</p>
              </a>
            </div>
            <p v-if="caisson.order>3" class="new_dimensio">"Uniquement pour la mise à jour de fin de chantier Dimensionnement 2023"<br>Fin de commercialisation janvier 2024, remplacé par la gamme Ecowatt + / HP+</p>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <b-row class="footer align-items-center no-gutters">
      <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
        <b-button @click="back($event)">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
      <b-col md="8" cols="12" order="1" order-md="2" class="content inner-shadow-box">
        <div class="content-block" style="padding: 0;border-radius: 0;background: none;">
          <p><strong>Le dimensionnement est le résultat de la configuration de votre réseau saisi pour assurer une pression de fonctionnement aux bouches hygroréglables de 80 Pa minimum et 160 Pa maximum.<br>Saviez-vous que les piquages flexibles de l’OCTEO  evitent l’ écrasement de gaine et les coudes 90° en sortie de groupe.<br>Vous pouvez modifier la position du groupe pour limiter les longueurs sur les branches les plus critiques.</strong></p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import ApexCharts from 'apexcharts';

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

export default {
  name: 'Caissons',
  data() {
      return {
        show: true,
        selectedCategorie: 'Tous les produits',
        list_label: 'Choix VMC',
        libelle_branche: {
          cuisine: "Cuisine",
          eau:"Salle d'eau",
          rejet: "Rejet",
          sdb: "Salle de bain",
          sdbwc: "Salle de bain avec WC",
          wc: "WC"
        },
        }
    },
    methods: {
      exportProjet(){
        console.log("export project as performair file");
        var toExport = {
          devis:this.$store.state.devis.devis,
          rejets:this.$store.state.devis.rejets
        };
        console.log(toExport);
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(toExport)], {type: 'text/plain'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'projet.performair');
        document.body.appendChild(link);
        link.click();
      },
      worstbranch(){
        const findHighest = obj => {
           const values = Object.values(obj);
           const max = Math.max.apply(Math, values);
           for(var key in obj){
              if(obj[key] === max){
                 return {
                    [key]: max
                 };
              }
           }
        };
        var maxBranche = Object.getOwnPropertyNames(findHighest(this.$store.state.devis.devis.branche.max))[0];
        //var minBranche = Object.getOwnPropertyNames(findHighest(this.$store.state.devis.devis.branche.min))[0];
        return this.libelle_branche[maxBranche];
      },
      downloadPDFInstal(caisson){
        var caisson_vignette = "caisson_vignette_"+caisson.lidic;
        var caisson_courbe = "caisson_courbe_"+caisson.lidic;
        //console.log(this.$store.state.devis.devis);

        console.log(caisson_vignette);
        console.log(caisson_courbe);
        const image = document.getElementById(caisson_vignette);
        console.log(image);
        console.log(image.src);

        fetch(image.src)
          .then((res) => res.blob())
          .then((blob) => {
             console.log(blob);
              const reader = new FileReader();

              reader.onloadend = () => {
                  console.log(reader.result);
                  ApexCharts.exec(caisson_courbe, "dataURI").then(({ imgURI }) => {
                    const baseURI = this.$api_url+'generatePDF';
                    console.log(this.$store.state.devis.devis);
                    /*var tmpTrace = this.$store.state.devis.devis.trace;
                    delete tmpTrace.layersImg;
                    delete tmpTrace.layersLabel;
                    delete tmpTrace.visualBranche;
                    delete tmpTrace.selectedLayer;
                    delete tmpTrace.stageSaved;*/
                    this.$http.post(baseURI,{'devis':this.$store.state.devis.devis,'caisson':{'content':caisson,'vignette':reader.result,'courbe':imgURI}}).then((result) => {
                      if(result){
                        var tmp_url = this.$api_url+result.data;
                        console.log(tmp_url);

                        var link= document.createElement('a');
                        link.target = '_blank' ;
                        link.href = tmp_url ;
                        link.download = tmp_url.substr(tmp_url.lastIndexOf('/') + 1);
                        link.click();
                      }
                    });
                  });
              };
              reader.readAsDataURL(blob);
          });
      },
      back(evt) {
        evt.preventDefault();
        this.$store.dispatch('devis/setCaissons',{});
        this.$router.go(-1);
      },
    },
    computed: {
      cssProps() {
          return {
            '--maincolor': this.$maincolor
          }
        },
      caissons() {
        var caissons = [];
        if(this.$store.state.devis.devis!=null && this.$store.state.devis.devis.caissons && this.$store.state.devis.devis.caissons.res!="ok"){
          this.$store.state.devis.devis.caissons.forEach((caisson) => {
              caissons.push(caisson);
          });
        }else{
          caissons.push({ppd:10.3,pd:150,pr:8,lidic:'607711',designation:'Pour tous chantiers, installation ultra-rapide, simple & durable avec ses systèmes PowerTwist, PowerFlex & PowerConnect. Kit VMC Hygro de type B basse conso à partir de 16,3 WThC. Bouches à piles et détections de présence (WC)',nom:'OCTEO ST KHB T3/7 P K',series:[{
                name: "Points de fonctionnement",
                data: [[10,5], [41,6], [35,8], [51,20], [49,23], [62,42], [69,21], [91,10], [148,10]]
            }]});
          caissons.push({series:[{
                name: "Points de fonctionnement",
                data: [[10,5], [41,6], [35,8], [51,20], [49,23], [62,42], [69,21], [91,10], [148,10]]
            }],ppd:7.6,pd:142,pr:23,lidic:'607611',designation:'Pour tous chantiers, installation ultra-rapide, simple & durable avec ses systèmes PowerTwist, PowerFlex & PowerConnect. Kit VMC Hygro de type B basse conso à partir de 16,3 WThC. Bouches à piles et détections de présence (WC)',nom:'OCTEO ST KHB T3/7 P K'});
          caissons.push({series:[{
                name: "Points de fonctionnement",
                data: [[10,5], [41,6], [35,8], [51,20], [49,23], [62,42], [69,21], [91,10], [148,10]]
            }],ppd:8.4,pd:130,pr:2,lidic:'607411',designation:'Pour tous chantiers, installation ultra-rapide, simple & durable avec ses systèmes PowerTwist, PowerFlex & PowerConnect. Avec 5 entrées d’air filtrantes, 1 sonde radio de QAI (Séjour), 2 sondes HR (cuisine et SdB), 1 COV (WC), 4 bouches BDO',nom:'OCTEO AUTO SERENITE ECO RD K '});
        }
        return caissons;
      },
    },mounted(){
      window.scrollTo(0, 0);
      console.log(this.$store.state.devis.devis);
    }
}
</script>

<style lang="scss">
#list{
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;
  .content-block{
    background: linear-gradient(-122deg,#f8f8fc,#e1e2e8 100%,#00d4ff 0);
    border-radius: 1em;
    padding: 1em 2em 1.5em 2em;
    height: 100%;

    &.caisson.hide{
      //display:none;
      opacity:0.5;
    }
  }
  .thanks div{
    margin-bottom:2em;
    font-size: 1.5em;
    color: #575F6B;
    border-radius: 1.5em;
    padding:1em 1em;
    text-align: center;
    p{
      margin:0;
      padding: 0 4em;
    }
  }

  .new_dimensio{
    text-align: center;
    color: red;
    font-weight: bold;
  }

  .new_devis div{
    text-align: center;
    margin-bottom:1em;
    font-size: 1.5em;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    border-radius: 2em;
    padding:0.5em 0em;
    a{
      &:hover{
        text-decoration: none;
      }
      p{
       margin:0;
       color: black;
     }
    }
  }
  .filter button {
    box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
    background-color: transparent !important;
    color:#333;
    border:0;
    text-transform: none;
    padding: 0.1em 1em;
    margin: 5px 12px;
    font-size: 1.75em;
    &.active{
      border:1px solid var(--maincolor);
      box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
    }
  }

  .product-item{
    @media (max-width: 576px) {
        margin: 2em 0px;
    }
    background: linear-gradient(-122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
    padding: 2em 2.5em;
    text-align: center;
    margin: 2em 30px;
    width: 100%;
    max-width: 400px;
    border-radius: 2em;
    cursor: pointer;
    &.disabled{
      cursor: default;
      h5{
        color:#666;
      }
      p{
        color:#666;
      }
      h6{
        color: var(--maincolor);
      }
      &:before{
        content: " ";
        background: rgba(120, 120, 120, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 2em;
        z-index: 10;
      }
    }

    h5{
      color:black;
      font-size:2em;
      font-weight: bold;
    }
    img.vignette{
      width:80%;
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
      border-radius:2em;
      padding: 0.5em 3em;
      background-color:white;
      margin: 1em 0;
    }

    h6{
      img{
        background-color: transparent;
        border-radius:0;
        box-shadow:none;
        padding:0;
        width:54px;
        margin-right: 1em;
      }

      span {
        z-index:12;
        position: relative;
      }
    }

    .button-next {
      background: linear-gradient(-70deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
      width:100px;
      height:100px;
      margin:0 auto;
      border-radius: 50%;

      img{
        transform: rotate(180deg);
        padding: 0.8em 2.2em;
      }
    }
  }
}
</style>
