<template>
    <b-container class="home" fluid="xl">
      <b-row align-h="center" align-v="center">
        <b-col md="10" sm="12" style="position:relative;">
          <router-link to="/"><b-img :src='"/img/"+$path+"logo.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img></router-link>
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center">
        <p>Bienvenue, cet outil a été élaboré pour répondre au dernier Cahier des Prescriptions Techniques des sytèmes de VMC Simple Flux Hygroréglables. Il vous permet de réaliser le dimensionnement théorique selon les règles communes définies par Uniclima  et de sélectionner le  groupe de VMC hygroréglable le plus adapté aux besoins de votre installation.</p>
      </b-row>
      <b-row style="margin-top:2em;" align-h="center">
        <b-col sm="12" md="3" class="entry">
            <router-link to="/chantier" v-bind:class="{'disabled':!module_individuel}">
              <p class="upcoming" v-if="!module_individuel">
                Bientôt Disponible
              </p>
              <div class="contain shadow-box" v-bind:class="{'disabled':!module_individuel}">
                <b-img src="../assets/individuel.jpg" fluid alt="Devis Individuel" class="entry_point"></b-img>
                <div class="caption">
                  <p>Nouveau Projet <span class="shadow-box"><img :src='"/img/"+$path+"arrow_right.png"' /></span></p>
                </div>
              </div>
            </router-link>
        </b-col>
        <b-col sm="12" md="3" class="entry">
          <a href="#" @click="importFile($event)">
            <div class="contain shadow-box">
              <b-img src="../assets/import.jpg" fluid alt="Reprendre un Projet" class="entry_point"></b-img>
              <div class="caption">
                <input type="file" ref="fileInput" style="display: none" @change="uploadFile"/>
                <p>Reprendre un Projet <span class="shadow-box"><img :src='"/img/"+$path+"arrow_right.png"'/></span></p>
              </div>
            </div>
          </a>
        </b-col>
        <!--<b-col sm="12" md="3" class="entry" v-if="!$islm">
          <router-link @click.native="setDevis('tertiaire')" to="/tertiaire" v-bind:class="{'disabled':!module_tertiaire}">
            <p class="upcoming" v-if="!module_tertiaire">
              Bientôt Disponible
            </p>
            <div class="contain shadow-box" v-bind:class="{'disabled':!module_tertiaire}">
                <b-img src="../assets/tertiaire.jpg" fluid alt="Devis Tertiare" class="entry_point"></b-img>
              <div class="caption">
                <p>Ventilation tertiaire <span class="shadow-box"><img :src='"/img/"+$path+"arrow_right.png"'/></span></p>
              </div>
            </div>
          </router-link>
        </b-col>
        <b-col sm="12" md="3" class="entry" v-if="!$islm">
          <router-link @click.native="setDevis('desenfumage')" to="/desenfumage" v-bind:class="{'disabled':!module_desenfumage}">
            <p class="upcoming" v-if="!module_desenfumage">
              Bientôt Disponible
            </p>
            <div class="contain shadow-box" v-bind:class="{'disabled':!module_desenfumage}">
                <b-img src="../assets/grid-desenfumage.jpg" fluid alt="Devis Accessoires" class="entry_point"></b-img>
              <div class="caption" style="height:80px">
                <p>Désenfumage habitat collectif <span class="shadow-box"><img :src='"/img/"+$path+"arrow_right.png"'/></span></p>
              </div>
            </div>
          </router-link>
        </b-col>-->
      </b-row>
      <div class="footer inner-shadow-box">
        <p>Proposé par<br>
          <strong>S&amp;P France</strong><br>
          <em>www.solerpalau.fr</em>
        </p>
        <p>
        <span v-if="is_nSP">pour<br></span>
        <b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img>
        </p>
      </div>
    </b-container>
</template>

<script>

export default {
  name: 'Home',
  data() {
      return {
        module_individuel : true,
        module_tertiaire: true,
        module_collectif: true,
        module_desenfumage: true,
        is_nSP: false,
      }
  },
  methods:{
    clearCache(){
      window.location.reload(true);
    },
    importFile(){
      this.$refs.fileInput.click();
    },
    uploadFile(event){
      var extension = event.target.files[0].name.split(".");
      if(event.target.files.length == 1 && extension[extension.length-1]=="performair"){
        const fileReader = new FileReader();
        fileReader.addEventListener('load', () => {
          var data = JSON.parse(fileReader.result);

          const baseURI = this.$api_url+'rejetsSource';
          this.$http.post(baseURI).then((result) => {
            if(result){
              data.rejets = result.data.rejets;
              var id_rejet = data.rejets.findIndex((x) => x.lidic === data.devis.chantier.rejet.lidic);
              if(id_rejet>-1) {
                data.devis.chantier.rejet = data.rejets[id_rejet];
                if(data.devis.trace.rejet!=null){
                  data.devis.trace.rejet.points_fonctionnement = data.devis.chantier.rejet.points_fonctionnement;
                }
              }
              console.log(data.devis);
              console.log(data.devis.debit);
              if(data.devis.debit.debit==undefined){
                const baseURI = this.$api_url+'debit';
                this.$http.post(baseURI,{'chantier':data.devis.chantier}).then((result) => {
                  if(result){
                    data.devis.debit = result.data;
                    this.$store.dispatch('devis/importData',data);
                    console.log("go to chantier");
                    console.log(data);
                    this.$router.push('chantier');
                  }
                });
              }else{
              this.$store.dispatch('devis/importData',data);
              console.log("go to chantier 2");
              console.log(data);
              this.$router.push('chantier');
              }
            }
          });

        })
        fileReader.readAsText(event.target.files[0]);
      }else{
        //mauvaise extension mauvais fichiers
      }
    },
    setDevis(type){
      this.$store.dispatch('devis/setType',type);
    }
  },
  mounted(){
    window.scrollTo(0, 0);
    this.$store.dispatch('devis/defaultChantier',{fuite:12,hygro:'HB'});
    
    this.is_nSP = this.$path!='/base/';

  }
}
</script>

<style lang="scss">
body{
  background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);

  .shadow-box {
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
  }

  .inner-shadow-box{
    box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
    display: inline-block;
  }
  .home{
    .footer{
      border-radius: 3em;
      color: #666666;
      padding: 1.5em 3em;
      line-height: 1.3em;
    }

    #estimair-logo{
      max-width: 80%;
    }

    #logo-business{
      max-width: 100px;
    }

    .entry {
      .upcoming{
        position: absolute;
        top: 19%;
        left: 1em;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        z-index: 8;
        background: rgba(255,0,0,0.5);
        padding: 1px 10px;
        font-size: 1.5em;
        transform: rotate(-15deg);
      }
      .contain{
        &.disabled{
          opacity:0.5;
        }

        position: relative;
        margin-bottom: 2em;
        border-radius: 1em;
        overflow: hidden;
        padding-bottom: 1em;
        .caption {
          position: absolute;
           bottom: -10px;
           width: 100%;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          opacity: 1;
          color: #666;
          border-radius: 0 0 1em 1em;
          height:60px;
           p {
             text-transform: uppercase;
             margin: 0;
             padding:10px 0;
             text-align:center;
             span{
               background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
               border-radius: 50%;
               padding: 5px 9px;
               box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
               img{
                 width:auto;
                 transform: translate(2px, 0);
               }
            }
           }
        }
        img.entry_point {
          border-radius: 1em;
          transition: transform 1s ease;
          width:100%;
          &:hover {
            transform: scale(2);
          }
        }
      }
    }
  }
}
</style>
