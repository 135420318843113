<template>
  <div id="rejet_admin">
    <b-row  v-if="editVMC">
      <b-col cols="12" md="12" lg="12">
        <div class="content-block shadow-box">
          <div class='header-form'>
            <p><span>{{currentRejet.libelle}}</span><br></p>
          </div>
          <div class="content-form roundButton">
            <b-row>
              <b-col cols="2">
                <label for="vmc_input">Rejet: </label>
              </b-col>
              <b-col>
              <b-form-input id="vmc_input" list="my-list-id" v-model="currentRejet.lidic" @blur="updateVMC" @input="updateLidicProduct()"></b-form-input>
              <datalist id="my-list-id">
                  <option :value="prod.value" v-for="(prod,index) in lidic_product" :key="index">{{ prod.text }}</option>
              </datalist>
            </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="rejet_libelle">Libelle: </label>
              </b-col>
              <b-col>
                <b-form-input id="rejet_libelle" v-model="currentRejet.libelle"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="debit_input_min">Longueur min: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="debit_input_min" v-model="currentRejet.longueur_min"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <label for="debit_input">Diamètre: </label>
              </b-col>
              <b-col>
                <b-form-input type="number" id="debit_input" v-model="currentRejet.diametre"></b-form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="2">
                <label for="statut_input">Status: </label>
              </b-col>
              <b-col>
                <b-form-select v-model="currentRejet.status" :options="status" id="statut_input"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-tabs pills>
                  <b-tab title="Points de fonctionnement" active>
                    <div class="content-block shadow-box" v-if="editOptions">
                      <div class='header-form'>
                        <p><span>Point de fonctionnement</span><br></p>
                      </div>
                      <div class="content-form roundButton">
                        <b-row>
                        <b-col>
                        <b-row>
                          <b-col cols="2">
                              <label for="points_fonctionnement_debit">Débit: </label>
                            </b-col>
                            <b-col>
                              <b-form-input id="points_fonctionnement_debit" v-model="currentPoints_fonctionnement.debit"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="2">
                              <label for="points_fonctionnement_pression">Pression: </label>
                            </b-col>
                            <b-col>
                            <b-form-input id="points_fonctionnement_pression" v-model="currentPoints_fonctionnement.pression"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-button size="sm" target="_blank" @click="close('caisson_point_fonctionnement')" class="mr-1">Fermer</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                      </b-row>
                    </div>
                  </div>
                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" target="_blank" @click="editItem(-1,'caisson_point_fonctionnement')" class="mr-1">Ajouter Point de fonctionnement</b-button>
                    </b-col>
                  </b-row>
                    <b-table responsive striped hover :items="currentRejet.points_fonctionnement" :fields="fields_points_fonctionnement">
                      <template #cell(actions)="row">
                        <b-button size="sm" target="_blank" @click="editItem(row.item,'caisson_point_fonctionnement')" class="mr-1">Editer</b-button>
                        <b-button size="sm" target="_blank" @click="deleteItem(row.index,'caisson_point_fonctionnement')" class="mr-1">Supprimer</b-button>
                      </template>
                    </b-table>
                  </b-tab>
                          </b-tabs>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="cancel('caisson')" class="mr-1">Annuler</b-button>
              </b-col>
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="saveItem('caisson')" class="mr-1">Enregistrer</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
  </b-row>
    <b-row  v-if="!editVMC">
        <b-col cols="12" md="12" lg="12">
          <div class="content-block shadow-box">
            <div class='header-form'>
              <p><span>Rejets</span><br></p>
            </div>
            <div class="content-form roundButton">
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                <p>Rejet enregistré avec succès.</p>
              </b-alert>
      <b-row align-h="end">
        <b-col cols="2">
          <b-button size="sm" target="_blank" @click="editItem(-1,'caisson')" class="mr-1">Ajouter un type de Rejet</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="2">
          <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher par LIDIC, Nom, Type,..."></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table responsive striped hover :items="rejets" :fields="fields" :filter="filter" id="my-table" :per-page="perPage" :current-page="currentPage" :tbody-tr-class="rowClass">
      <template #cell(vignette)="row">
        <b-img :src="`/img/${row.item.lidic}.jpg`"/>
      </template>
        <template #cell(actions)="row">
          <b-button size="sm" target="_blank" @click="editItem(row.item,'caisson')" class="mr-1">Editer</b-button>
          <b-button size="sm" target="_blank" @click="copyItem(row.item,'caisson')" class="mr-1">Copier</b-button>
          <b-button size="sm" target="_blank" @click="deleteItem(row.item,'caisson')" class="mr-1">Supprimer</b-button>
        </template>
      </b-table>
      </div></div>
    </b-col>
    </b-row>
    <datalist id="my-list-pt">
        <option :value="prod.value" v-for="(prod,index3) in lidic_params" :key="index3">{{ prod.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'RejetAdmin',
  props: {
    rejets: Array,
    product: Array,
  },
  data() {
      return {
        dismissCountDown: 0,
        currentRejet:{},
        currentRejetLidic: -1,
        currentOptions:{},
        currentPoints_fonctionnement:{},
        currentParams:{},
        currentConfig:{},
        newConfig: 'Nouveau Label',
        currentGaine:{},
        currentGaineLidic: null,
        tmpGaineLidic: null,
        currentConfigLabel: null,
        tmpConfigLabel: null,
        lidic_options:[],
        lidic_params:[],
        lidic_product:[],
        newConfigParam: '',
        editVMC:false,
        editOptions:false,
        editParams:false,
        editConfig:false,
        editGaines:false,
        filter: null,
        perPage: 20,
        currentPage: 1,
        fields_config: [
          {
            key: 'label',
            label: 'label',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_puissance_pondere: [
          {
            key: 'sdb',
            label: 'SDB',
            sortable: true
          },
          {
            key: 'sdbwc',
            label: 'SDB/WC',
            sortable: true
          },
          {
            key: 'wc',
            label: 'WC',
            sortable: true
          },
          {
            key: 'eau',
            label: "Salle d'eau",
            sortable: true
          },
          {
            key: 'puissance',
            label: "Puissance",
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_gaines: [
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields_points_fonctionnement: [
          {
            key: 'debit',
            label: 'Débit',
            sortable: true
          },
          {
            key: 'pression',
            label: 'Pressions',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        fields: [
          { key: 'vignette', label: '',tdClass: 'product-vignette' },
          {
            key: 'lidic',
            label: 'LIDIC',
            sortable: true
          },
          {
            key: 'libelle',
            label: 'Nom',
            sortable: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        typologie:[{
          value:1,
          text:'T1'
        },{
          value:2,
          text:'T2'
        },{
          value:3,
          text:'T3'
        },{
          value:4,
          text:'T4'
        },{
          value:5,
          text:'T5'
        },{
          value:6,
          text:'T6'
        },{
          value:7,
          text:'T7'
        }],
        type_vmc:[{
          value:'HA',
          text:'Hygro A'
        },
        {
          value:'HB',
          text:'Hygro B'
        },{
          value:'A',
          text:'Auto'
        }],
        param_type:[{value:'insufflation',text:"Bouche d'insufflation"},
                    {value:'gaines',text:"Gaines"},
                    {value:'piece_technique','text':'Pièces Techniques'},
                   {value:'entree','text':"Bouche d'entrée"},
                   {value:'silent','text':'Silent'}],
        flux:[{value:'Simple Flux',text:'Simple Flux'},{value:'Double Flux',text:'Double Flux'}],
        status:[{value:'valid',text:'Validé'},{value:'error',text:'Erreur Paramétrage'},{value:'waiting',text:'En attente de validation'}],
      }
  },
  methods: {
    countDownChanged(dismisscd){
      this.dismissCountDown = dismisscd;
    },
    updateParams(){
      switch(this.currentParams.type){
        case 'entree':
          this.$set(this.currentParams,'rallonge',{});
          this.$set(this.currentParams,'entree',{});
          this.$delete(this.currentParams,'sdb');
          this.$delete(this.currentParams,'sdb_wc');
          this.$delete(this.currentParams,'wc');
          this.$delete(this.currentParams,'eau');
          this.$delete(this.currentParams,'offered');
        break;
        case 'piece_technique':
          this.$delete(this.currentParams,'rallonge');
          this.$delete(this.currentParams,'entree');
          this.$set(this.currentParams,'sdb',{items:[],offered:0});
          this.$set(this.currentParams,'sdb_wc',{items:[],offered:0});
          this.$set(this.currentParams,'wc',{items:[],offered:0});
          this.$set(this.currentParams,'eau',{items:[],offered:0});
          this.$set(this.currentParams,'offered',0);
        break;
        default:
          this.$delete(this.currentParams,'sdb');
          this.$delete(this.currentParams,'sdb_wc');
          this.$delete(this.currentParams,'wc');
          this.$delete(this.currentParams,'eau');
          this.$delete(this.currentParams,'offered');
          this.$delete(this.currentParams,'rallonge');
          this.$delete(this.currentParams,'entree');
        break;
      }
      console.log(this.currentParams);
    },
    add_item_options(){
      this.currentOptions.content.options.push({text:'',value:''});
    },
    remove_item_options(index){
      this.currentOptions.content.options.splice(index,1);
    },
    remove_item_config(propertyName){
      delete this.currentConfig[propertyName];
      this.$forceUpdate();
    },
    add_item_config(){
      if(this.newConfigParam!=""){
        this.$set(this.currentConfig,this.newConfigParam,0);
        this.newConfigParam = '';
      }
    },
    add_item_entree(rallonge,bouche){
      if(rallonge){
        if(!this.currentParams['rallonge'][bouche]) this.$set(this.currentParams['rallonge'],bouche,[]);
        this.currentParams['rallonge'][bouche].push({lidic:'',number:1});
      }else{
        if(!this.currentParams['entree'][bouche]) this.$set(this.currentParams['entree'],bouche,[]);
        this.currentParams['entree'][bouche].push({lidic:'',number:1});
      }

    },
    remove_item_entree(rallonge,bouche,index2){
      if(rallonge){
        this.currentParams['rallonge'][bouche].splice(index2,1);
      }else{
        this.currentParams['entree'][bouche].splice(index2,1);
      }
    },
    remove_item_gaine(nb_etage,index){
      this.currentGaine.items[nb_etage].splice(index,1);
    },
    add_item_gaine(nb_etage){
      if(!this.currentGaine.items[nb_etage]) this.$set(this.currentGaine.items,nb_etage,[]);
      this.currentGaine.items[nb_etage].push({lidic:'',number:1});
    },
    add_item_bouche(bouche){
      if(!this.currentParams[bouche]) this.$set(this.currentParams,bouche,[]);
      this.currentParams[bouche].push({lidic:'',number:1});
    },
    remove_item_bouche(bouche,index2){
      this.currentParams[bouche].splice(index2,1);
    },
    add_item_pt(pt,index){
      if(!this.currentParams[pt].items[index]) this.$set(this.currentParams[pt].items,index,[]);
      this.currentParams[pt].items[index].push({lidic:'',number:1,offered:0});
    },
    remove_item_pt(pt,index,index2){
      this.currentParams[pt].items[index].splice(index2,1);
    },
    is_paramType(type){
      return this.currentParams.type==type;
    },
    close(type){
      switch(type){
        case 'caisson_point_fonctionnement':
          console.log(this.currentPoints_fonctionnement);
          console.log(this.currentRejet);
          this.editOptions=false;
        break;
        case 'puissance_pondere':
          this.editParams=false;
          console.log(this.currentPuissance);
          console.log(this.currentRejet);
        break;
        case 'gaines':
          this.lidic_params = [];
          this.editGaines = false;
          if(this.currentGaineLidic!=this.tmpGaineLidic && this.currentGaineLidic!=-1){
            this.$delete(this.currentParams, this.currentGaineLidic);
          }
          this.$set(this.currentParams,this.tmpGaineLidic,this.currentGaine);
          console.log(this.currentParams);
          console.log(this.currentRejet);
        break;
        case 'config':
          this.editConfig=false;
          if(this.currentConfigLabel!=this.tmpConfigLabel && this.currentConfigLabel!='Nouveau Label'){
            this.$delete(this.currentRejet.params.config, this.currentConfigLabel);
          }
          this.$set(this.currentRejet.params.config,this.tmpConfigLabel,this.currentConfig);
          console.log(this.currentConfig);
          console.log(this.currentRejet);
        break;
      }
    },
    copyItem(item,type){
      switch(type){
        case 'caisson':
          this.currentRejet = JSON.parse(JSON.stringify(item));
          this.currentRejet.lidic=null;
          this.currentRejet.status= "waiting",
          this.currentRejetLidic = -1;
          this.editVMC = true;
        break;
      }
    },
    editItem(item,type,opt){
      switch(type){
        case 'caisson':

          if(item==-1){
            this.currentRejet = {
              lidic: null,
              points_fonctionnement: [],
              puissances: {
                1:[],
                2:[],
                3:[],
                4:[],
                5:[],
                6:[],
                7:[]
              },
              params: {
                items:[],
                config:{}
              },
              status: "waiting",
            };
            this.currentRejetLidic = item;
          }else{
            console.log("edit caisson");
            console.log(item);
            this.currentRejet = item;
            console.log(this.currentRejet);
            this.currentRejetLidic = item.lidic;
          }
          this.editVMC = true;
        break;
        case 'caisson_point_fonctionnement':
          if(item==-1){
            this.currentRejet.points_fonctionnement.push({pression:0,debit:0});
            this.currentPoints_fonctionnement = this.currentRejet.points_fonctionnement[this.currentRejet.points_fonctionnement[opt].length - 1];
          }else{
            this.currentPoints_fonctionnement = item;
          }
          this.editOptions = true;
        break;
        case 'puissance_pondere':
          if(item==-1){
            this.currentRejet.puissances[opt].push({sdb:0,sdbwc:0,eau:0,wc:0,puissance:0});
            this.currentPuissance = this.currentRejet.puissances[opt][this.currentRejet.puissances[opt].length - 1];
          }else{
            this.currentPuissance = item;
          }
          this.editParams = true;
        break;
        case 'individuel_config':
          if(item==-1){
            this.currentConfig = {};
            this.currentConfigLabel = 'Nouveau label';
            this.tmpConfigLabel = 'Nouveau label';
          }else{
            this.currentConfig = this.currentRejet.params.config[item.label];
            this.currentConfigLabel = item.label;
            this.tmpConfigLabel = this.currentConfigLabel;
          }
          this.editConfig = true;
          console.log(this.currentConfig);
        break;
        case 'individuel_gaines':

          if(item==-1){
            this.currentGaine = {
              items:{
                0:[],
                1:[]
              },
              longueur:0
            };
            this.currentGaineLidic = -1;
            this.tmpGaineLidic = null;
          }else{
            this.currentGaine = this.currentParams[item.lidic];
            this.currentGaineLidic = item.lidic;
            this.tmpGaineLidic = this.currentGaineLidic;
          }
          this.editGaines = true;
          console.log(item);
        break;
      }
    },
    deleteItem(item,type,opt){
      switch(type){
        case 'caisson':
          this.$http.delete(`${this.$api_url}rejets/${item.lidic}`).then((result) => {
            console.log(result);
            if(result){
              var index = this.rejets.findIndex((vmc) => { return item.lidic == vmc.lidic; });
              if(index>-1) this.rejets.splice(index,1);
            }else{
              console.log(result);
            }
          });
        break;
        case 'caisson_point_fonctionnement':
          this.currentRejet.points_fonctionnement.splice(item,1);
        break;
        case 'puissance_pondere':
          this.currentRejet.puissances[opt].splice(item,1);
        break;
        case 'individuel_config':
          this.$delete(this.currentRejet.params.config, item.label);
        break;
        case 'individuel_gaines':
          this.$delete(this.currentParams, item.lidic);
        break;
      }
    },
    cancel(type){
      switch(type){
        case 'caisson':
          this.editVMC = false;
          this.currentRejet = {};
        break;
      }
    },
    saveItem(type){
      switch(type){
        case 'caisson':
          console.log(this.currentRejet);
          var baseURI = this.$api_url+'rejets';
          if(this.currentRejetLidic==-1){
            this.$http.post(baseURI,{rejet: this.currentRejet})
            .then((result) => {
              console.log(result);
              if(result){
                this.$set(this.rejets,this.rejets.length,this.currentRejet);
                this.currentRejet = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }else{
            console.log(this.currentRejet);
            this.$http.put(baseURI,{rejet: this.currentRejet,lidic: this.currentRejetLidic})
            .then((result) => {
              console.log(result);
              if(result){
                console.log(this.rejets.length);
                var index = this.rejets.findIndex((item) => { return item.lidic == this.currentRejetLidic; });
                this.$set(this.rejets,index,this.currentRejet);
                this.currentRejet = {};
                this.editVMC = false;
                this.dismissCountDown = 5;
              }else{
                console.log(result);
              }
            });
          }
        break;
      }
    },
    rowClass(item,type){
      if (item && type === 'row') {
        if(item.status=='valid'){
          return 'validation_success';
        }else if(item.status=='error'){
          return 'validation_error';
        }else if(item.status=="waiting"){
          return 'waiting_validation';
        }
      }else{
        return null;
      }
    },
    updateVMC(){
      if(this.currentRejet.lidic!=""){
        let index = this.product.findIndex((item)=> {
          return item.lidic == this.currentRejet.lidic});
        if(index!=1){
          this.currentRejet.libelle = this.product[index].nom;
          this.currentRejet.designation = this.product[index].designation;
          this.currentRejet.prix_unitaire = this.product[index].prix_unitaire;
        }
        console.log(this.currentRejet);
      }
    },
    updateLidicGaineProd(nb_etage,index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentGaine.items[nb_etage][index].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentGaine.items[nb_etage][index].lidic) || item.nom.toString().includes(this.currentGaine.items[nb_etage][index].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicGaine(){
      let res = [];
      this.product.forEach((item) => {
        if(this.tmpGaineLidic.toString().length>3 && (item.lidic.toString().includes(this.tmpGaineLidic) || item.nom.toString().includes(this.tmpGaineLidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicOptions(index){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentOptions.content.options[index].value.toString().length>3 && (item.lidic.toString().includes(this.currentOptions.content.options[index].value) || item.nom.toString().includes(this.currentOptions.content.options[index].value))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    clearLidicBouche(){
      this.lidic_params = [];
    },
    updateLidicEntree(rallonge,index,index2){
      let res = [];
      if(rallonge){
        this.product.forEach((item) => {
          if(this.currentParams['rallonge'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['rallonge'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['rallonge'][index][index2].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }else{
        this.product.forEach((item) => {
          if(this.currentParams['entree'][index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams['entree'][index][index2].lidic) || item.nom.toString().includes(this.currentParams['entree'][index][index2].lidic))){
          res.push({'value':item.lidic, 'text':item.nom});
          }
        });
      }
      this.lidic_params = res;
      return res;
    },
    updateLidicBouche(index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[index][index2].lidic) || item.nom.toString().includes(this.currentParams[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicParams(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams.lidic) || item.nom.toString().includes(this.currentParams.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicPT(pt,index,index2){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentParams[pt].items[index][index2].lidic.toString().length>3 && (item.lidic.toString().includes(this.currentParams[pt].items[index][index2].lidic) || item.nom.toString().includes(this.currentParams[pt].items[index][index2].lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_params = res;
      return res;
    },
    updateLidicProduct(){
      let res = [];
      this.product.forEach((item) => {
        if(this.currentRejet.lidic.toString().length>3 && (item.lidic.toString().includes(this.currentRejet.lidic) || item.nom.toString().includes(this.currentRejet.lidic))){
        res.push({'value':item.lidic, 'text':item.nom});
        }
      });
      this.lidic_product = res;
      return res;
    }
  },
  computed: {
    get_gaines_params(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="gaines"){
        console.log(this.currentParams);
        Object.keys(this.currentParams).filter(e => e !== 'type').forEach((item)=>{
          res.push({lidic: item});
        });
      }
      return res;
    },
    typologie_range(){
      let res = [];
      if(this.currentRejet!=null && this.currentRejet.typologie!=undefined && this.currentRejet.typologie_min!=undefined){
        let tmp = this.currentRejet.typologie_min;
        while(tmp<=this.currentRejet.typologie){
          res.push(tmp);
          tmp++;
        }
      }
      return res;
    },
    piece_technique_range(){
      let res = [];
      if(this.currentParams!=null && this.currentParams.type=="piece_technique" && this.currentParams['eau']!=null){
        res.push({value:'eau',text:"Salle d'eau"});
        res.push({value:'wc',text:"WC"});
        res.push({value:'sdb_wc',text:"Salle de bain avec WC"});
        res.push({value:'sdb',text:"Salle de bain"});
      }
      return res;
    },
    total_devis(){
      return this.items.length;
    },
    getCurrentConfig(){
      let res = [];
      Object.keys(this.currentRejet.params.config).forEach((item)=>{
        if(item!="leroy_merlin"){
          console.log(item);
          res.push({label:item});
        }
      });
      return res;
    },
    total_prix(){
      let res = 0;
      this.items.forEach((item) => {
        res += parseFloat(item.prix);
      });
      res = res.toFixed(2);
      return res;
    },
    items(){
      let res;
      if((this.start!=null && this.start!="") && (this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime() && new Date(elem.created_at).getTime() <= new Date(this.end).getTime());
      }else if((this.start!=null && this.start!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime() >= new Date(this.start).getTime());
      }else if((this.end!=null && this.end!="")){
        res = this.devis.filter((elem) => new Date(elem.created_at).getTime()<= new Date(this.end).getTime());
      }else{
        res = this.devis;
      }
      return res;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
